import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const DEFAULT_EMPTY_MESSAGE = "Nothing to see here.";

const EmptyList = ({ message }: { message?: string }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        p: 4,
      }}
    >
      <Typography variant="labelLarge">
        {message ?? DEFAULT_EMPTY_MESSAGE}
      </Typography>
    </Box>
  );
};

export default EmptyList;
