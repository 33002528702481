import AddIcon from "@mui/icons-material/Add";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DownloadIcon from "@mui/icons-material/FileDownloadOutlined";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import StarFilledIcon from "@mui/icons-material/StarOutlined";
import StarOutlineIcon from "@mui/icons-material/StarOutlineOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { useAuth } from "../../api/AuthProvider";
import { HighlightedFaqViewCounts } from "../../api/faq/getHighlightedFaqViews";
import AIDisclaimer from "../../components/AIDisclaimer";
import APAccordion from "../../components/APAccordion";
import APModal from "../../components/APModal";
import AIVehicleSuggestionsButton from "../../components/buttons/AIVehicleSuggestionsButton";
import APPrimaryButton from "../../components/buttons/APPrimaryButton";
import CatchEButton from "../../components/buttons/CatchEButton";
import InfoButton from "../../components/buttons/InfoButton";
import SyncQuoteButton from "../../components/buttons/SyncQuoteButton";
import FaqDetailRow from "../../components/FaqDetailRow";
import APToggleGroup from "../../components/form/APFormToggleGroup";
import APTextField from "../../components/form/APTextField";
import ImageCarousel from "../../components/ImageCarousel";
import LoadingBackdrop from "../../components/LoadingBackdrop";
import RestrictedAppScope from "../../components/RestrictedAppScope";
import TermsButton from "../../components/TermsButton";
import FaqCarousel from "../../faq/components/FaqCarousel";
import {
  AppLocationRenderFunction,
  hasHighlightedFaqs,
} from "../../helpers/faq";
import { appendRedbookImageParams } from "../../helpers/files";
import {
  CRMAppLocationIdentifier,
  Faq,
  FaqAppLocation,
  quoteViewDetailsAppLocationIdentifiers,
  quoteViewIncludeAppLocationIdentifiers,
} from "../../types/Faq";
import {
  CalculatedQuoteFormatted,
  payCyclePeriodOptions,
  QuotePayPeriod,
} from "../../types/Quote";
import { RedbookVehiclePhoto } from "../../types/Vehicles";
import { ViewQuoteSubmitAction } from "../viewQuote";

type Inputs = {
  quoteName: string;
  payCyclePeriod: QuotePayPeriod;
};

interface CalculatedQuoteFlags {
  isPrimary: boolean;
  canCreateQuote: boolean;
  disablePrimaryToggle: boolean;
  hasAIConsented: boolean;
  vehicleSuggestionsEnabled: boolean;
  isSubmitting: boolean;
}

interface CalculatedQuoteEventHandlers {
  handleSubmit: (
    name: string,
    payPeriod: QuotePayPeriod,
    submitAction: ViewQuoteSubmitAction
  ) => Promise<void>;
  handleSetPrimary: (quoteId: string) => void;
  handleSetAIConsent: () => void;
  handlePayPeriodChange: (payPeriod: QuotePayPeriod) => void;
}

interface FaqProps {
  renderAppLocation: AppLocationRenderFunction;
  faqAppLocations: FaqAppLocation[];
  highlightedFaqViews: HighlightedFaqViewCounts;
  carouselAppLocationId: string | null;
  carouselFaqs: Faq[];
}

interface CalculatedQuoteProps {
  quote: CalculatedQuoteFormatted;
  quoteImages: RedbookVehiclePhoto[];
  contactId: string;
  faqs: FaqProps;
  flags: CalculatedQuoteFlags;
  handlers: CalculatedQuoteEventHandlers;
}

const peroidLabelMap: Record<QuotePayPeriod, string> = {
  weekly: "week",
  fortnight: "fortnight",
  monthly: "month",
  annual: "year",
};

const CalculatedQuote = ({
  quote,
  quoteImages,
  contactId,
  flags: {
    isPrimary,
    canCreateQuote,
    isSubmitting,
    disablePrimaryToggle,
    hasAIConsented,
    vehicleSuggestionsEnabled,
  },
  handlers: {
    handleSubmit,
    handleSetPrimary,
    handleSetAIConsent,
    handlePayPeriodChange,
  },
  faqs,
}: CalculatedQuoteProps) => {
  const {
    control,
    watch,
    handleSubmit: _handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({ mode: "onTouched" });
  const [showAIDisclaimer, setShowAIDisclaimer] = useState(false);
  const { inAppScope } = useAuth();

  const isError = Object.keys(errors).length > 0;

  // Faqs
  const detailsHasHighlightedFaq = hasHighlightedFaqs(
    faqs.faqAppLocations,
    quoteViewDetailsAppLocationIdentifiers,
    faqs.highlightedFaqViews
  );
  const includesHasHighlightedFaq = hasHighlightedFaqs(
    faqs.faqAppLocations,
    quoteViewIncludeAppLocationIdentifiers,
    faqs.highlightedFaqViews
  );
  const faqContextIds = {
    quoteId: quote.id,
  };

  // Event Handlers
  const getSubmitHandler = (
    submitAction: ViewQuoteSubmitAction
  ): SubmitHandler<Inputs> => {
    return async (data) => {
      handleSubmit(data.quoteName, data.payCyclePeriod, submitAction);
    };
  };

  const onAIConsentAccepted: SubmitHandler<Inputs> = async (data) => {
    handleSetAIConsent();
    getSubmitHandler("vehicle-suggestions")(data);
  };

  const onAIDisclaimer: () => void = () => {
    setShowAIDisclaimer(true);
  };

  const onCloseAIDisclaimerModal = () => {
    setShowAIDisclaimer(false);
  };

  const payCyclePeriod = watch("payCyclePeriod") || quote.payPeriod.raw;
  useEffect(() => {
    handlePayPeriodChange(payCyclePeriod);
  }, [payCyclePeriod]);

  // Render Functions
  const renderAppLocation = faqs.renderAppLocation;

  const renderFaqDetailRow = (
    label: string,
    value: string,
    appLocationIdentifier: CRMAppLocationIdentifier
  ) => {
    return (
      <FaqDetailRow
        label={label}
        value={value}
        appLocations={faqs.faqAppLocations}
        highlightedFaqViews={faqs.highlightedFaqViews}
        appLocationIdentifier={appLocationIdentifier}
        contextIds={faqContextIds}
      />
    );
  };

  return (
    <Box>
      <APModal
        isOpen={showAIDisclaimer}
        onClose={onCloseAIDisclaimerModal}
        closeText="Back"
        confirmButton={
          <APPrimaryButton
            variant="contained"
            text="Yes"
            onClick={_handleSubmit(onAIConsentAccepted)}
          />
        }
      >
        <AIDisclaimer
          titleAdornment={renderAppLocation(
            "QuoteView-AIVehicleSuggestion",
            <InfoButton />,
            true,
            true
          )}
        />
      </APModal>
      <Stack spacing={2}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {!quote.isLocked && (
            <IconButton
              sx={{ mr: 1 }}
              size="large"
              aria-label="edit-quote"
              color="primary"
              onClick={_handleSubmit(getSubmitHandler("edit"))}
            >
              <EditOutlinedIcon />
            </IconButton>
          )}
          {renderAppLocation(
            "QuoteView-VehicleName",
            <Box>
              <Typography variant="titleMedium" className="my-first-step">
                {quote.vehicle.year} {quote.vehicle.make} {quote.vehicle.model}{" "}
                {quote.vehicle.variantLongName}
              </Typography>
            </Box>
          )}
          <Box sx={{ flex: 1, display: "flex", justifyContent: "end" }}>
            {isPrimary ? (
              <StarFilledIcon sx={{ m: 1.5 }} color="secondary" />
            ) : (
              <IconButton
                size="large"
                aria-label="favourite-quote"
                color="onSurface"
                onClick={() => handleSetPrimary(quote.id)}
                disabled={disablePrimaryToggle}
              >
                <StarOutlineIcon />
              </IconButton>
            )}
          </Box>
        </Box>

        {quoteImages.length > 0 && (
          <Box>
            <ImageCarousel
              images={quoteImages.map((image) =>
                appendRedbookImageParams(image.href)
              )}
            />
          </Box>
        )}

        {vehicleSuggestionsEnabled && (
          <Box className="my-ai-step">
            {renderAppLocation(
              "QuoteView-AIVehicleSuggestionControl",
              <AIVehicleSuggestionsButton
                onClick={
                  inAppScope("internal") || hasAIConsented
                    ? _handleSubmit(getSubmitHandler("vehicle-suggestions"))
                    : onAIDisclaimer
                }
                animateOnRender={!hasAIConsented}
                endAdornment={renderAppLocation(
                  "QuoteView-AIVehicleSuggestion",
                  <InfoButton />,
                  true,
                  true
                )}
              />
            )}
          </Box>
        )}

        <Box
          sx={{
            backgroundColor: "secondary.main",
            color: "onPrimary.main",
            p: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mt: 2,
          }}
        >
          {renderAppLocation(
            "QuoteView-$CostPerPeriod-TopPinkBox",
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "end",
              }}
              className="my-other-step"
            >
              <Typography
                variant="displayMedium"
                sx={{ lineHeight: "1.1", mr: 1 }}
              >
                {quote.netCost.formatted_exc_cents}
              </Typography>
              <Typography variant="titleLarge">
                per {peroidLabelMap[payCyclePeriod]}^
              </Typography>
            </Box>
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              pt: 3,
              textAlign: "center",
            }}
          >
            {renderAppLocation(
              "QuoteView-Includes$offinance&runningCostpa-Pinkbox",
              <>
                <Typography variant="headlineMedium">
                  Includes {quote.runningCostsPA.formatted_exc_cents}
                </Typography>
                <Typography variant="titleSmall">
                  of finance & running costs p.a
                </Typography>
              </>
            )}
          </Box>
        </Box>

        <Box>
          {renderAppLocation(
            "QuoteView-NameQuoteInput",
            <APTextField<Inputs>
              name="quoteName"
              label="Quote Nickname"
              control={control}
              validations={{
                required: true,
                minLength: 1,
                maxLength: 100,
              }}
              errors={errors}
              defaultValue={quote.name}
            />
          )}
        </Box>

        <Box>
          <APAccordion
            title={"Details"}
            leftIcon={!quote.isLocked && <EditOutlinedIcon />}
            handleLeftIconClick={_handleSubmit(getSubmitHandler("edit"))}
            forceOpen={detailsHasHighlightedFaq}
          >
            <Box sx={{ pt: 1 }}>
              {renderFaqDetailRow(
                "Annual Salary (ex super)",
                quote.annualSalary.formatted_exc_cents,
                "QuoteView-AnnualSalary-Details"
              )}
              {renderFaqDetailRow(
                "Annual Kms",
                quote.annualKm.formatted,
                "QuoteView-Annualkms-Details"
              )}
              {renderFaqDetailRow(
                "Purchase Type",
                quote.purchaseType.formatted,
                "QuoteView-PurchaseType-Details"
              )}
              {renderFaqDetailRow(
                "On Road Cost",
                quote.vehicleOnroadCosts.formatted_exc_cents,
                "QuoteView-OnroadCost-Details"
              )}
              {renderFaqDetailRow(
                "Lease Term (months)",
                quote.term.formatted,
                "QuoteView-LeaseTerm-Details"
              )}
              {renderFaqDetailRow(
                "Residual Inc GST",
                quote.vehicleResidualGross.formatted_exc_cents,
                "QuoteView-ResidualIncGST-Details"
              )}
              {renderFaqDetailRow(
                "Business Usage %",
                quote.businessUsage.formatted,
                "QuoteView-BusinessUsage%-Details"
              )}
            </Box>
          </APAccordion>
        </Box>

        <Box>
          <APAccordion
            title={"Includes"}
            defaultExpanded={quote.inclusions.insurance.raw === 0}
            forceOpen={includesHasHighlightedFaq}
          >
            <Box sx={{ pt: 1 }}>
              {renderFaqDetailRow(
                "Finance",
                quote.inclusions.finance.formatted_exc_cents,
                "QuoteView-Finance-Includes"
              )}
              {renderFaqDetailRow(
                "Servicing",
                quote.inclusions.servicing.formatted_exc_cents,
                "QuoteView-Servicing-Includes"
              )}
              {renderFaqDetailRow(
                "Tyres",
                quote.inclusions.tyres.formatted_exc_cents,
                "QuoteView-Tyres-Includes"
              )}
              {renderFaqDetailRow(
                "Fuel / Charging",
                quote.inclusions.fuel.formatted_exc_cents,
                "QuoteView-Fuel/Charging-Includes"
              )}
              {renderFaqDetailRow(
                "Rego & CTP",
                quote.inclusions.rego.formatted_exc_cents,
                "QuoteView-Rego&CTP-Includes"
              )}
              {renderFaqDetailRow(
                "Comprehensive Insurance",
                quote.inclusions.insurance.formatted_exc_cents,
                "QuoteView-ComprehensiveInsurance-Includes"
              )}
              {renderFaqDetailRow(
                "Management Fee",
                quote.inclusions.managementFees.formatted_exc_cents,
                "QuoteView-ManagementFee-Includes"
              )}
              {renderFaqDetailRow(
                "Luxury Car Charge",
                quote.inclusions.luxuryCharge.formatted_exc_cents,
                "QuoteView-LuxuryCarCharge-Includes"
              )}
              {renderFaqDetailRow(
                "GST",
                quote.inclusions.gst.formatted_exc_cents,
                "QuoteView-GST-Includes"
              )}
            </Box>
          </APAccordion>
        </Box>

        <Box>
          <Box sx={{ my: 2 }}>
            <APToggleGroup
              name="payCyclePeriod"
              control={control}
              options={payCyclePeriodOptions}
              defaultValue={quote.payPeriod.raw}
            />
          </Box>
        </Box>

        <Box>
          <APAccordion
            title={"Savings"}
            hideExpand={true}
            alwaysExpanded={true}
          >
            {renderFaqDetailRow(
              "Package Value",
              quote.packageValue.formatted_exc_cents,
              "QuoteView-PackageValue"
            )}
          </APAccordion>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {renderAppLocation(
            "QuoteView-Saving%",
            <Typography variant="titleLarge" color="secondary.main">
              Savings
            </Typography>
          )}

          {renderAppLocation(
            "QuoteView-Saving%",
            <Box
              sx={{ p: 0.5, backgroundColor: "secondary.main", width: "6rem" }}
            >
              <Typography
                variant="headlineLarge"
                color="onPrimary.main"
                sx={{ whiteSpace: "nowrap", px: 1, textAlign: "right" }}
              >
                {quote.savings.formatted}
              </Typography>
            </Box>,
            false,
            true
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            borderTop: "solid black 1px",
            borderBottom: "solid black 1px",
            alignItems: "center",
          }}
        >
          {renderAppLocation(
            "QuoteView-NetCost-BottomPage",
            <Typography variant="titleMedium">Net Cost^</Typography>
          )}
          {renderAppLocation(
            "QuoteView-NetCost-BottomPage",
            <Box
              sx={{ backgroundColor: "primary.main", py: 0.5, width: "6rem" }}
            >
              <Typography
                variant="titleMedium"
                color="onPrimary.main"
                sx={{ textAlign: "right", pr: 1 }}
              >
                {quote.netCost.formatted_exc_cents}
              </Typography>
            </Box>,
            false,
            true
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {renderAppLocation(
            "QuoteView-Savings-Year-BottomPage",
            <Typography variant="titleLarge">Savings - Year</Typography>
          )}
          {renderAppLocation(
            "QuoteView-Savings-Year-BottomPage",
            <Typography variant="titleLarge" color="secondary.main">
              {quote.savingsPerYear.formatted_exc_cents}
            </Typography>,
            false,
            true
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "secondary.main",
            py: 0.5,
            px: 1,
          }}
        >
          {renderAppLocation(
            "QuoteView-LifeofLeaseSavings-BottomPage",
            <Typography variant="titleLarge" color="onPrimary.main">
              Life of Lease Savings
            </Typography>
          )}
          {renderAppLocation(
            "QuoteView-LifeofLeaseSavings-BottomPage",
            <Typography variant="titleLarge" color="onPrimary.main">
              {quote.lifeOfLeaseSavings.formatted_exc_cents}
            </Typography>,
            false,
            true
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <TermsButton termsId="quote" />
          {renderAppLocation(
            "QuoteView-All",
            <Typography variant="labelSmall" color="primary">
              FAQ
            </Typography>,
            true,
            true
          )}
        </Box>

        {faqs.carouselFaqs.length > 0 && (
          <Box sx={{ backgroundColor: "surface.main" }}>
            {faqs.carouselAppLocationId && (
              <FaqCarousel
                faqs={faqs.carouselFaqs}
                appLocationId={faqs.carouselAppLocationId}
                contextIds={faqContextIds}
              />
            )}
            {faqs.carouselFaqs.length > 1 && (
              <Box sx={{ position: "relative " }}>
                {renderAppLocation(
                  "QuoteView-BelowLifeofLeaseSavings",
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      right: 0,
                      bottom: 0,
                      position: "absolute",
                      mr: 2,
                      mb: 1,
                    }}
                  >
                    <Typography variant="bodySmall" color="primary">
                      More
                    </Typography>
                  </Box>,
                  false,
                  true
                )}
              </Box>
            )}
          </Box>
        )}

        <Box>
          {isError && (
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Typography variant="labelMedium" color="error.main">
                There are errors in your submission
              </Typography>
            </Box>
          )}
          <Box
            sx={{
              pt: 2,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  rowGap: 1,
                  mr: 2,
                }}
              >
                {!quote.isLocked && (
                  <Button
                    size="large"
                    color="primary"
                    variant="contained"
                    disableElevation={true}
                    startIcon={<EditOutlinedIcon />}
                    sx={{ mr: 1 }}
                    onClick={_handleSubmit(getSubmitHandler("edit"))}
                  >
                    Edit
                  </Button>
                )}
                {canCreateQuote &&
                  renderAppLocation(
                    "QuoteView-NewQuoteButton",
                    <Button
                      size="large"
                      color="primary"
                      variant="contained"
                      disableElevation={true}
                      startIcon={<AddIcon />}
                      onClick={_handleSubmit(getSubmitHandler("new"))}
                    >
                      New
                    </Button>
                  )}
              </Box>
              <RestrictedAppScope scope="internal">
                <Box
                  mt={2}
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                    rowGap: 1,
                    columnGap: 1,
                    mr: 2,
                  }}
                >
                  <Button
                    size="large"
                    color="primary"
                    variant="contained"
                    disableElevation={true}
                    onClick={_handleSubmit(getSubmitHandler("user"))}
                  >
                    User
                  </Button>
                  <SyncQuoteButton contactId={contactId} quoteId={quote.id} />
                </Box>
              </RestrictedAppScope>
            </Box>
            <Box sx={{ alignSelf: "start" }}>
              <RestrictedAppScope scope="internal">
                <CatchEButton url={quote.catchEQuoteUrl} />
              </RestrictedAppScope>

              <IconButton
                size="large"
                aria-label="download-quote"
                color="onSurface"
                onClick={_handleSubmit(getSubmitHandler("download"))}
              >
                <DownloadIcon />
              </IconButton>

              <Button
                size="large"
                color="secondary"
                variant="contained"
                type="submit"
                disableElevation={true}
                startIcon={<PlayArrowOutlinedIcon />}
                onClick={_handleSubmit(getSubmitHandler("next"))}
              >
                Next
              </Button>
            </Box>
          </Box>
        </Box>
      </Stack>
      {isSubmitting && <LoadingBackdrop />}
    </Box>
  );
};

export default CalculatedQuote;
