import { useMutation } from "react-query";

import { User } from "../../types/User";
import { useApi } from "../ApiProvider";
import { APQueryOptions, useAPQuery } from "../APQueryProvider";

export const setMyAIConsent = (options: APQueryOptions = {}) => {
  const { fetchWithAuth } = useApi();
  const {
    defaultOnMutateError,
    defaultOnMutateSuccess,
    getDataByQueryKey,
    optimisiticUpdateOnMutate,
  } = useAPQuery();

  return useMutation({
    mutationFn: (): Promise<boolean> => {
      return fetchWithAuth<boolean>("setMyAIConsent", undefined, {
        requestOptions: { method: "post" },
      });
    },
    onMutate: async () => {
      const queryKey = ["user"];
      const user = getDataByQueryKey(queryKey) as User;
      const updatedUser: User = {
        ...user,
        aiConsent: true,
      };

      optimisiticUpdateOnMutate(["user"], updatedUser);
    },
    onError: defaultOnMutateError(),
    onSuccess: defaultOnMutateSuccess(options),
  });
};
