import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ElementType } from "react";

type WrapperComponent = {
  component: ElementType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props?: Record<string, any>;
};

const APDetailRow = ({
  label,
  value,
  leftWrapper,
  rightWrapper,
}: {
  label: string;
  value: string;
  leftWrapper?: WrapperComponent;
  rightWrapper?: WrapperComponent;
}) => {
  const leftText = <Typography variant="titleMedium">{label}</Typography>;
  const rightText = <Typography variant="titleMedium">{value}</Typography>;

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      {leftWrapper ? (
        <leftWrapper.component {...leftWrapper.props}>
          {leftText}
        </leftWrapper.component>
      ) : (
        leftText
      )}
      {rightWrapper ? (
        <rightWrapper.component {...rightWrapper.props}>
          {rightText}
        </rightWrapper.component>
      ) : (
        rightText
      )}
    </Box>
  );
};

export default APDetailRow;
