import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ReactNode } from "react";

import LoadingBackdrop from "../LoadingBackdrop";

interface APFormProps {
  children: ReactNode;
  onSubmit?: React.FormEventHandler<HTMLFormElement>;
  submitText: string;
  submitIcon?: ReactNode;
  leftButton?: ReactNode;
  additionalButton?: ReactNode;
  disableSubmit?: boolean;
  isLoading: boolean;
  isError?: boolean;
  minSpacing?: boolean;
  submitButtonColor?: "primary" | "secondary";
}

const APForm = ({
  children,
  onSubmit,
  submitText,
  submitIcon,
  leftButton,
  additionalButton,
  disableSubmit,
  isLoading,
  isError,
  minSpacing,
  submitButtonColor,
}: APFormProps) => {
  return (
    <Box sx={{ py: 2 }}>
      {isLoading && <LoadingBackdrop />}
      <Stack component="form" spacing={minSpacing ? 2 : 4} onSubmit={onSubmit}>
        {children}
        <Box>
          {isError && (
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Typography variant="labelMedium" color="error.main">
                There are errors in your submission
              </Typography>
            </Box>
          )}
          <Box
            sx={{
              pt: minSpacing ? 0 : 2,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box>{leftButton}</Box>
            <Box sx={{ display: "flex" }}>
              {additionalButton && <Box sx={{ mr: 2 }}>{additionalButton}</Box>}
              <Button
                size="large"
                color={submitButtonColor ?? "secondary"}
                variant="contained"
                type="submit"
                disableElevation={true}
                startIcon={submitIcon || null}
                disabled={disableSubmit}
              >
                {submitText}
              </Button>
            </Box>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

export default APForm;
