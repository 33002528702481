import DriveEtaOutlinedIcon from "@mui/icons-material/DriveEtaOutlined";

import APVerticalStepper, {
  VerticalStepperCheckListItem,
  VerticalStepperStep,
} from "../../components/APVerticalStepper";
import { AppLocationRenderFunction } from "../../helpers/faq";
import { DetailedDossierStatus } from "../../types/Dossier";
import { CRMAppLocationIdentifier } from "../../types/Faq";

interface DossierStatusStepperAppLocationProps {
  renderAppLocation: AppLocationRenderFunction;
  firstTodoIdentifier: CRMAppLocationIdentifier;
}

interface DossierStatusStepperProps {
  dossierStatus: DetailedDossierStatus;
  onItemClick?: () => void;
  appLocations?: DossierStatusStepperAppLocationProps;
}

const DossierStatusStepper = ({
  dossierStatus,
  onItemClick,
  appLocations,
}: DossierStatusStepperProps) => {
  const statusSteps: VerticalStepperStep[] = [];
  dossierStatus.dossierPhases.forEach((phase) => {
    // Phase is completed if all child events are completed
    const checkListItems: VerticalStepperCheckListItem[] = [];

    phase.events.forEach((event) => {
      const isCurrentPhase = phase.id === dossierStatus.currentPhaseId;
      const isVisible =
        event.visibility === "visible" ||
        (event.visibility === "after-completion" && event.completed) ||
        (event.visibility === "current-past-phases" && isCurrentPhase);

      if (isVisible) {
        const eventStatus = event.completed
          ? "done"
          : event.eventTriggeredBy === "driver"
          ? "highlighted-todo"
          : "todo";
        const checklistItem: VerticalStepperCheckListItem = {
          status: eventStatus,
          text: event.eventName,
        };

        checkListItems.push(checklistItem);
      }
    });

    const step: VerticalStepperStep = {
      status: phase.completed ? "done" : "todo",
      text: phase.name,
      checklistitems: checkListItems,
    };

    statusSteps.push(step);
  });

  return (
    <APVerticalStepper
      steps={statusSteps}
      finalIcon={<DriveEtaOutlinedIcon fontSize="large" />}
      onItemClick={onItemClick}
      appLocations={appLocations || undefined}
    ></APVerticalStepper>
  );
};

export default DossierStatusStepper;
