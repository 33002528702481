import DriveEtaOutlinedIcon from "@mui/icons-material/DriveEtaOutlined";
import PlayArrowOutlined from "@mui/icons-material/PlayArrowOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { ReactNode, useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { UseQueryResult } from "react-query";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { useAuth } from "../../api/AuthProvider";
import { getPublicConfig } from "../../api/config/getPublicConfig";
import { getDossier } from "../../api/dossier/getDossier";
import { getFaqAppLocations } from "../../api/faq/getFaqAppLocations";
import { getHighlightedFaqViews } from "../../api/faq/getHighlightedFaqViews";
import { createQuote } from "../../api/quote/createQuote";
import { getCalculatedQuote } from "../../api/quote/getCalculatedQuote";
import { PatchQuotePayload, updateQuote } from "../../api/quote/updateQuote";
import { setMyAIConsent } from "../../api/user/setMyAIConsent";
import { getVehicleMakes } from "../../api/vehicle/getVehicleMakes";
import { getVehicleModels } from "../../api/vehicle/getVehicleModels";
import { getVehicleModelYears } from "../../api/vehicle/getVehicleModelYears";
import { getVehicleVariants } from "../../api/vehicle/getVehicleVariants";
import AIDisclaimer from "../../components/AIDisclaimer";
import APModal from "../../components/APModal";
import AppLocation from "../../components/AppLocation";
import AIVehicleSuggestionsButton from "../../components/buttons/AIVehicleSuggestionsButton";
import APPrimaryButton from "../../components/buttons/APPrimaryButton";
import CatchEButton from "../../components/buttons/CatchEButton";
import InfoButton from "../../components/buttons/InfoButton";
import APAutoComplete, {
  AutoCompleteOption,
} from "../../components/form/APAutoComplete";
import APForm from "../../components/form/APForm";
import APToggleGroup from "../../components/form/APFormToggleGroup";
import APTextField from "../../components/form/APTextField";
import PageContainer from "../../components/PageContainer";
import RestrictedAppScope from "../../components/RestrictedAppScope";
import {
  buildAutoCompleteOptions,
  getAutoCompleteLabel,
} from "../../helpers/buildOptions";
import { isAIVehicleSuggestionEnabled } from "../../helpers/configuration";
import { trimStringInputs } from "../../helpers/form";
import { CRMAppLocationIdentifier } from "../../types/Faq";
import {
  CalculatedQuote,
  internalOnlyPurchaseTypeOptions,
  VehiclePurchaseType,
  vehiclePurchaseTypeOptions,
} from "../../types/Quote";
import { termOptions } from "../../types/QuoteTermOptions";
import { State, stateOptions } from "../../types/State";
import { User } from "../../types/User";

export enum QuoteCreateAction {
  Create = "Create",
  Clone = "Clone",
  Edit = "Edit",
}

type Inputs = {
  purchaseType: AutoCompleteOption | null;
  vehicleMake: AutoCompleteOption | null;
  vehicleModel: AutoCompleteOption | null;
  vehicleYear: AutoCompleteOption | null;
  vehicleVariant: AutoCompleteOption | null;
  vehicleDiscount: string;
  vehiclePrice: string;
  initialOdometer: string;
  state: AutoCompleteOption | null;
  income: string;
  term: string;
  kilometres: string;
  businessUsage: string;
  listPrice: string;
  referralCode: string;
};

export type CreateQuoteSubmitAction = "next" | "personalise";
const CreateQuote = ({
  me,
  action,
}: {
  me: User;
  action: QuoteCreateAction;
}) => {
  const {
    control,
    watch,
    setValue,
    getValues,
    handleSubmit,
    unregister,
    formState: { errors },
  } = useForm<Inputs>({ mode: "onTouched" });
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { dossierId, quoteId } = useParams();
  const [navigationCheckComplete, setNavigationCheck] = useState(false);
  const [showReferralCodeField, setShowReferralCodeField] = useState(false);
  const [showDiscountField, setShowDiscountField] = useState(false);
  const [showAIDisclaimer, setShowAIDisclaimer] = useState(false);
  const { getScopedPageUrl, inAppScope } = useAuth();

  const limitedFields =
    !!searchParams.get("limitedFields") &&
    searchParams.get("limitedFields") === "true";
  const aiQueryId = searchParams.get("aiQueryId") ?? null;
  const makeIdOverride = searchParams.get("makeId") ?? null;
  const makeNameOverride = searchParams.get("makeName") ?? null;
  const modelIdOverride = searchParams.get("modelId") ?? null;
  const modelNameOverride = searchParams.get("modelName") ?? null;
  const modelYearOverride = searchParams.get("modelYear") ?? null;
  const overwriteQuoteId = searchParams.get("overwriteQuote") ?? null;

  const hasOverrides =
    !!makeIdOverride || !!modelIdOverride || !!modelYearOverride;

  const onNavigate = (
    quoteId: string,
    submitAction: CreateQuoteSubmitAction
  ) => {
    switch (submitAction) {
      case "next":
        navigate(getScopedPageUrl("viewQuote", quoteId));
        break;
      case "personalise":
        navigate(getScopedPageUrl("personaliseQuote", quoteId));
        break;
    }
  };

  let quote: UseQueryResult<CalculatedQuote, unknown> | null = null;

  switch (action) {
    case QuoteCreateAction.Create:
      if (!navigationCheckComplete) {
        const newPurchaseType = vehiclePurchaseTypeOptions.find(
          (type) => type.id === "new"
        );

        if (newPurchaseType) {
          setValue("purchaseType", newPurchaseType);
        }

        setNavigationCheck(true);
      }
      break;
    case QuoteCreateAction.Clone:
    case QuoteCreateAction.Edit:
      if (!quoteId) {
        // TODO: improve client side error messaging
        throw new Error("quote id param is misisng");
      }

      quote = getCalculatedQuote(quoteId);

      useEffect(() => {
        if (quote?.isSuccess) {
          // If the quote is locked and the action is edit then redirect to the quote page
          if (quote.data.isLocked && action === QuoteCreateAction.Edit) {
            navigate(getScopedPageUrl("viewQuote", quoteId), {
              replace: true,
            });
          } else {
            if (!aiQueryId) {
              setValue("purchaseType", {
                id: quote.data.purchaseType,
                label: getAutoCompleteLabel(
                  vehiclePurchaseTypeOptions,
                  quote.data.purchaseType
                ),
              });
            } else {
              const newPurchaseType = vehiclePurchaseTypeOptions.find(
                (option) => option.id === ("new" as VehiclePurchaseType)
              );
              if (newPurchaseType) {
                setValue("purchaseType", newPurchaseType);
              }
            }

            if (!hasOverrides) {
              setValue("vehicleMake", {
                id: quote.data.vehicle.makeId,
                label: `${quote.data.vehicle.make}`,
              });
            } else if (makeIdOverride && makeNameOverride) {
              setValue("vehicleMake", {
                id: makeIdOverride,
                label: makeNameOverride,
              });
            }

            if (!hasOverrides) {
              setValue("vehicleModel", {
                id: quote.data.vehicle.modelId,
                label: `${quote.data.vehicle.model}`,
              });
            } else if (modelIdOverride && modelNameOverride) {
              setValue("vehicleModel", {
                id: modelIdOverride,
                label: modelNameOverride,
              });
            }

            if (!hasOverrides) {
              setValue("vehicleYear", {
                id: quote.data.vehicle.year,
                label: quote.data.vehicle.year,
              });
            } else if (modelYearOverride) {
              setValue("vehicleYear", {
                id: modelYearOverride,
                label: modelYearOverride,
              });
            }

            if (!hasOverrides) {
              setValue("vehicleVariant", {
                id: quote.data.vehicle.variantId,
                label: quote.data.vehicle.variantLongName,
              });
            }

            if (quote.data.discount) {
              setValue("vehicleDiscount", `${quote.data.discount}`);
            }

            setValue(
              "initialOdometer",
              `${quote.data.vehicle.initialOdometer}`
            );

            if (quote.data.listPrice) {
              setValue("listPrice", `${quote.data.listPrice}`);
            }

            setValue("state", {
              id: quote.data.state,
              label: quote.data.state,
            });
            setValue("income", `${quote.data.annualSalary}`);
            setValue("term", `${quote.data.term}`);
            setValue("kilometres", `${quote.data.annualKm}`);

            if (quote.data.businessUsage) {
              setValue("businessUsage", `${quote.data.businessUsage}`);
            }

            setNavigationCheck(true);
          }
        }
      }, [quote.isSuccess]);

      if (quote.isError) {
        throw quote.error;
      }
      break;
  }

  let _dossierId: string = "";

  if (action === QuoteCreateAction.Create) {
    if (!dossierId) {
      // TODO: improve client side error messaging
      throw new Error("dossier id param is misisng");
    }
    _dossierId = dossierId;
  } else if (
    action === QuoteCreateAction.Clone ||
    action === QuoteCreateAction.Edit
  ) {
    _dossierId = quote?.data?.dossierId || "";
  }

  const dossier = getDossier(_dossierId, { disabled: !_dossierId });
  const pageId = "quote-edit";
  const faqAppLocations = getFaqAppLocations({ pageId, quoteId });
  const highlightedFaqViews = getHighlightedFaqViews();
  const configMap = getPublicConfig();

  if (dossier.isError) {
    throw dossier.error;
  }

  if (faqAppLocations.isError) {
    throw faqAppLocations.error;
  }

  if (highlightedFaqViews.isError) {
    throw highlightedFaqViews.error;
  }

  if (configMap.isError) {
    throw configMap.error;
  }

  const { mutateAsync, isLoading: isLoadingMutate } = createQuote(_dossierId);

  const { mutateAsync: mutateAsyncUpdate, isLoading: isLoadingUpdateMutate } =
    updateQuote(overwriteQuoteId ?? quoteId ?? "");

  const { mutateAsync: mutateSetAIConsentAsync } = setMyAIConsent();

  // Sets up a field to reset if it's parent field changes
  // Also returns the watched parent field to use for controlling visibility of the fields
  // in the form
  const setCascadeReset = (
    parentField: keyof Pick<
      Inputs,
      "vehicleMake" | "vehicleModel" | "vehicleYear"
    >,
    childField: keyof Pick<
      Inputs,
      "vehicleModel" | "vehicleYear" | "vehicleVariant"
    >
  ) => {
    const watchField = watch(parentField);
    const previousValue = useRef(watchField);

    useEffect(() => {
      const previous = previousValue.current && previousValue.current.id;
      const current = watchField && watchField.id;

      if (!!previous && previous !== current) {
        setValue(childField, null);
      }

      previousValue.current = watchField;
    }, [watchField, setValue]);

    return watchField;
  };

  const response = getVehicleMakes();

  if (response.isError) {
    throw response.error;
  }

  const buildCreatePayload = (data: Inputs) => {
    if (!data.purchaseType || !data.state || !data.vehicleVariant) {
      // TODO: better error
      throw new Error("invalid");
    }

    return {
      purchaseType: data.purchaseType.id as VehiclePurchaseType,
      state: data.state.id as State,
      variantId: data.vehicleVariant.id,
      salary: parseFloat(data.income),
      term: parseInt(data.term),
      annualKilometres: parseInt(data.kilometres),
      businessUsage:
        limitedFields || !data.businessUsage ? 0 : parseInt(data.businessUsage),
      discount: data.vehicleDiscount ? parseFloat(data.vehicleDiscount) : 0,
      initialOdometer: data.initialOdometer
        ? parseInt(data.initialOdometer)
        : 0,
      listPrice: data.listPrice ? parseFloat(data.listPrice) : undefined,
      referralCode: data.referralCode || undefined,
      aiQueryId: aiQueryId ?? undefined,
    };
  };

  const buildUpdatePayload = (data: Inputs, quote: CalculatedQuote) => {
    if (!data.purchaseType || !data.state || !data.vehicleVariant) {
      // TODO: better error
      throw new Error("invalid");
    }

    const updatePayload: PatchQuotePayload = {
      aiQueryId: aiQueryId ?? undefined,
    };

    if (data.purchaseType.id !== quote.purchaseType) {
      updatePayload.purchaseType = data.purchaseType.id as VehiclePurchaseType;
    }

    if (data.vehicleVariant.id !== quote.vehicle.variantId) {
      updatePayload.variantId = data.vehicleVariant.id;
    }

    if ((data.vehicleDiscount || "0") !== `${quote.discount}`) {
      updatePayload.discount = data.vehicleDiscount
        ? parseFloat(data.vehicleDiscount)
        : 0;
    }
    if ((data.initialOdometer || "0") !== `${quote.vehicle.initialOdometer}`) {
      updatePayload.initialOdometer = data.initialOdometer
        ? parseInt(data.initialOdometer)
        : 0;
    }
    if (data.listPrice && data.listPrice !== `${quote.listPrice}`) {
      updatePayload.listPrice = parseFloat(data.listPrice);
    }

    if (data.state.id !== quote.state) {
      updatePayload.state = data.state.id as State;
    }
    if (data.term !== `${quote.term}`) {
      updatePayload.term = parseInt(data.term);
    }
    if (data.kilometres !== `${quote.annualKm}`) {
      updatePayload.annualKilometres = parseInt(data.kilometres);
    }
    if (data.income !== `${quote.annualSalary}`) {
      updatePayload.salary = parseFloat(data.income);
    }
    if ((data.businessUsage || "0") !== `${quote.businessUsage}`) {
      updatePayload.businessUsage = data.businessUsage
        ? parseInt(data.businessUsage)
        : 0;
    }

    if (data.referralCode) {
      updatePayload.referralCode = data.referralCode;
    }

    return updatePayload;
  };

  const onSubmit = async (
    _data: Inputs,
    submitAction: CreateQuoteSubmitAction
  ) => {
    trimStringInputs(_data, setValue);
    const data = getValues();

    if (!quote || quote.isSuccess) {
      const isCreate =
        (action === QuoteCreateAction.Create ||
          action === QuoteCreateAction.Clone) &&
        !overwriteQuoteId;
      const isEdit = action === QuoteCreateAction.Edit || overwriteQuoteId;
      const _quoteId = overwriteQuoteId ?? quoteId;

      if (isCreate) {
        await mutateAsync(buildCreatePayload(data), {
          onSuccess: (quoteId: string) => onNavigate(quoteId, submitAction),
        });
      } else if (isEdit && quote && _quoteId) {
        const updatePayload: PatchQuotePayload = buildUpdatePayload(
          data,
          quote.data
        );

        if (Object.keys(updatePayload).length > 0) {
          updatePayload.dossierId = _dossierId;
          await mutateAsyncUpdate(updatePayload);
        }
        onNavigate(_quoteId, submitAction);
      }
    }
  };

  const onNext: SubmitHandler<Inputs> = async (data) => {
    onSubmit(data, "next");
  };

  const onPersonalise: SubmitHandler<Inputs> = async (data) => {
    onSubmit(data, "personalise");
  };

  const onReferralLinkClick = () => {
    setShowReferralCodeField(true);
  };

  const onDiscountLinkClick = () => {
    setShowDiscountField(true);
  };

  const watchPurchaseType = watch("purchaseType");
  const watchVehicleMake = setCascadeReset("vehicleMake", "vehicleModel");
  const watchVehicleModel = setCascadeReset("vehicleModel", "vehicleYear");
  const watchVehicleYear = setCascadeReset("vehicleYear", "vehicleVariant");
  const watchVehicleVariant = watch("vehicleVariant");
  const watchDiscount = watch("vehicleDiscount");
  const watchlistPrice = watch("listPrice");

  useEffect(() => {
    const previous = quote && quote.isSuccess && quote.data.vehicle.variantId;
    const current = watchVehicleVariant && watchVehicleVariant.id;

    if (!!current && previous !== current) {
      setValue("listPrice", "");
    }
  }, [watchVehicleVariant]);

  useEffect(() => {
    if (watchPurchaseType) {
      if (watchPurchaseType.id === "new") {
        if (inAppScope("external")) {
          setValue("listPrice", "");
        } else {
          unregister("listPrice");
          setValue("listPrice", watchlistPrice);
        }
        setValue("initialOdometer", "");
      } else {
        setValue("vehicleDiscount", "");
        setShowDiscountField(false);
      }
    }
  }, [watchPurchaseType]);

  useEffect(() => {
    if (watchDiscount && !showDiscountField) {
      setShowDiscountField(true);
    }
  }, [watchDiscount]);

  const vehicleModels = getVehicleModels(watchVehicleMake?.id || null);
  const vehicleModelYears = getVehicleModelYears(watchVehicleModel?.id || null);

  const vehicleModelVariants = getVehicleVariants(
    watchVehicleMake?.id || null,
    watchVehicleModel?.id || null,
    watchVehicleYear?.id || null
  );

  const isLoading =
    response.isFetching ||
    (!!quote && quote.isFetching) ||
    !_dossierId ||
    dossier.isFetching ||
    faqAppLocations.isFetching ||
    highlightedFaqViews.isLoading ||
    configMap.isFetching;
  const isSuccess =
    response.isSuccess &&
    (!quote || quote.isSuccess) &&
    dossier.isSuccess &&
    faqAppLocations.isSuccess &&
    highlightedFaqViews.isSuccess &&
    configMap.isSuccess;
  const showReferralCodeLink =
    !showReferralCodeField &&
    dossier?.data?.opportunity &&
    !dossier.data.opportunity.referralCode;
  const showDiscountLink = !showDiscountField && watchVehicleVariant;

  const getVehicleSuggestionQueryString = (quote: CalculatedQuote) => {
    const {
      vehicle: { makeId, modelId, year, variantId },
    } = quote;
    const vehicleHasChanged =
      makeId !== watchVehicleMake?.id ||
      modelId !== watchVehicleModel?.id ||
      year !== watchVehicleYear?.id ||
      variantId !== watchVehicleVariant?.id;

    if (
      vehicleHasChanged &&
      watchVehicleMake &&
      watchVehicleModel &&
      watchVehicleYear &&
      watchVehicleVariant
    ) {
      return `?makeId=${watchVehicleMake.id}&modelId=${watchVehicleModel.id}&modelYear=${watchVehicleYear.id}&variantId=${watchVehicleVariant.id}`;
    } else {
      return "";
    }
  };

  const onAIClick = () => {
    if (inAppScope("internal") || me.aiConsent) {
      onVehicleSuggestions();
    } else {
      setShowAIDisclaimer(true);
    }
  };

  const onCloseAIDisclaimerModal = () => {
    setShowAIDisclaimer(false);
  };

  const onAIConsentAccepted = () => {
    mutateSetAIConsentAsync();
    onVehicleSuggestions();
  };

  const onVehicleSuggestions = () => {
    if (quote?.isSuccess) {
      const queryString = getVehicleSuggestionQueryString(quote.data);

      navigate(
        `${getScopedPageUrl(
          "viewQuoteVehicleSuggestions",
          quoteId
        )}${queryString}`
      );
    }
  };

  const isVehicleSuggestionsEnabled =
    configMap.isSuccess && isAIVehicleSuggestionEnabled(configMap.data);
  const faqContextIds = {
    quoteId: quoteId,
  };
  const renderAppLocation = (
    appLocationIdentifier: CRMAppLocationIdentifier,
    children: ReactNode,
    hideIfNoFaqs?: boolean,
    hideHighlight?: boolean
  ) => {
    return (
      <AppLocation
        appLocations={faqAppLocations.data || []}
        highlightedFaqViews={highlightedFaqViews.data || {}}
        appLocationIdentifier={appLocationIdentifier}
        hideIfNoFaqs={hideIfNoFaqs}
        hideHighlight={hideHighlight}
        contextIds={faqContextIds}
      >
        {children}
      </AppLocation>
    );
  };

  return (
    <PageContainer
      loading={isLoading || !navigationCheckComplete}
      title="Select Vehicle"
    >
      {isSuccess && navigationCheckComplete && (
        <>
          <APModal
            isOpen={showAIDisclaimer}
            onClose={onCloseAIDisclaimerModal}
            closeText="Back"
            confirmButton={
              <APPrimaryButton
                variant="contained"
                text="Yes"
                onClick={onAIConsentAccepted}
              />
            }
          >
            <AIDisclaimer
              titleAdornment={renderAppLocation(
                "QuoteEdit-AIVehicleSuggestion",
                <InfoButton />,
                true,
                true
              )}
            />
          </APModal>
          <Typography variant="labelLarge" color="onSurface.main" gutterBottom>
            This info is needed for your quote.
          </Typography>
          <APForm
            onSubmit={handleSubmit(onNext)}
            submitText="View Quote"
            submitIcon={<PlayArrowOutlined />}
            isLoading={isLoadingMutate || isLoadingUpdateMutate}
            isError={Object.keys(errors).length > 0}
            additionalButton={
              <Box sx={{ display: "flex" }}>
                {quote?.data && (
                  <Box sx={{ mr: { md: 2, sm: 0 } }}>
                    <RestrictedAppScope scope="internal">
                      <CatchEButton url={quote.data.catchEQuoteUrl} />
                    </RestrictedAppScope>
                  </Box>
                )}
                <Button
                  size="large"
                  color="primary"
                  variant="contained"
                  disableElevation={true}
                  startIcon={<DriveEtaOutlinedIcon />}
                  onClick={handleSubmit(onPersonalise)}
                >
                  Personalise
                </Button>
              </Box>
            }
          >
            <APAutoComplete
              name="purchaseType"
              label="Purchase Type"
              options={[
                ...vehiclePurchaseTypeOptions,
                ...(inAppScope("internal")
                  ? internalOnlyPurchaseTypeOptions
                  : internalOnlyPurchaseTypeOptions.filter(
                      (option) =>
                        watchPurchaseType && option.id === watchPurchaseType.id
                    )),
              ]}
              control={control}
              validations={{ required: true }}
              errors={errors}
              defaultValue={null}
            />

            <APAutoComplete
              name="vehicleMake"
              label="Vehicle Make"
              options={buildAutoCompleteOptions(response.data, "name", "id")}
              control={control}
              validations={{ required: true }}
              errors={errors}
              defaultValue={null}
            />

            <APAutoComplete
              name="vehicleModel"
              label="Vehicle Model"
              options={
                vehicleModels.isSuccess
                  ? buildAutoCompleteOptions(vehicleModels.data, "name", "id")
                  : []
              }
              control={control}
              validations={{ required: true }}
              errors={errors}
              defaultValue={null}
              disabled={!watchVehicleMake || !vehicleModels.isSuccess}
            />

            <APAutoComplete
              name="vehicleYear"
              label="Vehicle Year"
              options={
                vehicleModelYears.isSuccess
                  ? buildAutoCompleteOptions(
                      vehicleModelYears.data.map((year) => {
                        return { id: `${year}`, name: `${year}` };
                      }),
                      "name",
                      "id"
                    )
                  : []
              }
              control={control}
              validations={{ required: true }}
              errors={errors}
              defaultValue={null}
              disabled={
                !watchVehicleMake ||
                !watchVehicleModel ||
                !vehicleModelYears.isSuccess
              }
            />

            <APAutoComplete
              name="vehicleVariant"
              label="Vehicle Variant"
              options={
                vehicleModelVariants.isSuccess
                  ? buildAutoCompleteOptions(
                      vehicleModelVariants.data,
                      "name",
                      "id"
                    )
                  : []
              }
              control={control}
              validations={{ required: true }}
              errors={errors}
              defaultValue={null}
              disabled={
                !watchVehicleMake ||
                !watchVehicleModel ||
                !watchVehicleYear ||
                !vehicleModelVariants.isSuccess
              }
              highlightOnEmpty={!!aiQueryId}
            />

            {watchPurchaseType && watchPurchaseType.id === "new" && (
              <>
                {showDiscountField && (
                  <APTextField<Inputs>
                    name="vehicleDiscount"
                    label="Vehicle Discount Inc GST"
                    control={control}
                    validations={{
                      formatValidation: /[0-9]+(\.[0-9][0-9])?/,
                    }}
                    errors={errors}
                    defaultValue={""}
                    startAdornment="$"
                    placeholder="xxxxxxx.xx"
                    formatNumber={true}
                  />
                )}
                {showDiscountLink && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      mt: "12px !important",
                    }}
                  >
                    <Typography
                      variant="bodySmall"
                      color="primary"
                      onClick={onDiscountLinkClick}
                      sx={{ textDecoration: "underline", cursor: "pointer" }}
                    >
                      I have a discount
                    </Typography>
                  </Box>
                )}
              </>
            )}

            {watchVehicleVariant &&
              (action === QuoteCreateAction.Edit ||
                action === QuoteCreateAction.Clone) &&
              isVehicleSuggestionsEnabled && (
                <Box>
                  <AIVehicleSuggestionsButton
                    onClick={onAIClick}
                    endAdornment={renderAppLocation(
                      "QuoteEdit-AIVehicleSuggestion",
                      <InfoButton />,
                      true,
                      true
                    )}
                  />
                </Box>
              )}

            {watchPurchaseType &&
              (watchPurchaseType.id !== "new" || inAppScope("internal")) && (
                <APTextField<Inputs>
                  name="listPrice"
                  label="Vehicle Price Inc GST"
                  control={control}
                  validations={{
                    required: watchPurchaseType.id !== "new",
                    formatValidation: /[0-9]+(\.[0-9][0-9]?)?/,
                  }}
                  errors={errors}
                  defaultValue=""
                  startAdornment="$"
                  placeholder="xxxxxxx.xx"
                  formatNumber={true}
                />
              )}

            {watchPurchaseType && watchPurchaseType.id !== "new" && (
              <APTextField<Inputs>
                name="initialOdometer"
                label="Initial Odometer"
                control={control}
                validations={{
                  required: true,
                  formatValidation: /(?!0+$)[0-9]+/,
                  customMessage: "Value must be a number greater than 0",
                }}
                errors={errors}
                defaultValue=""
                endAdornment="km"
                placeholder="xxxxx"
              />
            )}

            <APAutoComplete
              name="state"
              label="State"
              options={stateOptions}
              control={control}
              validations={{ required: true }}
              errors={errors}
              defaultValue={""}
            />
            <APTextField<Inputs>
              name="income"
              label="Annual Salary (ex super)"
              control={control}
              validations={{
                required: true,
                formatValidation: /[0-9]+(\.[0-9][0-9])?/,
              }}
              errors={errors}
              defaultValue={""}
              startAdornment="$"
              placeholder="xxxxxxx.xx"
              formatNumber={true}
            />
            <APToggleGroup
              name="term"
              control={control}
              options={termOptions}
              defaultValue="60"
            />
            <APTextField<Inputs>
              name="kilometres"
              label="Annual Km"
              control={control}
              validations={{
                required: true,
                formatValidation: /[0-9]+/,
              }}
              errors={errors}
              defaultValue="10000"
              endAdornment="km"
              placeholder="xxxxx"
              helperText="Number of kms driven p.a."
            />
            {!limitedFields && (
              <APTextField<Inputs>
                name="businessUsage"
                label="Business Usage (if any)"
                control={control}
                validations={{
                  maxLength: 2,
                  formatValidation: /[0-9]+/,
                }}
                errors={errors}
                defaultValue=""
                endAdornment="%"
                placeholder="xx"
                helperText="Business logbook required"
              />
            )}
            <RestrictedAppScope scope="external">
              {showReferralCodeField && (
                <APTextField<Inputs>
                  name="referralCode"
                  label="Referral Code"
                  control={control}
                  validations={{
                    maxLength: 6,
                    minLength: 6,
                    formatValidation:
                      me && me.referralCode
                        ? new RegExp(`^(?!${me.referralCode}$).*$`, "i")
                        : undefined,
                    customFormatMessage:
                      "You cannot use your own referral code.",
                  }}
                  errors={errors}
                  defaultValue=""
                />
              )}
            </RestrictedAppScope>
          </APForm>
          <RestrictedAppScope scope="external">
            {showReferralCodeLink && (
              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <Typography
                  variant="bodySmall"
                  color="primary"
                  onClick={onReferralLinkClick}
                  sx={{ textDecoration: "underline", cursor: "pointer" }}
                >
                  I have a referral code
                </Typography>
              </Box>
            )}
          </RestrictedAppScope>
        </>
      )}
    </PageContainer>
  );
};

export default CreateQuote;
