export type CRMAppLocationIdentifier =
  | "QuoteView-$CostPerPeriod-TopPinkBox"
  | "QuoteView-Annualkms-Details"
  | "QuoteView-PurchaseType-Details"
  | "QuoteView-AnnualSalary-Details"
  | "QuoteView-BelowLifeofLeaseSavings"
  | "QuoteView-BusinessUsage%-Details"
  | "QuoteView-ComprehensiveInsurance-Includes"
  | "QuoteView-Finance-Includes"
  | "QuoteView-Fuel/Charging-Includes"
  | "QuoteView-GST-Includes"
  | "QuoteView-Includes$offinance&runningCostpa-Pinkbox"
  | "QuoteView-LeaseTerm-Details"
  | "QuoteView-LifeofLeaseSavings-BottomPage"
  | "QuoteView-LuxuryCarCharge-Includes"
  | "QuoteView-ManagementFee-Includes"
  | "QuoteView-NetCost-BottomPage"
  | "QuoteView-OnroadCost-Details"
  | "QuoteView-PackageValue"
  | "QuoteView-Rego&CTP-Includes"
  | "QuoteView-ResidualIncGST-Details"
  | "QuoteView-Saving%"
  | "QuoteView-Savings-Year-BottomPage"
  | "QuoteView-Servicing-Includes"
  | "QuoteView-Tyres-Includes"
  | "QuoteView-All"
  | "QuoteView-AIVehicleSuggestion"
  | "QuoteView-VehicleName"
  | "QuoteView-NewQuoteButton"
  | "QuoteView-NameQuoteInput"
  | "QuoteView-AIVehicleSuggestionControl"
  | "QuoteEdit-AIVehicleSuggestion"
  | "QuoteList-StatusCurrentStage"
  | "QuoteList-SortOrder"
  | "AIVehicleSuggestion-Page"
  | "AIVehicleSuggestion-PoweredBy";

export const quoteViewIncludeAppLocationIdentifiers: CRMAppLocationIdentifier[] =
  [
    "QuoteView-ComprehensiveInsurance-Includes",
    "QuoteView-Finance-Includes",
    "QuoteView-Fuel/Charging-Includes",
    "QuoteView-GST-Includes",
    "QuoteView-LuxuryCarCharge-Includes",
    "QuoteView-ManagementFee-Includes",
    "QuoteView-Rego&CTP-Includes",
    "QuoteView-Servicing-Includes",
    "QuoteView-Tyres-Includes",
  ];

export const quoteViewDetailsAppLocationIdentifiers: CRMAppLocationIdentifier[] =
  [
    "QuoteView-Annualkms-Details",
    "QuoteView-PurchaseType-Details",
    "QuoteView-AnnualSalary-Details",
    "QuoteView-BusinessUsage%-Details",
    "QuoteView-LeaseTerm-Details",
    "QuoteView-OnroadCost-Details",
    "QuoteView-ResidualIncGST-Details",
  ];

export type APPage =
  | "initial-user-info"
  | "quote-edit"
  | "vehicle-options"
  | "quote-view"
  | "dossier-list"
  | "dossier-view"
  | "request-employer-setup-authorised"
  | "request-employer-setup-not-authorised"
  | "driver-pa"
  | "driver-bank"
  | "employer-home"
  | "employer-approve-drivers"
  | "vehicle-suggestions";

export type APFaqVoteType = "useful" | "not-useful";

type HighlightedFaq = {
  id: string;
  question: string;
  faqApplocationId: string | null;
};

export type FaqAppLocation = {
  id: string;
  appLocationIdentifier: CRMAppLocationIdentifier;
  name: string;
  page: APPage;
  faqCount: number;
  highlightedFaq: HighlightedFaq | null;
};

export type Faq = {
  id: string;
  faqApplocationId: string | null;
  question: string;
  answer: string;
  answerBelowImage: string | null;
  imageUrl: string | null;
  usefulCount: number;
  notUsefulCount: number;
  viewCount: number;
  isHighlighted: boolean;
};

export type FaqVote = {
  id: string;
  faqId: string;
  vote: APFaqVoteType;
};
