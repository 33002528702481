import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { useEffect, useRef } from "react";
import { TooltipRenderProps } from "react-joyride";

export const APJoyrideTooltip = ({
  index,
  step,
  size,
  tooltipProps,
  primaryProps,
  backProps,
  skipProps,
  isLastStep,
}: TooltipRenderProps) => {
  const closeButtonRef = useRef<HTMLButtonElement>(null);
  const nextButtonRef = useRef<HTMLButtonElement>(null);

  // Trigger next button when enter is pressed
  useEffect(() => {
    if (nextButtonRef) {
      const handleKeyPress = (event: KeyboardEvent) => {
        if (event.key === "Enter") {
          nextButtonRef.current?.click();
        }
      };

      document.addEventListener("keydown", handleKeyPress);
      return () => document.removeEventListener("keydown", handleKeyPress);
    }
  }, [nextButtonRef]);

  // Trigger close when esc is pressed
  useEffect(() => {
    if (closeButtonRef) {
      const handleKeyPress = (event: KeyboardEvent) => {
        if (event.key === "Escape") {
          closeButtonRef.current?.click();
        }
      };

      document.addEventListener("keydown", handleKeyPress);
      return () => document.removeEventListener("keydown", handleKeyPress);
    }
  }, [closeButtonRef]);

  return (
    <Card
      {...tooltipProps}
      sx={{ padding: 1, maxWidth: { xs: "340px", sm: "600px" } }}
    >
      <CardContent>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            columnGap: 2,
            mb: 2,
          }}
        >
          <Typography variant="titleMedium">{step.title}</Typography>
          <IconButton
            ref={closeButtonRef}
            color="onSurface"
            {...skipProps}
            sx={{ alignSelf: "start" }}
            size="small"
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <Typography variant="bodyMedium">{step.content}</Typography>
      </CardContent>
      <CardActions
        sx={{ display: "flex", columnGap: 4, justifyContent: "space-between" }}
      >
        <Button
          size="large"
          color="primary"
          variant="outlined"
          disableElevation={true}
          sx={{ visibility: index === 0 ? "hidden" : undefined }}
          {...backProps}
        >
          Back
        </Button>

        <Box>
          <Typography variant="labelLarge">
            {index + 1} / {size}
          </Typography>
        </Box>
        <Button
          ref={nextButtonRef}
          size="large"
          color="secondary"
          variant="outlined"
          disableElevation={true}
          disableFocusRipple={true}
          {...primaryProps}
        >
          {isLastStep ? "Finish" : "Next"}
        </Button>
      </CardActions>
    </Card>
  );
};
