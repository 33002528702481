import { faRobot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { ReactNode } from "react";

import { TERTIARY_COLOR } from "../../theme";

const AIButton = ({
  text,
  onClick,
  endAdornment,
  pulseOnRender = false,
}: {
  text: string;
  onClick: () => void;
  endAdornment?: ReactNode;
  pulseOnRender?: boolean;
}) => {
  return (
    <Box
      sx={{
        border: "2px solid",
        borderColor: "tertiaryGreen.main",

        borderRadius: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        transition: pulseOnRender ? "all 0.3s ease" : undefined,
        animation: pulseOnRender ? "pulse-glow 2s 2" : undefined,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          columnGap: 1,
          flex: 1,
          cursor: "pointer",
          p: 1.5,
        }}
        onClick={onClick}
      >
        <FontAwesomeIcon icon={faRobot} color={TERTIARY_COLOR} size="xl" />
        <Typography variant="titleMedium">{text}</Typography>
      </Box>
      <Box p={1.5}>{endAdornment}</Box>
    </Box>
  );
};

export default AIButton;
