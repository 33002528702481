import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary/AccordionSummary";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { ReactNode, useEffect, useState } from "react";

interface APAccordionProps {
  children: ReactNode;
  title: string;
  leftIcon?: ReactNode;
  hideExpand?: boolean;
  alwaysExpanded?: boolean;
  defaultExpanded?: boolean;
  forceOpen?: boolean;
  smallHeader?: boolean;
  handleLeftIconClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const APAccordion = ({
  children,
  title,
  leftIcon,
  hideExpand,
  alwaysExpanded,
  defaultExpanded,
  forceOpen,
  smallHeader,
  handleLeftIconClick,
}: APAccordionProps) => {
  const [internalOpen, setInternalOpen] = useState(!!defaultExpanded);

  const handleChange = (_: React.SyntheticEvent, isExpanded: boolean) => {
    setInternalOpen(isExpanded);
  };

  const onLeftIconClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    if (handleLeftIconClick) {
      handleLeftIconClick(event);
    }
  };

  useEffect(() => {
    if (forceOpen) {
      setInternalOpen(true);
    }
  }, [forceOpen]);

  return (
    <Accordion
      sx={{
        boxShadow: "none",
        backgroundColor: "initial",
        ":before": { backgroundColor: "initial" },
      }}
      disableGutters
      expanded={alwaysExpanded || internalOpen}
      onChange={handleChange}
      className="APAccordion"
    >
      <AccordionSummary
        expandIcon={
          <Box sx={{ width: "1.5rem" }} className="APAccordion-expand">
            {hideExpand ? <></> : <ExpandMoreIcon />}
          </Box>
        }
        sx={{
          borderBottomColor: "primary.main",
          borderBottomWidth: 1,
          borderBottomStyle: "solid",
          p: 0,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", flex: 1 }}>
          <Box sx={{ width: "1.5rem" }}>
            {leftIcon && (
              <IconButton
                sx={{ mr: 1 }}
                size="large"
                aria-label="edit-quote"
                color="primary"
                onClick={onLeftIconClick}
              >
                {leftIcon}
              </IconButton>
            )}
          </Box>
          <Box sx={{ flex: 1, textAlign: "center" }}>
            <Typography
              variant={`${smallHeader ? "titleMedium" : "titleLarge"}`}
            >
              {title}
            </Typography>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ px: 0 }}>{children}</AccordionDetails>
    </Accordion>
  );
};

export default APAccordion;
