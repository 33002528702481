import { useQuery } from "react-query";

import { RedbookVehiclePhoto } from "../../types/Vehicles";
import { useApi } from "../ApiProvider";

export const getQuoteVehicleImages = (quoteId: string) => {
  const { fetchWithAuth } = useApi();

  return useQuery({
    queryKey: ["quote-vehicle-images", quoteId],
    queryFn: (): Promise<RedbookVehiclePhoto[]> => {
      return fetchWithAuth<RedbookVehiclePhoto[]>(
        "getQuoteVehicleImages",
        quoteId
      );
    },
  });
};
