import Box from "@mui/material/Box";
import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { useApi } from "../../api/ApiProvider";
import { useAuth } from "../../api/AuthProvider";
import { getFaq } from "../../api/faq/getFaq";
import { getCalculatedQuote } from "../../api/quote/getCalculatedQuote";
import ModalContainer from "../../components/ModalContainer";
import { formatCalculatedQuote } from "../../helpers/format";
import FaqDetails from "../components/FaqDetails";

const ViewFaq = () => {
  const { faqId } = useParams();
  const { fetchWithAuth } = useApi();
  const { inAppScope } = useAuth();
  const [searchParams] = useSearchParams();

  const quoteId = searchParams.get("modal.quoteId") ?? null;
  const appLocationId = searchParams.get("modal.appLocationId") ?? null;
  const faqAppLocationId = searchParams.get("modal.faqAppLocationId") ?? null;

  if (!faqId) {
    // TODO: improve client side error messaging
    throw new Error("faq id param is misisng");
  }

  const faq = getFaq(faqId);

  const quote = getCalculatedQuote(quoteId ?? "", {
    useCached: true,
    disabled: !quoteId,
  });

  if (faq && faq.isError) {
    throw faq.error;
  }

  if (quote.isError) {
    throw quote.error;
  }

  const trackView = (faqId: string) => {
    if (faq.isSuccess && inAppScope("external")) {
      fetchWithAuth<void>("createFaqView", faqId, {
        requestOptions: {
          method: "post",
          body: JSON.stringify({
            appLocationId: appLocationId,
            faqAppLocationId,
          }),
        },
      });
    }
  };

  useEffect(() => {
    if (faq.isSuccess) {
      trackView(faq.data.id);
    }
  }, [faq.data?.id]);

  const isLoading = faq.isLoading || quote.isLoading;
  const isSuccess = faq.isSuccess && (!quoteId || quote.isSuccess);

  return (
    <ModalContainer loading={isLoading}>
      {isSuccess && (
        <Box>
          {faq.data && (
            <FaqDetails
              faq={faq.data}
              quote={quote.data ? formatCalculatedQuote(quote.data) : undefined}
              appLocationId={appLocationId}
            />
          )}
        </Box>
      )}
    </ModalContainer>
  );
};

export default ViewFaq;
