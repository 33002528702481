import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { ReactNode } from "react";

import LoadingBackdrop from "./LoadingBackdrop";

const PageContainer = ({
  children,
  loading,
  title,
  titleButton,
}: {
  children: ReactNode;
  loading: boolean;
  title?: string;
  titleButton?: ReactNode;
}) => {
  return (
    <Container
      disableGutters
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        height: "100%",
      }}
      maxWidth="md"
    >
      {!loading && (
        <Paper
          sx={{
            boxShadow: {
              xs: "none",
              md: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
            },
            flex: {
              xs: "1",
              md: "initial",
            },
            pb: 6,
            mb: {
              xs: 0,
              md: 4,
            },
          }}
        >
          <Box
            sx={{
              p: {
                xs: 2,
                sm: 3,
                md: 4,
              },
            }}
          >
            {title && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <Typography
                  variant="headlineLarge"
                  color="onSurface.main"
                  gutterBottom
                  mb={0}
                >
                  {title}
                </Typography>

                {titleButton && <Box ml={1}>{titleButton}</Box>}
              </Box>
            )}
            {children}
          </Box>
        </Paper>
      )}
      {loading && <LoadingBackdrop />}
    </Container>
  );
};

export default PageContainer;
