import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { VehicleSuggestion } from "../../types/Vehicles";
import VehicleStats from "./VehicleStats";

const VehicleSuggestionCard = ({
  vehicleSuggestion,
  onButtonClick,
}: {
  vehicleSuggestion: VehicleSuggestion;
  onButtonClick: (suggestion: VehicleSuggestion) => void;
}) => {
  const {
    match,
    make: makeSuggestion,
    model: modelSuggestion,
    comparisonDetails,
    pros,
    cons,
    value,
    comfort,
    performance,
  } = vehicleSuggestion;
  const makeName = match?.make ? match.make.name : makeSuggestion;
  const modelName = match?.model ? match.model.name : modelSuggestion;
  const imageUrl = match?.photo ? match.photo.href : "/photo-placeholder.png";

  return (
    <Card
      sx={{
        display: "flex",
        backgroundColor: "surface.main",
        flexDirection: "column",
      }}
      variant="outlined"
    >
      <Box
        sx={{
          p: {
            xs: 1,
            sm: 2,
          },
          display: "flex",
          flexDirection: "column",
          flex: 1,
          justifyContent: "start",
        }}
      >
        <Box sx={{ display: "flex", maxWidth: "100%" }}>
          <CardMedia
            component="img"
            sx={{
              minWidth: "5rem",
              flex: 2,
              mr: 1,
              ml: {
                xs: -1,
                sm: -2,
              },
              maxWidth: "15rem",
              aspectRatio: 1.5,
              objectFit: "contain",
            }}
            image={imageUrl}
            alt={`${makeName} ${modelName}`}
          />
          <Box
            sx={{
              display: "flex",
              flex: 3,
              flexDirection: "column",
              overflow: "hidden",
            }}
          >
            <Box>
              <Typography variant="titleMedium" color="onSurface.main">
                {makeName} {modelName}
              </Typography>
            </Box>
            <Box>
              <VehicleStats
                value={value}
                comfort={comfort}
                performance={performance}
              />
            </Box>
          </Box>
        </Box>
        <Stack spacing={2} my={2}>
          <Typography variant="bodyLarge" color="onSurface.main">
            {comparisonDetails}
          </Typography>

          <Typography variant="bodyLarge" color="onSurface.main">
            <b>Pros:</b> {pros}
          </Typography>

          <Typography variant="bodyLarge" color="onSurface.main">
            <b>Cons:</b> {cons}
          </Typography>
        </Stack>
        <Box
          mt={1}
          sx={{ display: "flex", justifyContent: "end", flexGrow: 1 }}
        >
          <Box sx={{ display: "flex", alignItems: "end" }}>
            <Button
              size="large"
              color="secondary"
              variant="contained"
              disableElevation={true}
              onClick={() => onButtonClick(vehicleSuggestion)}
            >
              Quote
            </Button>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default VehicleSuggestionCard;
