import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  Path,
  PathValue,
} from "react-hook-form";

import { getNestedProperty } from "../../helpers/object";
import {
  ValidationOptions,
  validationOptionsToControlRules,
} from "../../types/Validations";

interface APCheckboxProps<T extends FieldValues> {
  name: Path<T>;
  label?: string;
  validations?: ValidationOptions;
  errors?: FieldErrors<T>;
  control: Control<T>;
  defaultValue: PathValue<T, Path<T>> | undefined;
  showRequired?: boolean;
  highlightOnEmpty?: boolean;
  readOnly?: boolean;
}

const APCheckbox = <T extends FieldValues>({
  name,
  label,
  validations,
  errors,
  control,
  defaultValue,
  showRequired,
  highlightOnEmpty,
  readOnly,
}: APCheckboxProps<T>) => {
  const error = errors && getNestedProperty(name, errors);

  return (
    <FormControl error={!!errors?.[name]}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={
          validations
            ? validationOptionsToControlRules(label ?? "", validations)
            : undefined
        }
        render={({ field }) => (
          <FormControlLabel
            sx={{
              mx: 0,
              color: highlightOnEmpty && !field.value ? "error.main" : "",
            }}
            label={showRequired ? `${label} *` : label}
            control={
              <Checkbox
                {...field}
                required={validations?.required}
                sx={{
                  p: 0,
                  mr: label ? 1 : 0,
                }}
                checked={field.value}
                onChange={(e) => field.onChange(e.target.checked)}
                readOnly={readOnly}
                disabled={readOnly}
              />
            }
          />
        )}
      />
      {!!error && (
        <FormHelperText sx={{ ml: 0 }}>{`${error?.message}`}</FormHelperText>
      )}
    </FormControl>
  );
};

export default APCheckbox;
