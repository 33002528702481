import { useQuery } from "react-query";

import { CalculatedQuote } from "../../types/Quote";
import { useApi } from "../ApiProvider";
import {
  APQueryOptions,
  SHORT_STALE_TIME,
  useAPQuery,
} from "../APQueryProvider";

export const getCalculatedQuote = (
  quoteId: string,
  options: APQueryOptions = {}
) => {
  const { fetchWithAuth } = useApi();
  const { isQueryEnabled } = useAPQuery();

  return useQuery({
    queryKey: ["calculated-quote", quoteId],
    queryFn: (): Promise<CalculatedQuote> => {
      return fetchWithAuth<CalculatedQuote>("getCalculatedQuote", quoteId);
    },
    staleTime: SHORT_STALE_TIME,
    refetchOnMount: options.useCached ? true : "always",
    enabled: isQueryEnabled(options),
  });
};
