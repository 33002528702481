import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

export type SelectDropdownOption<T> = {
  id: T;
  label: string;
};

const APSelectDropDown = <T extends string>({
  label,
  selectedOptionId,
  options,
  onOptionChange,
}: {
  label: string;
  selectedOptionId: T;
  options: SelectDropdownOption<T>[];
  onOptionChange: (optionId: T) => void;
}) => {
  return (
    <FormControl>
      <InputLabel>{label}</InputLabel>
      <Select
        labelId="ap-select-drop-down"
        id="ap-select-drop-down"
        value={selectedOptionId}
        sx={{ backgroundColor: "white", width: "inherit" }}
        onChange={(event) => {
          const value = event.target.value as T;
          onOptionChange(value);
        }}
        label={label}
      >
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default APSelectDropDown;
