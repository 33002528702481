import { useQuery } from "react-query";

import { Faq } from "../../types/Faq";
import { useApi } from "../ApiProvider";
import { APQueryOptions, useAPQuery } from "../APQueryProvider";

export type FaqsFilter = {
  appLocationId: string;
  quoteId?: string;
};

export const getFaqs = (filter: FaqsFilter, options: APQueryOptions = {}) => {
  const { fetchWithAuth } = useApi();
  const { isQueryEnabled } = useAPQuery();

  return useQuery({
    queryKey: ["faqs", filter.appLocationId],
    queryFn: (): Promise<Faq[]> => {
      return fetchWithAuth<Faq[]>("getFaqs", filter.appLocationId, {
        queryParams: {
          quoteId: filter.quoteId,
        },
      });
    },
    enabled: isQueryEnabled(options),
  });
};
