import { ApplicationScope } from "../types/App";

type PageDirectoryEntry = (entityId?: string, secondaryId?: string) => string;

export type PageDirectoryEntryName =
  | "home"
  | "initialUser"
  | "userDirectDebit"
  | "employerHome"
  | "employerApproveDrivers"
  | "employerInvoices"
  | "employerPackagingAgreements"
  | "employerPayrollAdvice"
  | "employerFBTReports"
  | "employerEngagementReports"
  | "employerPromotions"
  | "employerLuxuryLeaseReports"
  | "viewDossiers"
  | "viewDossier"
  | "viewOpportunity"
  | "dossierEmployerSetup"
  | "dossierFinanceApplication"
  | "dossierPackagingAgreement"
  | "dossierDirectDebit"
  | "dossierActionConfirmation"
  | "viewQuote"
  | "viewQuoteVehicleSuggestions"
  | "createQuote"
  | "editQuote"
  | "cloneQuote"
  | "personaliseQuote"
  | "viewSurvey"
  | "viewKeycloakUser"
  | "crmLinkRouter"
  | "modalFaq"
  | "modalAppLocation"
  | "modalAppLocationFaq"
  | "modalEnableMFA";

export type PageConfigName = "dossierMaxQuoteCount";

export type PageDirectory = Record<
  PageDirectoryEntryName,
  PageDirectoryEntry | undefined
>;

export type PageConfigurations = Record<PageConfigName, unknown>;

type PageRouteMap = {
  internal?: PageDirectoryEntry;
  external?: PageDirectoryEntry;
  shared?: PageDirectoryEntry;
};

type PageConfigMap = {
  internal?: unknown;
  external?: unknown;
  shared?: unknown;
};

const pageRoutes: Record<PageDirectoryEntryName, PageRouteMap> = {
  home: {
    internal: () => `/internal/home`,
    external: () => `/home`,
  },
  initialUser: {
    external: () => `/user`,
  },
  userDirectDebit: {
    external: () => `/user/direct-debit`,
  },
  employerHome: {
    external: () => `/employer-home`,
  },
  employerApproveDrivers: {
    external: () => `/employer-approve-drivers`,
  },
  employerInvoices: {
    external: () => `/employer-invoices`,
  },
  employerPackagingAgreements: {
    external: () => `/employer-packaging-agreements`,
  },
  employerPayrollAdvice: {
    external: () => `/employer-payroll-advices`,
  },
  employerFBTReports: {
    external: () => `/employer-fbt-reports`,
  },
  employerEngagementReports: {
    external: () => `/employer-engagement-reports`,
  },
  employerPromotions: {
    external: () => `/employer-promotions`,
  },
  employerLuxuryLeaseReports: {
    external: () => `/employer-luxury-lease-reports`,
  },
  viewDossiers: {
    external: () => `/dossiers`,
  },
  viewDossier: {
    internal: (dossierId?: string) => `/internal/dossiers/${dossierId}`,
    external: (dossierId?: string) => `/dossiers/${dossierId}`,
  },
  viewOpportunity: {
    internal: (opportunityId?: string) =>
      `/internal/opportunities/${opportunityId}`,
  },
  dossierEmployerSetup: {
    external: (dossierId?: string) => `/dossiers/${dossierId}/employer-setup`,
  },
  dossierFinanceApplication: {
    external: (dossierId?: string) =>
      `/dossiers/${dossierId}/finance-application`,
  },
  dossierPackagingAgreement: {
    external: (dossierId?: string) =>
      `/dossiers/${dossierId}/packaging-agreement`,
  },
  dossierDirectDebit: {
    external: (dossierId?: string) => `/dossiers/${dossierId}/direct-debit`,
  },
  dossierActionConfirmation: {
    external: (dossierId?: string, eventId?: string) =>
      `/dossiers/${dossierId}/action-confirmation/${eventId}`,
  },
  viewQuote: {
    internal: (quoteId?: string) => `/internal/quote/${quoteId}`,
    external: (quoteId?: string) => `/quote/${quoteId}`,
  },
  viewQuoteVehicleSuggestions: {
    internal: (quoteId?: string) =>
      `/internal/quote/${quoteId}/vehicle-suggestions`,
    external: (quoteId?: string) => `/quote/${quoteId}/vehicle-suggestions`,
  },
  createQuote: {
    internal: (dossierId?: string) =>
      `/internal/dossiers/${dossierId}/quote/create`,
    external: (dossierId?: string) => `/dossiers/${dossierId}/quote/create`,
  },
  editQuote: {
    internal: (quoteId?: string) => `/internal/quote/${quoteId}/edit`,
    external: (quoteId?: string) => `/quote/${quoteId}/edit`,
  },
  cloneQuote: {
    internal: (quoteId?: string) => `/internal/quote/${quoteId}/clone`,
    external: (quoteId?: string) => `/quote/${quoteId}/clone`,
  },
  personaliseQuote: {
    internal: (quoteId?: string) => `/internal/quote/${quoteId}/personalise`,
    external: (quoteId?: string) => `/quote/${quoteId}/personalise`,
  },
  viewSurvey: {
    external: (surveyId?: string) => `/survey/${surveyId}`,
  },
  viewKeycloakUser: {
    internal: (contactId?: string) =>
      `/internal/contact/${contactId}/keycloak-user`,
  },
  crmLinkRouter: {
    internal: (destinationId?: string) => `/internal/crm-link/${destinationId}`,
  },
  modalFaq: {
    shared: (faqId?: string) => `/modal/faq/${faqId}`,
  },
  modalAppLocation: {
    shared: (applocationId?: string) => `/modal/app-location/${applocationId}`,
  },
  modalAppLocationFaq: {
    shared: (applocationId?: string, faqId?: string) =>
      `/modal/app-location/${applocationId}/faq/${faqId}`,
  },
  modalEnableMFA: {
    external: () => `/modal/enable-mfa`,
  },
};

const pageConfigurations: Record<PageConfigName, PageConfigMap> = {
  dossierMaxQuoteCount: {
    internal: 1000,
    external: 5,
  },
};

function getRouteByType(
  entryName: PageDirectoryEntryName,
  applicationScope: ApplicationScope
) {
  return (
    pageRoutes[entryName][applicationScope] || pageRoutes[entryName]["shared"]
  );
}

function getConfigByName(
  configName: PageConfigName,
  applicationScope: ApplicationScope
) {
  return (
    pageConfigurations[configName][applicationScope] ||
    pageConfigurations[configName]["shared"] ||
    undefined
  );
}

export const buildPageDirectory = (
  applicationType: ApplicationScope
): PageDirectory => {
  return (Object.keys(pageRoutes) as PageDirectoryEntryName[]).reduce(
    (directory, entryName: PageDirectoryEntryName) => {
      directory[entryName] = getRouteByType(entryName, applicationType);
      return directory;
    },
    {} as PageDirectory
  );
};

export const buildPageConfigurations = (
  applicationType: ApplicationScope
): PageConfigurations => {
  return (Object.keys(pageConfigurations) as PageConfigName[]).reduce(
    (configurations, configName: PageConfigName) => {
      configurations[configName] = getConfigByName(configName, applicationType);
      return configurations;
    },
    {} as PageConfigurations
  );
};
