import { useMutation } from "react-query";

import { useApi } from "../ApiProvider";
import { APQueryOptions, useAPQuery } from "../APQueryProvider";

export type SetProductTourViewPayload = {
  latestStepId?: string;
  completed?: boolean;
};

export const setProductTourView = (
  productTourId: string,
  options: APQueryOptions = {}
) => {
  const { fetchWithAuth } = useApi();
  const { defaultOnMutateError, defaultOnMutateSuccess } = useAPQuery();

  return useMutation({
    mutationFn: (payload: SetProductTourViewPayload): Promise<string> => {
      return fetchWithAuth<string>("setProductTourView", productTourId, {
        requestOptions: { method: "PATCH", body: JSON.stringify(payload) },
      });
    },
    onError: defaultOnMutateError(),
    onSuccess: defaultOnMutateSuccess(options),
  });
};
