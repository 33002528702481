export const arraysHaveIntersection = (arr1: unknown[], arr2: unknown[]) => {
  return arr1.some((item) => arr2.includes(item));
};

export const removeDuplicatesByKey = <T extends Record<string, unknown>>(
  arr: T[],
  key: keyof T
): T[] => {
  const uniqueMap = new Map<T[keyof T], T>();

  return arr.filter((item) => {
    if (uniqueMap.has(item[key])) {
      return false;
    }
    uniqueMap.set(item[key], item);
    return true;
  });
};
