import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useState } from "react";

import Terms, { TermId } from "../terms/components/Terms";
import APModal from "./APModal";

const TermsButton = ({ termsId }: { termsId: TermId }) => {
  const [showTermsModal, setShowTermsModal] = useState(false);

  const onTermsClick = () => {
    setShowTermsModal(true);
  };

  const onCloseTermsModal = () => {
    setShowTermsModal(false);
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Box>
        <Typography
          sx={{ cursor: "pointer" }}
          variant="labelSmall"
          onClick={() => onTermsClick()}
        >
          ^ Terms
        </Typography>
      </Box>
      <APModal isOpen={showTermsModal} onClose={onCloseTermsModal}>
        <Terms termsId={termsId} />
      </APModal>
    </Box>
  );
};

export default TermsButton;
