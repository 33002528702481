import { useMutation } from "react-query";

import { QuotePayPeriod, VehiclePurchaseType } from "../../types/Quote";
import { State } from "../../types/State";
import { useApi } from "../ApiProvider";
import { APQueryOptions, useAPQuery } from "../APQueryProvider";

export type PatchQuotePayload = {
  dossierId?: string;
  name?: string;
  payPeriod?: QuotePayPeriod;
  purchaseType?: VehiclePurchaseType;
  state?: State;
  variantId?: string;
  term?: number;
  annualKilometres?: number;
  salary?: number;
  businessUsage?: number;
  discount?: number;
  initialOdometer?: number;
  listPrice?: number;
  optionalEquipment?: string[];
  referralCode?: string;
  aiQueryId?: string;
};

export const updateQuote = (quoteId: string, options: APQueryOptions = {}) => {
  const { fetchWithAuth } = useApi();
  const {
    defaultOnMutateError,
    defaultOnMutateSuccess,
    defaultOnMutateSettled,
  } = useAPQuery();

  return useMutation({
    mutationFn: (payload: PatchQuotePayload): Promise<string> => {
      return fetchWithAuth<string>("updateQuote", quoteId, {
        requestOptions: { method: "PATCH", body: JSON.stringify(payload) },
      });
    },
    onError: defaultOnMutateError(),
    onSuccess: defaultOnMutateSuccess(options),
    onSettled: (data, error, payload) => {
      const invalidateList = [
        ["quote", quoteId],
        ["calculated-quote", quoteId],
        ["quote-vehicle-suggestions", quoteId],
        ["quote-vehicle-images", quoteId],
      ];

      if (payload.dossierId) {
        invalidateList.push(
          ["quotes", payload.dossierId],
          ["dossier", payload.dossierId]
        );
      }
      defaultOnMutateSettled(invalidateList);
    },
  });
};
