import { useTheme } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateValidationError } from "@mui/x-date-pickers/models/validation";
import { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  Path,
  PathValue,
  UseFormClearErrors,
  UseFormSetError,
} from "react-hook-form";

import { getNestedProperty } from "../../helpers/object";

interface APDateFieldProps<T extends FieldValues> {
  name: Path<T>;
  label: string;
  control: Control<T>;
  defaultValue: PathValue<T, Path<T>> | undefined;
  errors: FieldErrors<T>;
  setError: UseFormSetError<T>;
  clearErrors: UseFormClearErrors<T>;
  minDate?: Dayjs;
  maxDate?: Dayjs;
  minDateErrorMessage?: string;
  maxDateErrorMessage?: string;
  showRequired?: boolean;
  highlightOnEmpty?: boolean;
  readOnly?: boolean;
  clearable?: boolean;
}

const APDateField = <T extends FieldValues>({
  name,
  label,
  control,
  defaultValue,
  errors,
  setError,
  clearErrors,
  minDate,
  maxDate,
  maxDateErrorMessage,
  showRequired,
  highlightOnEmpty,
  readOnly,
  clearable,
}: APDateFieldProps<T>) => {
  const [dateError, setDateError] = useState<DateValidationError | null>(null);
  const formError = getNestedProperty(name, errors);
  const theme = useTheme();

  const getErrorMessage = (error: DateValidationError) => {
    let message = null;
    switch (error) {
      case "maxDate":
        if (maxDateErrorMessage) {
          message = maxDateErrorMessage;
        }
        break;
      default:
        break;
    }

    return message;
  };

  useEffect(() => {
    if (dateError) {
      setError(name, {
        type: "string",
        message: getErrorMessage(dateError) ?? "",
      });
    } else {
      clearErrors(name);
    }
  }, [dateError]);

  return (
    <Controller<T>
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{ validate: () => !dateError }}
      render={({ field }) => (
        <DatePicker
          {...field}
          format="DD/MM/YYYY"
          label={showRequired ? `${label} *` : label}
          minDate={minDate}
          maxDate={maxDate}
          onError={(newError) => setDateError(newError)}
          readOnly={readOnly}
          slotProps={{
            actionBar: {
              actions: ["clear", "accept"],
            },
            textField: {
              helperText: `${formError ? formError?.message : ""}`,
              error: !!formError || (highlightOnEmpty && !field.value),
            },
            field: {
              clearable,
            },
            day: {
              sx: {
                "&.Mui-selected": {
                  color: `${theme.palette.onPrimary.main} !important`,
                  backgroundColor: `${theme.palette.primary.main} !important`,
                },
                "&.Mui-selected:hover": {
                  color: "onPrimary.main",
                  backgroundColor: "primary.main",
                },
              },
            },
          }}
        />
      )}
    />
  );
};

export default APDateField;
