import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";

import { useAuth } from "../../api/AuthProvider";
import { FaqContextIds } from "../../components/AppLocation";
import { addFaqModalParamsToHref } from "../../helpers/faq";
import { addModalToHref } from "../../helpers/routes";
import { Faq } from "../../types/Faq";

const FaqListCard = ({
  faq,
  appLocationId,
  highlightText,
  flat,
  contextIds,
}: {
  faq: Faq;
  appLocationId: string;
  highlightText?: boolean;
  flat?: boolean;
  contextIds: FaqContextIds;
}) => {
  const { getScopedPageUrl } = useAuth();
  let linkTo = addModalToHref(
    getScopedPageUrl("modalAppLocationFaq", appLocationId, faq.id),
    window.location.href
  );
  linkTo = addFaqModalParamsToHref(linkTo, appLocationId, contextIds);

  let textColor;
  let backgroundColor;

  if (faq.isHighlighted) {
    textColor = "white";
    backgroundColor = "tertiaryGreen.main";
  } else if (highlightText) {
    textColor = "secondary";
    backgroundColor = "surface.main";
  } else {
    textColor = "initial";
    backgroundColor = "surface.main";
  }

  return (
    <Card
      sx={{
        display: "flex",
        flex: 1,
        height: "inherit",
        border: flat ? "none" : null,
        backgroundColor: backgroundColor,
      }}
      variant="outlined"
    >
      <CardActionArea
        color="primary"
        sx={{
          display: "flex",
          py: 1,
          px: 2,
        }}
        component={Link}
        to={linkTo}
      >
        <Box>
          <Typography variant="titleLarge" align="center" color={textColor}>
            {faq.question}
          </Typography>
        </Box>
      </CardActionArea>
    </Card>
  );
};

export default FaqListCard;
