import { ApplicationScope } from "../types/App";

type ApiDirectoryEntry = (entityId?: string) => string;

export type ApiDirectoryEntryName =
  | "getMyUser"
  | "getMyContact"
  | "setMyMFA"
  | "setMyAIConsent"
  | "getContact"
  | "setUserMFA"
  | "getKeycloakUserInfo"
  | "setTemporaryPassword"
  | "getMyBankAccount"
  | "createMyBankAccount"
  | "getMyEmployerPayrollCycles"
  | "qualifyLead"
  | "qualifyNewLead"
  | "getMyDossiers"
  | "getMyDossiersForEmployerApproval"
  | "approveDriver"
  | "rejectDriver"
  | "getDocuments"
  | "getDocumentPDF"
  | "getDossier"
  | "getDossierByOpportunityId"
  | "getDossierStatus"
  | "getEmptyDossierStatus"
  | "getDossierQuotes"
  | "setPrimaryQuote"
  | "getFinanceApplication"
  | "updateFinanceApplication"
  | "updateFinanceApplicationImages"
  | "getAssetsAndLiabilitiesTypes"
  | "getSpousalDeclarationPDF"
  | "getDirectDebitTermsPDF"
  | "createDossierBankAccount"
  | "getPackagingAgreementPDF"
  | "getEmployeePackagingAgreementPDF"
  | "requestPackagingAgreement"
  | "signPackagingAgreement"
  | "requestEmployerAccountSetup"
  | "getQuote"
  | "getCalculatedQuote"
  | "getQuoteVehicleImages"
  | "getQuotePDF"
  | "getQuoteVehicleSuggestions"
  | "createQuote"
  | "updateQuote"
  | "setQuoteVisibility"
  | "getSyncQuotesStatus"
  | "syncQuotes"
  | "getVehicleMakes"
  | "getVehicleModels"
  | "getVehicleModelYears"
  | "getVehicleVariants"
  | "getVehicleOptions"
  | "getFile"
  | "getFaqAppLocations"
  | "getFaqs"
  | "getFaq"
  | "getFaqVote"
  | "createFaqVote"
  | "createFaqView"
  | "getSurvey"
  | "hasSurveyAwaitingResponse"
  | "updateSurveyResponse"
  | "getPublicConfig"
  | "getProductTours"
  | "createProductTourView"
  | "setProductTourView";

export type ApiDirectory = Record<
  ApiDirectoryEntryName,
  ApiDirectoryEntry | undefined
>;

type ApiRouteMap = {
  internal?: ApiDirectoryEntry;
  external?: ApiDirectoryEntry;
  shared?: ApiDirectoryEntry;
};

const apiRoutes: Record<ApiDirectoryEntryName, ApiRouteMap> = {
  getMyUser: {
    internal: () => "/internal/users/me",
    external: () => "/external/driver/users/me",
  },
  getMyContact: {
    external: () => "/external/driver/users/me/contact",
  },
  setMyMFA: {
    external: () => "/external/driver/users/me/set-mfa",
  },
  setMyAIConsent: {
    external: () => "/external/driver/users/me/ai-consent",
  },
  getContact: {
    internal: (contactId?: string) => `/internal/users/${contactId}`,
  },
  setUserMFA: {
    internal: (contactId?: string) => `/internal/users/${contactId}/set-mfa`,
  },
  getKeycloakUserInfo: {
    internal: (contactId?: string) =>
      `/internal/users/${contactId}/keycloak-info`,
  },
  setTemporaryPassword: {
    internal: (contactId?: string) =>
      `/internal/users/${contactId}/set-temp-password`,
  },
  getMyBankAccount: {
    external: () => "/external/driver/users/me/direct-debit",
  },
  createMyBankAccount: {
    external: () => "/external/driver/users/me/direct-debit",
  },
  getMyEmployerPayrollCycles: {
    external: () => "/external/employer/users/me/employer-payroll-cycles",
  },
  approveDriver: {
    external: (dossierId?: string) =>
      `/external/employer/dossiers/${dossierId}/approve-driver`,
  },
  rejectDriver: {
    external: (dossierId?: string) =>
      `/external/employer/dossiers/${dossierId}/reject-driver`,
  },
  getDocuments: {
    external: () => "/external/employer/document-actions/list",
  },
  getDocumentPDF: {
    external: (documentId?: string) =>
      `/external/employer/documents/${documentId}/pdf`,
  },
  getMyDossiers: {
    external: () => "/external/driver/dossier-actions/list",
  },
  getMyDossiersForEmployerApproval: {
    external: () => "/external/employer/dossier-actions/for-approval",
  },
  qualifyLead: {
    external: () => "/external/driver/users/qualify-lead",
  },
  qualifyNewLead: {
    external: () => "/external/driver/users/qualify-new-lead",
  },
  getDossier: {
    internal: (dossierId?: string) => `/internal/dossiers/${dossierId}`,
    external: (dossierId?: string) => `/external/driver/dossiers/${dossierId}`,
  },
  getDossierByOpportunityId: {
    internal: (opporuntiyId?: string) =>
      `/internal/dossier-actions/id-from-opportunity/${opporuntiyId}`,
  },
  getDossierStatus: {
    internal: (dossierId?: string) => `/internal/dossiers/${dossierId}/status`,
    external: (dossierId?: string) =>
      `/external/driver/dossiers/${dossierId}/status`,
  },
  getEmptyDossierStatus: {
    shared: () => "/shared/dossiers/empty-status",
  },
  getDossierQuotes: {
    internal: (dossierId?: string) => `/internal/dossiers/${dossierId}/quotes`,
    external: (dossierId?: string) =>
      `/external/driver/dossiers/${dossierId}/quotes`,
  },
  setPrimaryQuote: {
    internal: (dossierId?: string) =>
      `/internal/dossiers/${dossierId}/set-primary-quote`,
    external: (dossierId?: string) =>
      `/external/driver/dossiers/${dossierId}/set-primary-quote`,
  },
  getFinanceApplication: {
    external: (dossierId?: string) =>
      `/external/driver/dossiers/${dossierId}/finance-application`,
  },
  updateFinanceApplication: {
    external: (dossierId?: string) =>
      `/external/driver/dossiers/${dossierId}/finance-application`,
  },
  updateFinanceApplicationImages: {
    external: (dossierId?: string) =>
      `/external/driver/dossiers/${dossierId}/finance-application/images`,
  },
  getAssetsAndLiabilitiesTypes: {
    shared: () => "/shared/dossiers/assets-and-liability-types",
  },
  getSpousalDeclarationPDF: {
    shared: () => "/shared/dossiers/spousal-declaration-pdf",
  },
  getDirectDebitTermsPDF: {
    shared: () => "/shared/dossiers/direct-debit-terms-pdf",
  },
  createDossierBankAccount: {
    external: (dossierId?: string) =>
      `/external/driver/dossiers/${dossierId}/direct-debit`,
  },
  getPackagingAgreementPDF: {
    external: (dossierId?: string) =>
      `/external/driver/dossiers/${dossierId}/packaging-agreement-pdf`,
  },
  getEmployeePackagingAgreementPDF: {
    external: (dossierId?: string) =>
      `/external/employer/dossiers/${dossierId}/packaging-agreement-pdf`,
  },
  requestPackagingAgreement: {
    external: (dossierId?: string) =>
      `/external/driver/dossiers/${dossierId}/request-packaging-agreement`,
  },
  signPackagingAgreement: {
    external: (dossierId?: string) =>
      `/external/driver/dossiers/${dossierId}/sign-packaging-agreement`,
  },
  requestEmployerAccountSetup: {
    external: (dossierId?: string) =>
      `/external/driver/dossiers/${dossierId}/request-account-setup`,
  },
  getQuote: {
    internal: (quoteId?: string) => `/internal/quotes/${quoteId}`,
    external: (quoteId?: string) => `/external/driver/quotes/${quoteId}`,
  },
  getCalculatedQuote: {
    internal: (quoteId?: string) => `/internal/quotes/${quoteId}/calculated`,
    external: (quoteId?: string) =>
      `/external/driver/quotes/${quoteId}/calculated`,
  },
  getQuoteVehicleImages: {
    internal: (quoteId?: string) =>
      `/internal/quotes/${quoteId}/vehicle-images`,
    external: (quoteId?: string) =>
      `/external/driver/quotes/${quoteId}/vehicle-images`,
  },
  getQuotePDF: {
    internal: (quoteId?: string) => `/internal/quotes/${quoteId}/pdf`,
    external: (quoteId?: string) => `/external/driver/quotes/${quoteId}/pdf`,
  },
  getQuoteVehicleSuggestions: {
    internal: (quoteId?: string) =>
      `/internal/quotes/${quoteId}/vehicle-suggestions`,
    external: (quoteId?: string) =>
      `/external/driver/quotes/${quoteId}/vehicle-suggestions`,
  },
  getSyncQuotesStatus: {
    internal: (contactId?: string) =>
      `/internal/users/${contactId}/sync-quotes/status`,
  },
  syncQuotes: {
    internal: (contactId?: string) =>
      `/internal/users/${contactId}/sync-quotes`,
  },
  createQuote: {
    internal: (dossierId?: string) => `/internal/dossiers/${dossierId}/quotes`,
    external: (dossierId?: string) =>
      `/external/driver/dossiers/${dossierId}/quotes`,
  },
  updateQuote: {
    internal: (quoteId?: string) => `/internal/quotes/${quoteId}`,
    external: (quoteId?: string) => `/external/driver/quotes/${quoteId}`,
  },
  setQuoteVisibility: {
    internal: (quoteId?: string) =>
      `/internal/quotes/${quoteId}/set-visibility`,
  },
  getVehicleMakes: {
    shared: () => "/shared/vehicles/makes",
  },
  getVehicleModels: {
    shared: () => "/shared/vehicles/models",
  },
  getVehicleModelYears: {
    shared: () => "/shared/vehicles/model-years",
  },
  getVehicleVariants: {
    shared: () => "/shared/vehicles/variants",
  },
  getVehicleOptions: {
    shared: (variantId?: string) =>
      `/shared/vehicles/variants/${variantId}/options`,
  },
  getFile: {
    shared: (filePath?: string) => `${filePath}`,
  },
  getFaqAppLocations: {
    shared: (pageId?: string) =>
      `/shared/app/pages/${pageId}/faq-app-locations`,
  },
  getFaqs: {
    shared: (appLocationId?: string) =>
      `/shared/app/app-locations/${appLocationId}/faqs`,
  },
  getFaq: {
    shared: (faqId?: string) => `/shared/faqs/${faqId}`,
  },
  getFaqVote: {
    external: (faqId?: string) => `/external/driver/faqs/${faqId}/vote`,
  },
  createFaqVote: {
    external: (faqId?: string) => `/external/driver/faqs/${faqId}/vote`,
  },
  createFaqView: {
    external: (faqId?: string) => `/external/driver/faqs/${faqId}/view`,
  },
  getSurvey: {
    external: (surveyId?: string) => `/external/driver/surveys/${surveyId}`,
  },
  hasSurveyAwaitingResponse: {
    external: (surveyId?: string) =>
      `/external/driver/surveys/${surveyId}/awaiting-response`,
  },
  updateSurveyResponse: {
    external: (surveyId?: string) =>
      `/external/driver/surveys/${surveyId}/response`,
  },
  getPublicConfig: {
    shared: () => "/shared/configurations/public",
  },
  getProductTours: {
    external: (pageId?: string) =>
      `/external/driver/product-tour-actions/page/${pageId}`,
  },
  createProductTourView: {
    external: (productTourId?: string) =>
      `/external/driver/product-tours/${productTourId}/view`,
  },
  setProductTourView: {
    external: (productTourId?: string) =>
      `/external/driver/product-tours/${productTourId}/view`,
  },
};

function getRouteByType(
  entryName: ApiDirectoryEntryName,
  applicationScope: ApplicationScope
) {
  return (
    apiRoutes[entryName][applicationScope] || apiRoutes[entryName]["shared"]
  );
}

export const buildApiDirectory = (
  applicationType: ApplicationScope
): ApiDirectory => {
  return (Object.keys(apiRoutes) as ApiDirectoryEntryName[]).reduce(
    (directory, entryName: ApiDirectoryEntryName) => {
      directory[entryName] = getRouteByType(entryName, applicationType);
      return directory;
    },
    {} as ApiDirectory
  );
};
