import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import React from "react";

import { toDollars } from "../../helpers/currencies";
import { formatDate } from "../../helpers/dates";
import { APDocument, APDocumentContentType } from "../../types/Document";

const valueTransformers: Partial<
  Record<keyof APDocument, (value: string) => string>
> = {
  date: formatDate,
  date2: formatDate,
};

const contentTypeImageMap: Record<APDocumentContentType, string> = {
  file: "/file.png",
  website: "/website.png",
  video: "/video.png",
};

const DocumentCard = ({
  document,
  onDownload,
  onOpenUrl,
  titleFields,
  bodyFields,
  expandFields,
  fieldLabels,
  cardColor,
}: {
  document: APDocument;
  onDownload: (documentId: string, fileName: string) => void;
  onOpenUrl: (url: string) => void;
  titleFields: (keyof APDocument)[];
  bodyFields: (keyof APDocument)[][];
  expandFields: (keyof APDocument)[][];
  fieldLabels?: Partial<Record<keyof APDocument, string>>;
  cardColor?: string;
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    setExpanded(!expanded);
  };

  const handleCardClick = () => {
    if (document.mediaType === "file" && document.fileName) {
      onDownload(document.id, document.fileName || "document");
    } else if (document.mediaType === "url" && document.url) {
      onOpenUrl(document.url);
    }
  };

  const renderDocumentField = (
    documentKeys: (keyof APDocument)[],
    isTitle?: boolean
  ) => {
    const [firstField, secondField] = documentKeys;
    const firstTransformer = firstField && valueTransformers[firstField];
    const secondTransformer = secondField && valueTransformers[secondField];

    const firstValue =
      firstField && document[firstField]
        ? firstTransformer
          ? firstTransformer(`${document[firstField]}`)
          : document[firstField]
        : null;
    const secondValue =
      secondField && document[secondField]
        ? secondTransformer
          ? secondTransformer(`${document[secondField]}`)
          : document[secondField]
        : null;
    const fieldLabel =
      firstField && fieldLabels ? fieldLabels[firstField] : null;

    const fieldText = `${firstValue || ""}${
      secondValue ? ` - ${secondValue}` : ""
    }${fieldLabel ? ` - ${fieldLabel}` : ""}`;
    return (
      <React.Fragment key={firstField}>
        {firstValue && (
          <Typography
            variant={isTitle ? "titleMedium" : "bodyMedium"}
            sx={{ whiteSpace: "pre-line" }}
          >
            {fieldText}
          </Typography>
        )}
      </React.Fragment>
    );
  };

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: cardColor || "surface.main",
        rowGap: 2,
      }}
      variant="outlined"
    >
      <CardActionArea
        sx={{
          p: 1.5,
          display: "flex",
          alignItems: "stretch",
          flexDirection: "column",
        }}
        onClick={handleCardClick}
        disableRipple={true}
      >
        <Box sx={{ display: "flex" }}>
          <Box sx={{ display: "flex", alignItems: "start" }}>
            <CardMedia
              component="img"
              sx={{
                width: "1.25rem",
                mr: 1,
              }}
              image={contentTypeImageMap[document.contentType]}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box>{renderDocumentField(titleFields, true)}</Box>
              {document.amount && (
                <Typography variant="titleMedium">
                  {toDollars(document.amount, 2)}
                </Typography>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mt: 0.5,
              }}
            >
              <Box>{bodyFields.map((field) => renderDocumentField(field))}</Box>
              <IconButton
                component="div"
                size="small"
                aria-label="favourite-quote"
                color="onSurface"
                onClick={handleExpandClick}
              >
                {expanded ? (
                  <ExpandLessIcon fontSize="small" />
                ) : (
                  <ExpandMoreIcon fontSize="small" />
                )}
              </IconButton>
            </Box>
          </Box>
        </Box>
        <Collapse in={expanded}>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ width: "1.25rem", mr: 1 }} />
            <Box sx={{ flex: 1 }}>
              <Divider sx={{ borderColor: "outlineVariant.main", my: 1 }} />
              {expandFields.map((field) => renderDocumentField(field))}
            </Box>
          </Box>
        </Collapse>
      </CardActionArea>
    </Card>
  );
};

export default DocumentCard;
