import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Box from "@mui/material/Box";

import { NEUTRAL_WHITE, PRIMARY_COLOR, TERTIARY_COLOR } from "../../theme";

type IconColor = "primary" | "white" | "tertiary";

const InfoButton = ({
  onClick,
  iconColor = "primary",
}: {
  onClick?: () => void;
  iconColor?: IconColor;
}) => {
  let color: string;
  switch (iconColor) {
    case "primary":
      color = PRIMARY_COLOR;
      break;
    case "white":
      color = NEUTRAL_WHITE;
      break;
    case "tertiary":
      color = TERTIARY_COLOR;
      break;
  }

  return (
    <Box onClick={onClick} sx={{ cursor: "pointer" }}>
      <FontAwesomeIcon icon={faCircleInfo} color={color} size="xl" />
    </Box>
  );
};

export default InfoButton;
