import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import { ReactNode } from "react";

export type ModalSize = "small" | "medium";

const modalWidthMap = {
  small: "400px",
  medium: "600px",
  large: "900px",
};

const APModal = ({
  isOpen,
  children,
  onClose,
  closeText = "Close",
  size = "medium",
  confirmButton,
  closeButton,
  hideClose,
}: {
  isOpen: boolean;
  children: ReactNode;
  onClose: (() => void) | undefined;
  closeText?: string;
  size?: ModalSize;
  confirmButton?: ReactNode;
  closeButton?: ReactNode;
  hideClose?: boolean;
}) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          outline: "none",
        }}
      >
        <Paper
          sx={{
            p: 2,
            px: { xs: 2, sm: 4 },
            overflowY: "auto",
            maxWidth: modalWidthMap[size],
            maxHeight: "95vh",
            width: "90vw",
          }}
        >
          {children}
          <Box
            sx={{
              mt: 3,
              display: "flex",
              justifyContent: confirmButton ? "space-between" : "end",
              alignItems: "flex-start",
            }}
          >
            {!hideClose && (
              <>
                {closeButton || (
                  <Button
                    size="large"
                    color="primary"
                    variant="outlined"
                    disableElevation={true}
                    onClick={onClose}
                  >
                    {closeText}
                  </Button>
                )}
              </>
            )}
            {confirmButton}
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export default APModal;
