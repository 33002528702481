import Alert, { AlertColor } from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import APAccordion from "./APAccordion";

const AutoPilotAlert = ({
  severity,
  message,
  title,
  asHTML,
  detailsExpandable,
}: {
  severity: AlertColor;
  message: string;
  title?: string;
  asHTML?: boolean;
  detailsExpandable?: boolean;
}) => {
  const details = (
    <Box sx={{ overflowWrap: "break-word" }}>
      {asHTML ? <div dangerouslySetInnerHTML={{ __html: message }} /> : message}
    </Box>
  );
  return (
    <Alert severity={severity} sx={{ pb: 2 }}>
      {title && (
        <Box sx={{ mb: 1 }}>
          <Typography variant="titleMedium">{title}</Typography>
        </Box>
      )}
      {detailsExpandable ? (
        <APAccordion title="Show Error Details" smallHeader>
          {details}
        </APAccordion>
      ) : (
        <>{details}</>
      )}
    </Alert>
  );
};

export default AutoPilotAlert;
