import Box from "@mui/material/Box";
import { ReactNode, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useApi } from "../../api/ApiProvider";
import { useAuth } from "../../api/AuthProvider";
import { getPublicConfig } from "../../api/config/getPublicConfig";
import { getDossier } from "../../api/dossier/getDossier";
import { setPrimaryQuote } from "../../api/dossier/setPrimaryQuote";
import { getFaqAppLocations } from "../../api/faq/getFaqAppLocations";
import { getFaqs } from "../../api/faq/getFaqs";
import { getHighlightedFaqViews } from "../../api/faq/getHighlightedFaqViews";
import { getProductTours } from "../../api/productTour/getProductTours";
import { getCalculatedQuote } from "../../api/quote/getCalculatedQuote";
import { getQuoteVehicleImages } from "../../api/quote/getQuoteVehicleImages";
import { PatchQuotePayload, updateQuote } from "../../api/quote/updateQuote";
import { setMyAIConsent } from "../../api/user/setMyAIConsent";
import AppLocation from "../../components/AppLocation";
import APProductTour from "../../components/APProductTour";
import PageContainer from "../../components/PageContainer";
import { isAIVehicleSuggestionEnabled } from "../../helpers/configuration";
import { buildQuoteFilename, downloadFile } from "../../helpers/download";
import { AppLocationRenderFunction } from "../../helpers/faq";
import { formatCalculatedQuote } from "../../helpers/format";
import { CRMAppLocationIdentifier } from "../../types/Faq";
import { QuotePayPeriod } from "../../types/Quote";
import { User } from "../../types/User";
import CalculatedQuote from "../components/CalculatedQuote";

export type ViewQuoteSubmitAction =
  | "edit"
  | "new"
  | "next"
  | "download"
  | "user"
  | "vehicle-suggestions";

const ViewQuote = ({ me }: { me: User }) => {
  const { quoteId } = useParams();
  const [carouselAppLocationId, setCarouselAppLocationId] = useState(
    null as null | string
  );
  const [isPollingFaqs, setIsPollingFaqs] = useState(true);
  const [payPeriodOverride, setPayPeriodOverride] = useState<
    QuotePayPeriod | undefined
  >(undefined);

  const navigate = useNavigate();
  const { fetchWithAuth } = useApi();
  const { getScopedPageUrl, getScopedPageConfig, inAppScope } = useAuth();
  const productTourContainerRef = useRef<HTMLDivElement>(null);

  if (!quoteId) {
    // TODO: improve client side error messaging
    throw new Error("quote id param is misisng");
  }

  const { mutateAsync, isLoading: isLoadingUpdate } = updateQuote(quoteId);

  const quote = getCalculatedQuote(quoteId);
  const quoteVehicleImages = getQuoteVehicleImages(quoteId);

  const dossierId = quote.data?.dossierId;
  const dossier = getDossier(dossierId || "", { disabled: !dossierId });

  const pageId = "quote-view";
  const faqAppLocations = getFaqAppLocations(
    { pageId, quoteId },
    {
      refetchInterval: isPollingFaqs ? 3000 : undefined,
    }
  );
  const highlightedFaqViews = getHighlightedFaqViews();
  const carouselFaqs = getFaqs(
    { appLocationId: carouselAppLocationId || "", quoteId },
    {
      disabled: !carouselAppLocationId,
    }
  );
  const productTours = getProductTours(pageId, {
    disabled: inAppScope("internal"),
  });
  const configMap = getPublicConfig();

  const {
    mutateAsync: mutatePrimaryToggleAsync,
    isLoading: isLoadingPrimaryToggle,
  } = setPrimaryQuote(dossierId || "", {
    mutationOptions: {
      successAlertMessage: "Your primary quote has been updated",
    },
  });

  const { mutateAsync: mutateSetAIConsentAsync } = setMyAIConsent();

  useEffect(() => {
    if (faqAppLocations.isSuccess) {
      const carouselApplocation = faqAppLocations.data.find(
        (location) =>
          location.appLocationIdentifier === "QuoteView-BelowLifeofLeaseSavings"
      );

      if (carouselApplocation) {
        setCarouselAppLocationId(carouselApplocation.id);
      }
    }
  }, [faqAppLocations.isSuccess]);

  // Stop polling faqs after 15 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsPollingFaqs(false);
    }, 15000); // 15 seconds

    return () => clearTimeout(timer);
  }, []);

  if (quote.isError) {
    throw quote.error;
  }

  if (dossier.isError) {
    throw dossier.error;
  }

  if (faqAppLocations.isError) {
    throw faqAppLocations.error;
  }

  if (highlightedFaqViews.isError) {
    throw highlightedFaqViews.error;
  }

  if (configMap.isError) {
    throw configMap.error;
  }

  const download = async () => {
    if (quote.isSuccess && dossier.isSuccess) {
      const filename = buildQuoteFilename(
        dossier.data.identity,
        quote.data.vehicle.make,
        quote.data.vehicle.model,
        `${quote.data.term}`
      );

      const pdf = await fetchWithAuth<Blob>("getQuotePDF", quoteId, {
        responseOptions: { responseType: "blob" },
      });

      downloadFile(pdf, filename);
    }
  };

  const onSubmit = async (
    name: string,
    payPeriod: QuotePayPeriod,
    submitAction: ViewQuoteSubmitAction
  ) => {
    if (quote.isSuccess && dossier.isSuccess) {
      const updatePayload: PatchQuotePayload = {};

      if (name !== quote.data.name) {
        updatePayload.name = name;
      }

      if (payPeriod !== quote.data.payPeriod) {
        updatePayload.payPeriod = payPeriod;
      }

      if (Object.keys(updatePayload).length > 0) {
        updatePayload.dossierId = dossierId;
        await mutateAsync(updatePayload);
      }

      switch (submitAction) {
        case "next":
          navigate(getScopedPageUrl("viewDossier", dossier.data.id));
          break;
        case "new":
          navigate(getScopedPageUrl("cloneQuote", quoteId));
          break;
        case "edit":
          navigate(getScopedPageUrl("editQuote", quoteId));
          break;
        case "download":
          download();
          break;
        case "user":
          navigate(
            getScopedPageUrl("viewKeycloakUser", dossier.data.crmContactId)
          );
          break;
        case "vehicle-suggestions":
          navigate(getScopedPageUrl("viewQuoteVehicleSuggestions", quoteId));
          break;
      }
    }
  };

  const onPayPeriodChange = (period: QuotePayPeriod) => {
    setPayPeriodOverride(period);
  };

  const onSetPrimary = (quoteId: string) => {
    if (dossier.isSuccess) {
      mutatePrimaryToggleAsync({ quoteId });
    }
  };

  const onSetAIConsent = () => {
    mutateSetAIConsentAsync();
  };

  const isLoading =
    quote.isFetching ||
    dossier.isFetching ||
    faqAppLocations.isLoading ||
    highlightedFaqViews.isLoading ||
    configMap.isFetching;
  const isSuccess =
    quote.isSuccess &&
    dossier.isSuccess &&
    faqAppLocations.isSuccess &&
    highlightedFaqViews.isSuccess &&
    configMap.isSuccess;

  // Can create a quote if there is less then max quotes and the can create flag is true
  const canCreateQuote =
    dossier.isSuccess &&
    (dossier.data.quoteCount || 0) <
      getScopedPageConfig<number>("dossierMaxQuoteCount") &&
    (dossier.data.opportunity?.canCreateQuote || false);

  const isVehicleSuggestionsEnabled =
    configMap.isSuccess && isAIVehicleSuggestionEnabled(configMap.data);

  const faqContextIds = {
    quoteId,
  };

  const renderAppLocation: AppLocationRenderFunction = (
    appLocationIdentifier: CRMAppLocationIdentifier,
    children: ReactNode,
    hideIfNoFaqs?: boolean,
    hideHighlight?: boolean
  ) => {
    return (
      <AppLocation
        appLocations={faqAppLocations.data || []}
        highlightedFaqViews={highlightedFaqViews.data || {}}
        appLocationIdentifier={appLocationIdentifier}
        hideIfNoFaqs={hideIfNoFaqs}
        hideHighlight={hideHighlight}
        contextIds={faqContextIds}
      >
        {children}
      </AppLocation>
    );
  };

  return (
    <PageContainer loading={isLoading}>
      {isSuccess && (
        <>
          <Box ref={productTourContainerRef}>
            <CalculatedQuote
              quote={formatCalculatedQuote(quote.data, payPeriodOverride)}
              quoteImages={
                quoteVehicleImages.isSuccess ? quoteVehicleImages.data : []
              }
              contactId={dossier.data.crmContactId}
              faqs={{
                renderAppLocation,
                faqAppLocations: faqAppLocations.data,
                highlightedFaqViews: highlightedFaqViews.data,
                carouselAppLocationId,
                carouselFaqs: carouselFaqs?.isSuccess ? carouselFaqs.data : [],
              }}
              flags={{
                isPrimary:
                  dossier.data.opportunity?.primaryQuoteId === quote.data.id,
                canCreateQuote,
                disablePrimaryToggle:
                  isLoadingPrimaryToggle || dossier.data.hasLockedQuote,
                hasAIConsented: me.aiConsent,
                vehicleSuggestionsEnabled: isVehicleSuggestionsEnabled,
                isSubmitting: isLoadingUpdate,
              }}
              handlers={{
                handleSubmit: onSubmit,
                handleSetPrimary: onSetPrimary,
                handleSetAIConsent: onSetAIConsent,
                handlePayPeriodChange: onPayPeriodChange,
              }}
            />
          </Box>
          {productTours.isSuccess && productTours.data[0] && (
            <APProductTour
              key={productTours.data[0].id}
              tour={productTours.data[0]}
              tourContainerRef={productTourContainerRef}
            />
          )}
        </>
      )}
    </PageContainer>
  );
};

export default ViewQuote;
