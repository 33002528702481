import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Control } from "react-hook-form";

import { getFile } from "../../api/file/getFile";
import InfoButton from "../../components/buttons/InfoButton";
import APCheckbox from "../../components/form/APCheckbox";

const VehicleOptionCard = ({
  id,
  catchEOptionId,
  label,
  price,
  imageUrl,
  control,
  showImage,
  hasHighlight,
  handleHighlightClick,
}: {
  id: string;
  catchEOptionId: string;
  label: string;
  price: string;
  control: Control;
  showImage: boolean;
  imageUrl?: string | null;
  hasHighlight?: boolean;
  handleHighlightClick?: (optionId: string) => void;
}) => {
  const renderedPrice = <Typography variant="bodySmall">{price}</Typography>;

  const imageSrc = getFile(imageUrl || "", true, { disabled: !imageUrl });

  let _imageSrc: string | null;

  if (imageSrc.data) {
    _imageSrc = imageSrc.data;
  } else if (imageSrc.isIdle) {
    _imageSrc = "/photo-placeholder.png";
  } else {
    _imageSrc = null;
  }

  return (
    <Box>
      {_imageSrc && (
        <Card
          sx={{
            display: "flex",
            backgroundColor: "surface.main",
          }}
          variant="outlined"
        >
          <Box sx={{ p: 1, py: imageUrl ? 1 : 2 }}>
            <APCheckbox
              name={`options.${catchEOptionId}`}
              defaultValue={false}
              control={control}
            />
          </Box>
          <CardActionArea
            sx={{ display: "flex", py: imageUrl ? 0 : 1 }}
            onClick={() => handleHighlightClick && handleHighlightClick(id)}
            disabled={!hasHighlight}
          >
            {showImage && _imageSrc && (
              <CardMedia
                component="img"
                sx={{ height: "7.5rem", width: "7.5rem" }}
                image={_imageSrc}
                alt={`${label}`}
              />
            )}
            <Box
              sx={{
                p: 1,
                pr: 2,
                height: _imageSrc ? "100%" : "initial",
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="titleMedium">{label}</Typography>
                {hasHighlight ? <InfoButton /> : renderedPrice}
              </Box>
              {hasHighlight && (
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  {renderedPrice}
                </Box>
              )}
            </Box>
          </CardActionArea>
        </Card>
      )}
    </Box>
  );
};

export default VehicleOptionCard;
