import AddIcon from "@mui/icons-material/Add";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import SortIcon from "@mui/icons-material/Sort";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ReactNode, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useApi } from "../../api/ApiProvider";
import { useAuth } from "../../api/AuthProvider";
import { getDossier } from "../../api/dossier/getDossier";
import { getDossierStatus } from "../../api/dossier/getDossierStatus";
import { getFinanceApplication } from "../../api/dossier/getFinanceApplication";
import { getQuotes } from "../../api/dossier/getQuotes";
import { setPrimaryQuote } from "../../api/dossier/setPrimaryQuote";
import { getFaqAppLocations } from "../../api/faq/getFaqAppLocations";
import { getHighlightedFaqViews } from "../../api/faq/getHighlightedFaqViews";
import { getProductTours } from "../../api/productTour/getProductTours";
import { setQuoteVisibility } from "../../api/quote/setQuoteVisibility";
import AutoPilotAlert from "../../components/Alert";
import APAccordion from "../../components/APAccordion";
import APModal from "../../components/APModal";
import AppLocation from "../../components/AppLocation";
import APProductTour from "../../components/APProductTour";
import APSelectDropDown, {
  SelectDropdownOption,
} from "../../components/APSelectDropDown";
import APIconButton from "../../components/buttons/APIconButton";
import BackButton from "../../components/buttons/BackButton";
import CatchEButton from "../../components/buttons/CatchEButton";
import RefreshButton from "../../components/buttons/RefreshButton";
import SyncQuoteButton from "../../components/buttons/SyncQuoteButton";
import PageContainer from "../../components/PageContainer";
import RestrictedAppScope from "../../components/RestrictedAppScope";
import TermsButton from "../../components/TermsButton";
import { buildQuoteFilename, downloadFile } from "../../helpers/download";
import { AppLocationRenderFunction } from "../../helpers/faq";
import {
  filterQuotes,
  QuoteSortOption,
  sortQuotes,
} from "../../helpers/quotes";
import { DEFAULT_ACCOUNT } from "../../types/Contact";
import { buildNextActionRoute, NextDriverAction } from "../../types/Dossier";
import { CRMAppLocationIdentifier } from "../../types/Faq";
import { Quote } from "../../types/Quote";
import DossierStatusStepper from "../components/DossierStatusStepper";
import QuoteCard from "../components/QuoteCard";

const quoteSortOptions: SelectDropdownOption<QuoteSortOption>[] = [
  { id: "taxSavings", label: "Tax Benefit %" },
  { id: "netWeeklyCost", label: "Net Weekly Cost" },
  { id: "lastUpdated", label: "Last Updated" },
];

const ViewDossier = () => {
  const { dossierId } = useParams();
  const navigate = useNavigate();
  const [navigationCheckComplete, setNavigationCheck] = useState(false);
  const [showNextActionModal, setShowNextActionModal] = useState(false);
  const [showSortOptions, setShowSortOptions] = useState(false);
  const [quoteSortOptionId, setQuoteSortOptionId] =
    useState<QuoteSortOption>("lastUpdated");
  const { fetchWithAuth } = useApi();
  const { getScopedPageUrl, getScopedPageConfig, inAppScope } = useAuth();
  const productTourContainerRef = useRef<HTMLDivElement>(null);

  if (!dossierId) {
    // TODO: improve client side error messaging
    throw new Error("dossier id param is misisng");
  }

  const { mutateAsync, isLoading: isLoadingUpdate } = setPrimaryQuote(
    dossierId,
    {
      mutationOptions: {
        successAlertMessage: "Your primary quote has been updated",
      },
    }
  );

  const { mutateAsync: mutateQuoteVisibilityAsync } = setQuoteVisibility({
    mutationOptions: {
      successAlertMessage: "Quote visibility has been updated",
    },
  });

  const dossier = getDossier(dossierId);
  const dossierStatus = getDossierStatus(dossierId);

  const quotes = getQuotes(dossierId);

  const financeApplication = getFinanceApplication(dossierId, {
    appTypeScope: "external",
  });

  const pageId = "dossier-view";
  const faqAppLocations = getFaqAppLocations({ pageId });
  const highlightedFaqViews = getHighlightedFaqViews();
  const productTours = getProductTours(pageId, {
    disabled: inAppScope("internal"),
  });

  if (dossier.isError) {
    throw dossier.error;
  }

  if (dossierStatus.isError) {
    throw dossierStatus.error;
  }

  if (quotes.isError) {
    throw quotes.error;
  }

  if (financeApplication.isError) {
    throw financeApplication.error;
  }

  if (faqAppLocations.isError) {
    throw faqAppLocations.error;
  }

  if (highlightedFaqViews.isError) {
    throw highlightedFaqViews.error;
  }

  const isLoading =
    dossier.isFetching ||
    dossierStatus.isFetching ||
    quotes.isFetching ||
    financeApplication.isFetching ||
    faqAppLocations.isFetching ||
    highlightedFaqViews.isLoading;
  const isSuccess =
    dossier.isSuccess &&
    dossierStatus.isSuccess &&
    quotes.isSuccess &&
    faqAppLocations.isSuccess &&
    highlightedFaqViews.isSuccess &&
    (financeApplication.isSuccess || financeApplication.isIdle);
  const primaryQuoteId = dossier.data?.opportunity?.primaryQuoteId ?? null;

  useEffect(() => {
    // If there are no quotes then navigate to create quote page
    if (isSuccess) {
      if (dossier.data.quoteCount === 0) {
        navigate(
          `${getScopedPageUrl("createQuote", dossierId)}?limitedFields=true`,
          {
            replace: true,
          }
        );
      } else {
        setNavigationCheck(true);
      }
    }
  }, [isSuccess]);

  const onDownload = async (quoteId: string) => {
    if (quotes.isSuccess && dossier.isSuccess) {
      const quote = quotes.data.find((quote) => quote.crmQuoteId === quoteId);
      const filename = quote
        ? buildQuoteFilename(
            dossier.data.identity,
            quote.vehicleMake,
            quote.vehicleModel,
            `${quote.termMonths}`
          )
        : `${dossier.data.identity}.pdf`;
      const pdf = await fetchWithAuth<Blob>("getQuotePDF", quoteId, {
        responseOptions: { responseType: "blob" },
      });

      downloadFile(pdf, filename);
    }
  };

  const onClone = async (quoteId: string) => {
    navigate(getScopedPageUrl("cloneQuote", quoteId));
  };

  const onEdit = async (quoteId: string) => {
    navigate(getScopedPageUrl("editQuote", quoteId));
  };

  const onSetPrimary = async (quoteId: string) => {
    mutateAsync({ quoteId });
  };

  const onSetVisibility = async (quoteId: string, isVisible: boolean) => {
    mutateQuoteVisibilityAsync({ dossierId, quoteId, visibility: isVisible });
  };

  const onNext = async (nextAction: NextDriverAction) => {
    const route = buildNextActionRoute(dossierId, nextAction);
    navigate(route);
  };

  const onUser = async () => {
    if (dossier.data) {
      navigate(getScopedPageUrl("viewKeycloakUser", dossier.data.crmContactId));
    }
  };

  const onNew = async () => {
    if (quotes.isSuccess && dossier.isSuccess) {
      const quoteId = primaryQuoteId || quotes.data[0].crmQuoteId;

      navigate(getScopedPageUrl("cloneQuote", quoteId));
    }
  };

  const onFinanceApplication = async () => {
    navigate(getScopedPageUrl("dossierFinanceApplication", dossierId));
  };

  const onStatusItemClick = () => {
    setShowNextActionModal(true);
  };

  const onCloseNextActionModal = () => {
    setShowNextActionModal(false);
  };

  const onToggleSortOptions = () => {
    setShowSortOptions(!showSortOptions);
  };

  const onQuoteSortChange = (optionId: QuoteSortOption) => {
    setQuoteSortOptionId(optionId);
  };

  const hasLockedQuote =
    quotes.isSuccess && quotes.data.some((quote) => quote.isLocked);
  const canCreateQuote =
    dossier.isSuccess && !!dossier.data.opportunity?.canCreateQuote;
  const filteredQuotes = quotes.isSuccess
    ? filterQuotes(quotes.data, inAppScope("internal"))
    : [];

  // Render Functions
  const renderAppLocation: AppLocationRenderFunction = (
    appLocationIdentifier: CRMAppLocationIdentifier,
    children: ReactNode,
    hideIfNoFaqs?: boolean,
    hideHighlight?: boolean
  ) => {
    return (
      <AppLocation
        appLocations={faqAppLocations.data || []}
        highlightedFaqViews={highlightedFaqViews.data || {}}
        appLocationIdentifier={appLocationIdentifier}
        hideIfNoFaqs={hideIfNoFaqs}
        hideHighlight={hideHighlight}
      >
        {children}
      </AppLocation>
    );
  };

  const renderQuoteCard = (quote: Quote) => {
    return (
      <QuoteCard
        id={quote.crmQuoteId}
        key={quote.crmQuoteId}
        name={quote.name}
        isLocked={quote.isLocked}
        isPrimary={quote.crmQuoteId === primaryQuoteId}
        isVisible={quote.isVisible}
        vehicleMake={quote.vehicleMake}
        vehicleModel={quote.vehicleModel}
        vehicleYear={quote.vehicleYear}
        vehicleImage={quote.vehicleImage}
        vehicleOnroadCosts={quote.vehicleOnroadCostsFormatted}
        annualKm={quote.budgetedAnnualKmFormatted}
        purchaseType={quote.purchaseType}
        term={quote.termMonths}
        startDate={quote.startDate}
        endDate={quote.endDate}
        savings={quote.savings}
        weeklyNetCosts={quote.weeklyNetCostsFormatted}
        catchEQuoteUrl={quote.catchEQuoteUrl}
        handleDownload={onDownload}
        handleClone={onClone}
        handleEdit={onEdit}
        handleNext={() =>
          dossierStatus.isSuccess && onNext(dossierStatus.data.nextDriverAction)
        }
        handleSetPrimary={onSetPrimary}
        handleSetVisibility={onSetVisibility}
        disablePrimaryToggle={isLoadingUpdate || hasLockedQuote}
        hideClone={
          (quotes.isSuccess &&
            filteredQuotes.length >=
              getScopedPageConfig<number>("dossierMaxQuoteCount")) ||
          !canCreateQuote
        }
      />
    );
  };

  const sortDropdown = (
    <Box sx={{ my: 1, display: "flex", justifyContent: "end" }}>
      <Box sx={{ width: "168px", display: "flex", justifyContent: "end" }}>
        <APSelectDropDown
          label="Sort By"
          selectedOptionId={quoteSortOptionId}
          options={quoteSortOptions}
          onOptionChange={onQuoteSortChange}
        />
      </Box>
    </Box>
  );

  return (
    <PageContainer loading={isLoading || !navigationCheckComplete}>
      <>
        {isSuccess && navigationCheckComplete && (
          <Stack spacing={2} ref={productTourContainerRef}>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  columnGap: 1,
                }}
              >
                <Box>
                  <Typography variant="titleSmall" color="onSurface.main">
                    {dossier.data.identity} - {dossier.data.crmContactName}
                  </Typography>
                  {dossier.data.opportunity &&
                    dossier.data.opportunity.parentAccountId !==
                      DEFAULT_ACCOUNT && (
                      <Typography variant="titleSmall" color="onSurface.main">
                        {dossier.data.opportunity.parentAccountName}
                      </Typography>
                    )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    columnGap: 1,
                    alignItems: "center",
                    flexDirection: {
                      xs: inAppScope("internal") ? "column-reverse" : "row",
                      sm: "row",
                    },
                    rowGap: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      columnGap: 1,
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <RestrictedAppScope scope="internal">
                        <SyncQuoteButton
                          contactId={dossier.data.crmContactId}
                        />
                      </RestrictedAppScope>
                    </Box>
                    {renderAppLocation(
                      "QuoteList-SortOrder",
                      <Box>
                        <APIconButton
                          icon={<SortIcon />}
                          onClick={onToggleSortOptions}
                          sx={{
                            display: {
                              xs: "initial",
                              sm: "none",
                            },
                          }}
                        />
                        <Box
                          sx={{
                            display: { xs: "none", sm: "initial" },
                          }}
                        >
                          {sortDropdown}
                        </Box>
                      </Box>
                    )}
                    <RefreshButton />
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: {
                    xs: "initial",
                    sm: "none",
                  },
                }}
              >
                <Collapse in={showSortOptions}>{sortDropdown}</Collapse>
              </Box>
            </Box>

            <Stack
              sx={{
                display: "grid",
                gap: "16px",
                gridTemplateColumns: { md: "1fr 1fr" },
              }}
            >
              {sortQuotes(
                filteredQuotes,
                quoteSortOptionId,
                primaryQuoteId
              ).map(renderQuoteCard)}
            </Stack>
            <TermsButton termsId="quote" />
            {canCreateQuote && dossier.data.status !== "Active" && (
              <Box>
                {filteredQuotes.length <
                getScopedPageConfig<number>("dossierMaxQuoteCount") ? (
                  <Button
                    size="large"
                    color="primary"
                    variant="contained"
                    disableElevation={true}
                    startIcon={<AddIcon />}
                    onClick={() => onNew()}
                  >
                    New
                  </Button>
                ) : (
                  <Typography variant="titleMedium">
                    *Max quotes reached, contact Auto-UX if you require more.
                  </Typography>
                )}
              </Box>
            )}
            <Box>
              <APAccordion title="What is Next?" defaultExpanded>
                <Box sx={{ mt: 1, ml: 1 }}>
                  <DossierStatusStepper
                    dossierStatus={dossierStatus.data}
                    onItemClick={
                      dossier.data.noteToDriver ? onStatusItemClick : undefined
                    }
                    appLocations={{
                      renderAppLocation,
                      firstTodoIdentifier: "QuoteList-StatusCurrentStage",
                    }}
                  ></DossierStatusStepper>
                </Box>
              </APAccordion>
              <APModal
                isOpen={showNextActionModal}
                onClose={onCloseNextActionModal}
                confirmButton={
                  <Button
                    size="large"
                    color="secondary"
                    variant="contained"
                    disableElevation={true}
                    startIcon={<PlayArrowOutlinedIcon />}
                    onClick={() => onNext(dossierStatus.data.nextDriverAction)}
                  >
                    Next
                  </Button>
                }
              >
                {dossier.data.noteToDriver && (
                  <Box>
                    <AutoPilotAlert
                      severity="info"
                      message={dossier.data.noteToDriver}
                      asHTML={true}
                    />
                  </Box>
                )}
              </APModal>
            </Box>
            {dossier.data.noteToDriver && (
              <Box>
                <AutoPilotAlert
                  severity="info"
                  message={dossier.data.noteToDriver}
                  asHTML={true}
                />
              </Box>
            )}
            <RestrictedAppScope scope="external">
              <Box
                sx={{
                  pt: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                <Stack
                  spacing={2}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <BackButton />
                  {financeApplication.data &&
                    !financeApplication.data.isHidden && (
                      <Button
                        size="large"
                        color="primary"
                        variant="contained"
                        disableElevation={true}
                        onClick={() => onFinanceApplication()}
                      >
                        Finance App
                      </Button>
                    )}
                </Stack>
                <Button
                  size="large"
                  color="secondary"
                  variant="contained"
                  disableElevation={true}
                  startIcon={<PlayArrowOutlinedIcon />}
                  onClick={() => onNext(dossierStatus.data.nextDriverAction)}
                >
                  Next
                </Button>
              </Box>
            </RestrictedAppScope>
            <RestrictedAppScope scope="internal">
              <Stack spacing={2}>
                <Box
                  sx={{ display: "flex", columnGap: 1, alignItems: "center" }}
                >
                  <BackButton />
                  {dossier.data.opportunity?.catchEContactUrl && (
                    <CatchEButton
                      url={dossier.data.opportunity.catchEContactUrl}
                      sx={{ p: { sm: 1.5, xs: 1 } }}
                    />
                  )}
                </Box>
                <Box>
                  <Button
                    size="large"
                    color="primary"
                    variant="contained"
                    disableElevation={true}
                    onClick={onUser}
                  >
                    User
                  </Button>
                </Box>
              </Stack>
            </RestrictedAppScope>
          </Stack>
        )}
        {productTours.isSuccess && productTours.data[0] && (
          <APProductTour
            key={productTours.data[0].id}
            tour={productTours.data[0]}
            tourContainerRef={productTourContainerRef}
          />
        )}
      </>
    </PageContainer>
  );
};

export default ViewDossier;
