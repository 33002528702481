import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useApi } from "../../api/ApiProvider";
import { useAuth } from "../../api/AuthProvider";
import { getMyDossiersForEmployerApproval } from "../../api/dossier/getMyDossiersForEmployerApproval";
import {
  approveDriver,
  ApproveDriverPayload,
} from "../../api/user/approveDriver";
import { getMyEmployerPayrollCycles } from "../../api/user/getMyEmployerPayrollCycles";
import { rejectDriver } from "../../api/user/rejectDriver";
import APModal from "../../components/APModal";
import BackButton from "../../components/buttons/BackButton";
import PageContainer from "../../components/PageContainer";
import {
  buildPackagingAgreementFilename,
  downloadFile,
} from "../../helpers/download";
import { filterAuthorisedRolesForPage } from "../../helpers/user";
import { Dossier } from "../../types/Dossier";
import { User } from "../../types/User";
import DriverApprovalCard, {
  DriverInputs,
} from "../components/DriverApprovalCard";

const ApproveDrivers = ({ me }: { me: User }) => {
  const navigate = useNavigate();
  const [navigationCheckComplete, setNavigationCheck] = useState(false);
  const [dossierToReject, setDossierToRejectModal] = useState(
    null as string | null
  );
  const { fetchWithAuth } = useApi();
  const { getScopedPageUrl } = useAuth();

  const authorisedSignatoryRoles = me.contactRoles
    ? filterAuthorisedRolesForPage(me.contactRoles, "employerApproveDrivers")
    : [];

  const dossiersToApprove = getMyDossiersForEmployerApproval();
  const employerPayrollCycles = getMyEmployerPayrollCycles();

  if (dossiersToApprove.isError) {
    throw dossiersToApprove.error;
  }

  if (employerPayrollCycles.isError) {
    throw employerPayrollCycles.error;
  }

  const { mutateAsync: mutateApprove, isLoading: isApproving } = approveDriver({
    mutationOptions: {
      successAlertMessage: "You have approved this employee's novated lease",
    },
  });

  const { mutateAsync: mutateReject, isLoading: isRejecting } = rejectDriver({
    mutationOptions: {
      successAlertMessage: "You have rejected this employee's novated lease",
    },
  });

  const isLoading =
    dossiersToApprove.isLoading || employerPayrollCycles.isLoading;
  const isSuccess =
    dossiersToApprove.isSuccess && employerPayrollCycles.isSuccess;
  const isUpdating = isApproving || isRejecting;

  useEffect(() => {
    if (isSuccess) {
      if (authorisedSignatoryRoles.length > 0) {
        setNavigationCheck(true);
      } else {
        navigate(getScopedPageUrl("employerHome"), { replace: true });
      }
    }
  }, [isSuccess]);

  const onDriverApprove = async (inputs: DriverInputs, dossierId: string) => {
    if (dossiersToApprove.data) {
      const dossier = dossiersToApprove.data.find(
        (dossier) => dossier.id === dossierId
      );

      if (dossier) {
        const contact = dossier.contact;
        const { employer, payrollCycle, employeeId } = inputs;

        const approvePayload: ApproveDriverPayload = {
          dossierId,
          employerId: employer?.id ?? undefined,
          employerDevice: navigator.userAgent,
        };

        if (contact) {
          if (
            payrollCycle &&
            contact.employerPayrollCycleId !== payrollCycle.id
          ) {
            approvePayload.payrollCycleId = payrollCycle.id;
          }

          if (`${contact.employeeid}` !== (employeeId || "")) {
            approvePayload.employeeId = employeeId;
          }
        }

        await mutateApprove(approvePayload);
      }
    }
  };

  const onRejectApprove = async (dossierId: string) => {
    setDossierToRejectModal(null);
    await mutateReject(dossierId);
  };

  const onShowConfirmationModal = (dossierId: string) => {
    setDossierToRejectModal(dossierId);
  };

  const onCloseConfirmationModal = () => {
    setDossierToRejectModal(null);
  };

  const downloadPaPdf = async (dossierId: string, dossierIdentity: string) => {
    const filename = buildPackagingAgreementFilename(dossierIdentity);

    try {
      const pdf = await fetchWithAuth<Blob>(
        "getEmployeePackagingAgreementPDF",
        dossierId,
        { responseOptions: { responseType: "blob" } }
      );

      downloadFile(pdf, filename);
    } catch (e) {
      enqueueSnackbar(`There was an issue downloading the file: ${e}`, {
        variant: "error",
        action: (key) => (
          <IconButton onClick={() => closeSnackbar(key)} color="inherit">
            <CloseIcon />
          </IconButton>
        ),
      });
    }
  };

  const renderDriverCard = (dossier: Dossier, index: number) => {
    return (
      <Box key={index}>
        <DriverApprovalCard
          id={index}
          dossierId={dossier.id}
          dossierIdentity={dossier.identity}
          driverContact={dossier.contact || null}
          vehicleYear={dossier.vehicleModelyear}
          vehicleModel={dossier.vehicleModelName}
          vehicleMake={dossier.vehicleMakeName}
          roles={authorisedSignatoryRoles}
          employerPayrollCycles={employerPayrollCycles.data || []}
          onApprove={onDriverApprove}
          onReject={onShowConfirmationModal}
          onDownloadPaPdf={downloadPaPdf}
        />
      </Box>
    );
  };

  return (
    <PageContainer
      title="Approve Drivers"
      loading={isLoading || isUpdating || !navigationCheckComplete}
    >
      <APModal
        isOpen={!!dossierToReject}
        onClose={onCloseConfirmationModal}
        confirmButton={
          <Button
            size="large"
            color="secondary"
            variant="contained"
            sx={{ mr: 1, color: "onPrimary.main" }}
            onClick={() => dossierToReject && onRejectApprove(dossierToReject)}
          >
            Confirm
          </Button>
        }
        size="small"
      >
        <Box>
          <Typography variant="titleMedium">
            Are you sure you want to reject this employee’s novated vehicle
          </Typography>
        </Box>
      </APModal>
      {isSuccess && !isUpdating && navigationCheckComplete && (
        <Stack spacing={2}>
          <Typography variant="labelLarge">
            {dossiersToApprove.data.length > 0
              ? "The following employees are awaiting approval for a novated lease. Please approve or reject. *Note that by clicking ‘approve’, you are digitally executing the Packaging Agreement for this employee on behalf of the employer. You can view the PA below and will be emailed a copy once executed by all parties."
              : "There are no employees waiting for approval."}
          </Typography>
          {dossiersToApprove.data.map(renderDriverCard)}
          <Box>
            <BackButton />
          </Box>
        </Stack>
      )}
    </PageContainer>
  );
};

export default ApproveDrivers;
