import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";

export type ProgressBarColorOption = "primary" | "error" | "tertiaryGreen";

const APProgressBar = ({
  title,
  value,
  maxValue,
  colorSpectrum,
}: {
  title: string;
  value: number;
  maxValue: number;
  colorSpectrum: ProgressBarColorOption[];
}) => {
  const progressValue = (value / maxValue) * 100;
  const colorIndex = Math.max(
    Math.floor(colorSpectrum.length * (progressValue / 100)) - 1,
    0
  );

  return (
    <Box>
      <Typography variant="labelSmall">{title}</Typography>
      <LinearProgress
        value={progressValue}
        color={colorSpectrum[colorIndex]}
        variant="determinate"
      />
    </Box>
  );
};

export default APProgressBar;
