import { AutoCompleteOption } from "../components/form/APAutoComplete";
import { ToggleButtonOption } from "../components/form/APFormToggleGroup";

export function buildAutoCompleteOptions(
  items: Record<string, unknown>[],
  labelKey: string,
  idKey: string
): AutoCompleteOption[] {
  const options: AutoCompleteOption[] = [];
  const optionIds: string[] = [];
  items.forEach((item) => {
    const id = `${item[idKey]}`;

    if (!optionIds.includes(id)) {
      options.push({ label: `${item[labelKey]}`, id });
      optionIds.push(id);
    }
  });

  return options;
}

export function getAutoCompleteLabel(
  options: AutoCompleteOption[] | ToggleButtonOption[],
  optionId: string
) {
  const option = options.find((option) => option.id === optionId);

  return option ? option.label : "";
}
