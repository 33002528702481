import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { createFaqVote } from "../../api/faq/createFaqVote";
import { getFaqVote } from "../../api/faq/getFaqVote";
import APImageWithLoader from "../../components/APImageWithLoader";
import { compileTemplate } from "../../helpers/faq";
import { APFaqVoteType, Faq } from "../../types/Faq";
import { CalculatedQuoteFormatted } from "../../types/Quote";

const FaqDetails = ({
  faq,
  appLocationId,
  quote,
}: {
  faq: Faq;
  appLocationId: string | null;
  quote?: CalculatedQuoteFormatted;
}) => {
  const faqVote = getFaqVote(faq.id, appLocationId);

  const { mutateAsync, isLoading: isUpdating } = createFaqVote(faq.id);

  const handleVote = (voteType: APFaqVoteType | null) => {
    if (!isUpdating) {
      mutateAsync({
        vote: voteType,
        appLocationId,
        faqAppLocationId: faq.faqApplocationId,
      });
    }
  };

  const isUseful = faqVote.data?.vote === "useful";
  const isNotUseful = faqVote.data?.vote === "not-useful";

  return (
    <Stack spacing={2} sx={{ minWidth: "280px" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="titleLarge">{faq.question}</Typography>
      </Box>
      <Box>
        <Typography variant="labelLarge">
          <div
            dangerouslySetInnerHTML={{
              __html: quote
                ? compileTemplate(faq.answer, quote, "quote")
                : faq.answer,
            }}
            className="faq-detail-container"
          />
        </Typography>
      </Box>
      <Box>
        <Box
          sx={{ display: "flex", alignItems: "center", justifyContent: "end" }}
        >
          <Typography variant="labelLarge" sx={{ mr: 1 }}>
            Useful?
          </Typography>
          <IconButton
            sx={{ color: "tertiaryGreen.main" }}
            onClick={() => handleVote(isUseful ? null : "useful")}
          >
            {isUseful ? <ThumbUpIcon /> : <ThumbUpOutlinedIcon />}
          </IconButton>

          <IconButton
            color="error"
            onClick={() => handleVote(isNotUseful ? null : "not-useful")}
          >
            {isNotUseful ? <ThumbDownIcon /> : <ThumbDownOutlinedIcon />}
          </IconButton>
        </Box>
      </Box>
      {faq.imageUrl && (
        <Box>
          <APImageWithLoader
            imageUrl={faq.imageUrl}
            width="100%"
            height="400px"
            minHeight="300px"
          />
        </Box>
      )}

      {faq.answerBelowImage && (
        <Box>
          <Typography variant="labelLarge">
            <div
              dangerouslySetInnerHTML={{
                __html: quote
                  ? compileTemplate(faq.answerBelowImage, quote, "quote")
                  : faq.answerBelowImage,
              }}
              className="faq-detail-container"
            />
          </Typography>
        </Box>
      )}
    </Stack>
  );
};

export default FaqDetails;
