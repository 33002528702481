import { useEffect } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { useApi } from "../../api/ApiProvider";
import { useAuth } from "../../api/AuthProvider";
import { getCalculatedQuote } from "../../api/quote/getCalculatedQuote";
import { getQuoteVehicleImages } from "../../api/quote/getQuoteVehicleImages";
import { updateQuote } from "../../api/quote/updateQuote";
import { getVehicleOptions } from "../../api/vehicle/getVehicleOptions";
import PageContainer from "../../components/PageContainer";
import { downloadFileFromUrl } from "../../helpers/download";
import { formatCalculatedQuote } from "../../helpers/format";
import QuotePersonaliser from "../components/QuotePersonaliser";

const PersonaliseQuote = () => {
  const { quoteId } = useParams();
  const navigate = useNavigate();
  const { control, handleSubmit, setValue } = useForm();
  const { fetchWithAuth } = useApi();
  const { getScopedPageUrl } = useAuth();

  if (!quoteId) {
    // TODO: improve client side error messaging
    throw new Error("quote id param is misisng");
  }

  const quote = getCalculatedQuote(quoteId);
  const quoteVehicleImages = getQuoteVehicleImages(quoteId);

  const { mutateAsync, isLoading: isSubmitting } = updateQuote(quoteId);

  const variantId = quote.data?.vehicle.variantId;
  const vehicleOptions = getVehicleOptions(variantId || "", {
    disabled: !variantId,
  });

  if (quote.isError) {
    throw quote.error;
  }

  if (vehicleOptions.isError) {
    throw vehicleOptions.error;
  }

  useEffect(() => {
    if (quote?.isSuccess) {
      quote.data.catchEEquipmentIds.forEach((id) => {
        setValue(`options.${id}`, true);
      });
    }
  }, [quote.isSuccess]);

  const isLoading = vehicleOptions.isFetching || quote.isFetching;
  const isSuccess = vehicleOptions.isSuccess && quote.isSuccess;

  const onSubmit: SubmitHandler<FieldValues> = async ({ options }) => {
    if (quote.isSuccess) {
      const optionalEquipment: string[] = [];
      const oldEquipment = quote.data?.catchEEquipmentIds;

      if (options) {
        Object.keys(options).forEach((optionId) => {
          if (options[optionId]) {
            optionalEquipment.push(optionId);
          }
        });
      }

      const hasChanged =
        optionalEquipment.length !== oldEquipment.length ||
        !optionalEquipment.every(
          (equipmentId) => oldEquipment.indexOf(equipmentId) !== 1
        );

      if (hasChanged) {
        await mutateAsync({ optionalEquipment });
      }

      navigate(getScopedPageUrl("viewQuote", quoteId));
    }
  };

  const onEdit = async (values: FieldValues) => {
    await onSubmit(values);
    navigate(getScopedPageUrl("editQuote", quoteId));
  };

  const onOptionDownload = async (url: string, filename: string) => {
    const pdfSrc = await fetchWithAuth<string>("getFile", url, {
      responseOptions: { responseType: "fileUrl" },
    });
    downloadFileFromUrl(pdfSrc, filename);
  };

  // TODO: sort the vehicle images
  return (
    <PageContainer title="Vehicle Personalisation" loading={isLoading}>
      {isSuccess && (
        <QuotePersonaliser
          quote={formatCalculatedQuote(quote.data)}
          quoteImages={
            quoteVehicleImages.isSuccess ? quoteVehicleImages.data : []
          }
          vehicleOptions={vehicleOptions.data}
          control={control}
          handlers={{
            handleEdit: handleSubmit(onEdit),
            handleOptionDownload: onOptionDownload,
            handleSubmit: handleSubmit(onSubmit),
          }}
          flags={{
            isSubmitting,
          }}
        />
      )}
    </PageContainer>
  );
};

export default PersonaliseQuote;
