import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {
  Location,
  Outlet,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import {
  getParamFromQueryString,
  isAbsoluteUrl,
  removeModalParams,
  replaceModalParam,
} from "../helpers/routes";
import APModal, { ModalSize } from "./APModal";

export const APModalStack = ({
  modalStack,
  appLocation,
  size,
  hideClose,
  disableBackdropClose,
}: {
  modalStack: string[];
  appLocation: Location<unknown>;
  size: ModalSize;
  hideClose?: boolean;
  disableBackdropClose?: boolean;
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isSingleModal = modalStack.length === 1;

  const onClose = () => {
    navigate(
      `${appLocation.pathname}?${removeModalParams(searchParams).toString()}`,
      { replace: true }
    );
  };

  const onBack = () => {
    if (isSingleModal) {
      onClose();
    } else {
      const previousModal = modalStack[modalStack.length - 2];

      navigate(
        `${appLocation.pathname}?${replaceModalParam(
          previousModal,
          searchParams
        ).toString()}`,
        { replace: true, state: { popModalStack: true } }
      );
    }
  };

  return (
    <APModal
      isOpen={true}
      onClose={disableBackdropClose ? undefined : onClose}
      closeButton={
        <Button
          color="primary"
          variant="outlined"
          disableElevation={true}
          onClick={onBack}
        >
          {isSingleModal ? "Close" : "Back"}
        </Button>
      }
      hideClose={hideClose}
      size={size}
    >
      <Box
        onClick={(e) => {
          let target = e.target as HTMLElement;

          // Traverse up the DOM tree to find the <a> tag if a nested element is clicked
          while (target && target !== e.currentTarget) {
            if (target.tagName && target.tagName.toLowerCase() === "a") {
              const href = target?.getAttribute("href");

              if (href && !isAbsoluteUrl(href)) {
                e.preventDefault(); // Prevent default behavior (page reload)

                const isModalLink = href.includes("modal=");
                const modalParam = isModalLink
                  ? getParamFromQueryString(href, "modal")
                  : null;

                navigate(
                  modalParam
                    ? `${appLocation.pathname}?${replaceModalParam(
                        modalParam,
                        searchParams
                      ).toString()}`
                    : href,
                  {
                    replace: isModalLink,
                  }
                );
                return;
              }
            }
            target = target.parentElement as HTMLElement;
          }
        }}
      >
        <Outlet />
      </Box>
    </APModal>
  );
};

export default APModalStack;
