import Button from "@mui/material/Button";

const APPrimaryButton = ({
  text,
  onClick,
  variant = "outlined",
}: {
  text: string;
  onClick: () => void;
  variant?: "outlined" | "contained";
}) => {
  return (
    <Button
      size="large"
      color="primary"
      variant={variant}
      disableElevation={true}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

export default APPrimaryButton;
