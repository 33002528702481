import Box from "@mui/material/Box";
import Slide from "@mui/material/Slide";
import Tooltip from "@mui/material/Tooltip";
import { TransitionProps } from "@mui/material/transitions";
import Typography from "@mui/material/Typography";
import { ReactNode } from "react";

import { HIGHLIGHTED_TOOLTIP_ZINDEX } from "../theme";
import InfoButton from "./buttons/InfoButton";

interface APHighlightTooltipProps {
  text: string;
  children: ReactNode;
  isOpen?: boolean;
  onClick?: () => void;
}

const APHighlightTooltip = ({
  text,
  children,
  isOpen,
  onClick,
}: APHighlightTooltipProps) => {
  return (
    <Tooltip
      title={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: 1,
            cursor: "pointer",
          }}
          onClick={onClick}
        >
          <InfoButton iconColor="primary" />
          <Typography variant="bodySmall">{text}</Typography>
        </Box>
      }
      TransitionComponent={Slide}
      TransitionProps={
        { timeout: 500, direction: "up" } as unknown as TransitionProps
      }
      open={isOpen}
      arrow
      placement="top-start"
      PopperProps={{
        modifiers: [{ name: "offset", options: { offset: [0, -8] } }],
      }}
      slotProps={{
        tooltip: {
          sx: {
            backgroundColor: "white",
            border: "2px solid",
            borderColor: "tertiaryGreen.main",
            color: "black",
          },
        },
        arrow: { sx: { color: "tertiaryGreen.main" } },
        popper: {
          sx: { zIndex: HIGHLIGHTED_TOOLTIP_ZINDEX, overflow: "hidden" },
        },
      }}
    >
      <Box>{children}</Box>
    </Tooltip>
  );
};

export default APHighlightTooltip;
