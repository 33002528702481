import CheckIcon from "@mui/icons-material/Check";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";

const APSwitch = ({
  label,
  checked,
  onChange,
}: {
  label: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
}) => {
  return (
    <FormControlLabel
      sx={{ m: 0 }}
      control={
        <Switch
          color="primary"
          sx={{ m: 1 }}
          checked={checked}
          size="medium"
          checkedIcon={
            <Box
              sx={{
                width: "20px",
                height: "20px",
                borderRadius: "100%",
                backgroundColor: "primary.main",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CheckIcon sx={{ fontSize: "1rem" }} color="neutralWhite" />
            </Box>
          }
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            onChange(event.target.checked)
          }
        />
      }
      label={<Typography variant="titleMedium">{label}</Typography>}
      labelPlacement="start"
    />
  );
};

export default APSwitch;
