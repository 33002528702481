import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { ReactNode, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { useAuth } from "../../api/AuthProvider";
import { getDossier } from "../../api/dossier/getDossier";
import { getQuotes } from "../../api/dossier/getQuotes";
import { getFaqAppLocations } from "../../api/faq/getFaqAppLocations";
import { getHighlightedFaqViews } from "../../api/faq/getHighlightedFaqViews";
import { getQuote } from "../../api/quote/getQuote";
import { getQuoteVehicleSuggestions } from "../../api/quote/getQuoteVehicleSuggestions";
import APModal from "../../components/APModal";
import AppLocation from "../../components/AppLocation";
import APPrimaryButton from "../../components/buttons/APPrimaryButton";
import BackButton from "../../components/buttons/BackButton";
import InfoButton from "../../components/buttons/InfoButton";
import PageContainer from "../../components/PageContainer";
import PoweredByGemini from "../../components/PoweredByGemini";
import { filterQuotes, sortQuotes } from "../../helpers/quotes";
import { CRMAppLocationIdentifier } from "../../types/Faq";
import { VehicleSuggestion } from "../../types/Vehicles";
import VehicleSuggestionCard from "../components/VehicleSuggestionCard";

const ViewQuoteVehicleSuggestions = () => {
  const { quoteId } = useParams();
  const [selectedSuggestion, setSelectedSuggestion] =
    useState<VehicleSuggestion | null>(null);
  const { getScopedPageUrl, getScopedPageConfig, inAppScope } = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const makeIdOverride = searchParams.get("makeId") ?? undefined;
  const modelIdOverride = searchParams.get("modelId") ?? undefined;
  const modelYearOverride = searchParams.get("modelYear") ?? undefined;
  const variantIdOverride = searchParams.get("variantId") ?? undefined;
  const bevOnly =
    !!searchParams.get("bevOnly") && searchParams.get("bevOnly") === "true";

  if (!quoteId) {
    // TODO: improve client side error messaging
    throw new Error("quote id param is misisng");
  }

  const suggestions = getQuoteVehicleSuggestions(
    quoteId,
    makeIdOverride,
    modelIdOverride,
    modelYearOverride,
    variantIdOverride,
    bevOnly
  );
  const pageId = "vehicle-suggestions";
  const faqAppLocations = getFaqAppLocations({ pageId, quoteId });
  const highlightedFaqViews = getHighlightedFaqViews();
  const quote = getQuote(quoteId);
  const dossierId = quote.data?.crmDossierId;
  const dossier = getDossier(dossierId || "", { disabled: !dossierId });
  const quotes = getQuotes(dossierId || "", { disabled: !dossierId });

  const sortedQuotes =
    quotes.isSuccess && dossier.isSuccess
      ? sortQuotes(
          quotes.data,
          "lastUpdated",
          dossier.data.opportunity?.primaryQuoteId ?? null
        )
      : null;
  const oldestQuote = sortedQuotes
    ? sortedQuotes[sortedQuotes.length - 1]
    : null;

  if (suggestions.isError) {
    throw suggestions.error;
  }

  if (faqAppLocations.isError) {
    throw faqAppLocations.error;
  }

  if (highlightedFaqViews.isError) {
    throw highlightedFaqViews.error;
  }

  if (quote.isError) {
    throw quote.error;
  }

  if (dossier.isError) {
    throw quotes.error;
  }

  if (quotes.isError) {
    throw quotes.error;
  }

  const onCloseOverwriteQuoteModal = () => {
    setSelectedSuggestion(null);
  };

  const createQuote = (
    suggestion: VehicleSuggestion,
    overwriteQuote: boolean
  ) => {
    if (suggestions.data) {
      const {
        match: { make, model, year },
      } = suggestion;

      const makeParams = make ? `&makeId=${make.id}&makeName=${make.name}` : "";
      const modelParams = model
        ? `&modelId=${model.id}&modelName=${model.name}`
        : "";
      const modelYearParam = year ? `&modelYear=${year}` : "";
      const overwriteQuoteParam =
        overwriteQuote && oldestQuote
          ? `&overwriteQuote=${oldestQuote.crmQuoteId}`
          : "";

      const queryString = `?aiQueryId=${suggestions.data.queryId}${modelYearParam}${makeParams}${modelParams}${overwriteQuoteParam}`;
      navigate(`${getScopedPageUrl("cloneQuote", quoteId)}${queryString}`);
    }
  };

  const onQuote = (suggestion: VehicleSuggestion) => {
    const filteredQuotes =
      quotes.isSuccess && filterQuotes(quotes.data, inAppScope("internal"));
    const quoteMax: number = getScopedPageConfig("dossierMaxQuoteCount");
    const hasHitQuoteLimit =
      filteredQuotes && filteredQuotes.length >= quoteMax;

    if (hasHitQuoteLimit) {
      setSelectedSuggestion(suggestion);
    } else {
      createQuote(suggestion, false);
    }
  };

  const renderSuggestionCard = (
    suggestion: VehicleSuggestion,
    index: number
  ) => {
    return (
      <VehicleSuggestionCard
        key={index}
        vehicleSuggestion={suggestion}
        onButtonClick={onQuote}
      />
    );
  };

  const isLoading =
    suggestions.isFetching ||
    faqAppLocations.isFetching ||
    highlightedFaqViews.isLoading ||
    quote.isFetching ||
    dossier.isFetching ||
    quotes.isFetching;
  const isSuccess =
    suggestions.isSuccess &&
    faqAppLocations.isSuccess &&
    highlightedFaqViews.isSuccess &&
    quote.isSuccess &&
    dossier.isSuccess &&
    quotes.isSuccess;

  const faqContextIds = {
    quoteId: quoteId,
  };
  const renderAppLocation = (
    appLocationIdentifier: CRMAppLocationIdentifier,
    children: ReactNode,
    hideIfNoFaqs?: boolean,
    hideHighlight?: boolean
  ) => {
    return (
      <AppLocation
        appLocations={faqAppLocations.data || []}
        highlightedFaqViews={highlightedFaqViews.data || {}}
        appLocationIdentifier={appLocationIdentifier}
        hideIfNoFaqs={hideIfNoFaqs}
        hideHighlight={hideHighlight}
        contextIds={faqContextIds}
      >
        {children}
      </AppLocation>
    );
  };

  return (
    <PageContainer
      title="AI Vehicle Suggestions"
      loading={isLoading}
      titleButton={
        isSuccess ? (
          <Box mr={1}>
            {renderAppLocation(
              "AIVehicleSuggestion-Page",
              <InfoButton />,
              true,
              true
            )}
          </Box>
        ) : undefined
      }
    >
      <APModal
        isOpen={!!selectedSuggestion}
        onClose={onCloseOverwriteQuoteModal}
        closeText="Back"
        confirmButton={
          <APPrimaryButton
            variant="contained"
            text="Yes"
            onClick={() =>
              selectedSuggestion && createQuote(selectedSuggestion, true)
            }
          />
        }
      >
        <Typography variant="titleMedium">
          You have reached the maximum number of quotes.
        </Typography>
        {oldestQuote && (
          <Typography variant="titleMedium" mt={2}>
            Overwrite your quote: {oldestQuote.vehicleYear}{" "}
            {oldestQuote.vehicleMake} {oldestQuote.vehicleModel}
          </Typography>
        )}
      </APModal>
      {isSuccess && (
        <Stack spacing={2}>
          {renderAppLocation(
            "AIVehicleSuggestion-PoweredBy",
            <PoweredByGemini />,
            false,
            true
          )}
          <Stack
            sx={{
              display: "grid",
              gap: "16px",
              gridTemplateColumns: { md: "1fr 1fr" },
            }}
          >
            {suggestions.data.suggestions.map(renderSuggestionCard)}
          </Stack>
          <Box>
            <Typography variant="labelLarge">Need more help?</Typography>
            <Typography variant="bodyLarge" mt={1}>
              Contact our friendly team at new.driver@auto-ux.io or give us a
              call on 1800 288 689 so that we can assist.
            </Typography>
          </Box>
          <Box>
            <BackButton />
          </Box>
          <Box>
            <Typography variant="bodySmall" display="inline">
              Vehicle Suggestions are generated using AI and are subject to{" "}
            </Typography>
            <a href="https://www.auto-ux.io/terms-conditions/" target="_blank">
              <Typography variant="bodySmall" color="primary" display="inline">
                Auto-UX terms
              </Typography>
            </a>
          </Box>
        </Stack>
      )}
    </PageContainer>
  );
};

export default ViewQuoteVehicleSuggestions;
