export interface APEvent {
  event: string;
  userId: string | null;
  data: Record<string, unknown>;
}
export class PageViewEvent implements APEvent {
  event = "pageView";
  userId: string | null;
  data: Record<string, unknown>;

  constructor(path: string, crmUserId: string | null) {
    this.userId = crmUserId;
    this.data = {
      path,
    };
  }
}
