import Box from "@mui/material/Box";

import APCarousel from "../../components/APCarousel";
import { FaqContextIds } from "../../components/AppLocation";
import { Faq } from "../../types/Faq";
import FaqListCard from "./FaqListCard";

const FaqCarousel = ({
  faqs,
  appLocationId,
  contextIds,
}: {
  faqs: Faq[];
  appLocationId: string;
  contextIds: FaqContextIds;
}) => {
  const renderFaq = (faq: Faq) => {
    return (
      <Box key={`carousel-${faq.id}`} sx={{ height: "100%" }}>
        <FaqListCard
          faq={faq}
          appLocationId={appLocationId}
          highlightText
          flat
          contextIds={contextIds}
        />
      </Box>
    );
  };

  return <APCarousel>{faqs.map(renderFaq)}</APCarousel>;
};

export default FaqCarousel;
