import { useQuery } from "react-query";

import { ConfigurationMap } from "../../types/Config";
import { useApi } from "../ApiProvider";
import {
  APQueryOptions,
  LONG_STALE_TIME,
  useAPQuery,
} from "../APQueryProvider";

export const getPublicConfig = (options: APQueryOptions = {}) => {
  const { fetchWithAuth } = useApi();
  const { isQueryEnabled } = useAPQuery();

  return useQuery({
    queryKey: ["public-config"],
    queryFn: (): Promise<ConfigurationMap> => {
      return fetchWithAuth<ConfigurationMap>("getPublicConfig");
    },
    enabled: isQueryEnabled(options),
    staleTime: LONG_STALE_TIME,
  });
};
