import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/plugins/counter.css";

import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Lightbox from "yet-another-react-lightbox";
import Counter from "yet-another-react-lightbox/plugins/counter";
import Inline from "yet-another-react-lightbox/plugins/inline";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";

import { PRIMARY_COLOR } from "../theme";

export default function ImageCarousel({ images }: { images: string[] }) {
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const theme = useTheme();
  const navigate = useNavigate();

  const mobileWidthBreakpoint = useMediaQuery(theme.breakpoints.down("sm"));
  const mobileHeightBreakpoint = useMediaQuery("(max-height:600px)");
  const mobileView = mobileWidthBreakpoint || mobileHeightBreakpoint;

  const toggleOpen = (state: boolean) => () => {
    setOpen(state);

    if (state) {
      // Adds an entry to history so back button will close lightbox
      navigate("#lightbox", { replace: false });
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    const handlePopState = () => {
      // Close the lightbox if it's open and the user navigates back
      if (open) {
        setOpen(false);
      }
    };

    window.addEventListener("popstate", handlePopState);
    return () => window.removeEventListener("popstate", handlePopState);
  }, [open]);

  const updateIndex = ({ index: current }: { index: number }) =>
    setIndex(current);

  const galleryPlugins = [Inline];
  const viewerPlugins = [Counter, Zoom];

  if (images.length > 1) {
    if (!mobileView) {
      galleryPlugins.push(Thumbnails);
      viewerPlugins.push(Thumbnails);
    }

    if (!open) {
      galleryPlugins.push(Slideshow);
    }
  }

  return (
    <>
      <Lightbox
        index={index}
        slides={images.map((image) => {
          return { src: image, width: 1795, height: 1195 };
        })}
        plugins={galleryPlugins}
        on={{
          view: updateIndex,
          click: toggleOpen(true),
        }}
        carousel={{
          padding: 0,
          spacing: 0,
          imageFit: "contain",
          finite: images.length <= 1,
        }}
        inline={{
          style: {
            width: "100%",
            maxWidth: "900px",
            aspectRatio: "3 / 2",
            margin: "0 auto",
            maxHeight: "25rem",
          },
        }}
        render={{
          buttonPrev: images.length <= 1 ? () => null : undefined,
          buttonNext: images.length <= 1 ? () => null : undefined,
          iconSlideshowPause: () => null,
        }}
        styles={{
          container: {
            backgroundColor: "white",
          },
          thumbnailsContainer: {
            backgroundColor: "white",
          },
          navigationNext: {
            boxShadow: "none",
          },
          root: {
            "--yarl__color_backdrop": "rgba(255, 255, 255, .8)",
            "--yarl__thumbnails_thumbnail_active_border_color": PRIMARY_COLOR,
          },
        }}
        thumbnails={{
          position: "end",
          border: 4,
          padding: 0,
          width: 100,
          height: 67,
        }}
        slideshow={{
          autoplay: true,
          delay: 8000,
        }}
      />

      <Lightbox
        open={open}
        close={toggleOpen(false)}
        plugins={viewerPlugins}
        index={index}
        slides={images.map((image) => {
          return { src: image, width: 1795, height: 1195 };
        })}
        on={{ view: updateIndex }}
        animation={{ fade: 0, swipe: 0 }}
        controller={{ closeOnPullDown: true, closeOnBackdropClick: true }}
        styles={{
          root: {
            "--yarl__thumbnails_thumbnail_active_border_color": PRIMARY_COLOR,
            "--yarl__thumbnails_thumbnail_border_color": "transparent",
          },
        }}
        zoom={{
          maxZoomPixelRatio: mobileView ? 1.5 : 2.5,
          scrollToZoom: true,
        }}
        render={{
          buttonPrev: images.length <= 1 ? () => null : undefined,
          buttonNext: images.length <= 1 ? () => null : undefined,
        }}
        thumbnails={
          !mobileView
            ? {
                vignette: true,
                border: 4,
                padding: 0,
                width: 100,
                height: 67,
              }
            : undefined
        }
        carousel={{
          preload: 9,
          finite: images.length <= 1,
        }}
      />
    </>
  );
}
