import AutorenewIcon from "@mui/icons-material/Autorenew";
import { useNavigate } from "react-router-dom";

import APIconButton from "./APIconButton";

const RefreshButton = () => {
  const navigate = useNavigate();

  const onRefresh = async () => {
    navigate(0);
  };

  return <APIconButton icon={<AutorenewIcon />} onClick={onRefresh} />;
};

export default RefreshButton;
