import { Location } from "react-router-dom";

export const isAbsoluteUrl = (url: string): boolean => {
  const regex = /^[a-zA-Z][a-zA-Z\d+\-.]*:/;
  return regex.test(url);
};

export const isModalRoute = (location: Location<unknown>): boolean => {
  return location.search.includes("modal=");
};

export const getParamFromUrl = (href: string, param: string) => {
  const url = new URL(href);
  const params = new URLSearchParams(url.search);

  return params.get(param);
};

export const getParamFromQueryString = (queryString: string, param: string) => {
  const params = new URLSearchParams(queryString);

  return params.get(param);
};

export const replaceParam = (
  key: string,
  value: string,
  params: URLSearchParams
) => {
  params.set(key, value);

  return params;
};

export const replaceModalParam = (
  newModal: string,
  params: URLSearchParams
): URLSearchParams => {
  return replaceParam("modal", newModal, params);
};

export const addParamToHref = (key: string, value: string, href: string) => {
  const url = new URL(href);
  const params = new URLSearchParams(url.search);
  const newParams = replaceParam(key, value, params);
  url.search = newParams.toString();

  return url.toString();
};

export const addModalToHref = (newModal: string, href: string) => {
  return addParamToHref("modal", newModal, href);
};

export const removeModalParams = (params: URLSearchParams): URLSearchParams => {
  // Delete all modal params
  const keysToDelete = ["modal"];
  const keys = Array.from(params.keys());
  for (const key of keys) {
    if (key.startsWith("modal.")) {
      keysToDelete.push(key);
    }
  }
  keysToDelete.forEach((key) => params.delete(key));

  return params;
};

export const openInNewTab = (url: string) => {
  window.open(url, "_blank", "noopener,noreferrer");
};
