import { useNavigate } from "react-router-dom";

import APPrimaryButton from "./APPrimaryButton";

const BackButton = ({ onClick }: { onClick?: () => void }) => {
  const navigate = useNavigate();

  return (
    <APPrimaryButton text="Back" onClick={onClick || (() => navigate(-1))} />
  );
};

export default BackButton;
