import {
  FormattedCurrencyValue,
  FormattedValue,
} from "../types/FormattedValues";
import {
  CalculatedQuote,
  CalculatedQuoteFormatted,
  payCyclePeriodOptions,
  QuoteInclusions,
  QuoteInclusionsFormatted,
  QuotePayPeriod,
} from "../types/Quote";
import { getAutoCompleteLabel } from "./buildOptions";
import { toDollars, toFormattedNumber } from "./currencies";
import { getPurchaseTypeLabel } from "./quotes";

export const toFormattedValue = <T>(
  raw: T,
  formatted: string
): FormattedValue<T> => {
  return {
    raw,
    formatted,
  };
};

export const toFormattedCurrency = (curr: number): FormattedCurrencyValue => {
  return {
    raw: curr,
    formatted: toDollars(curr, 2),
    formatted_exc_cents: toDollars(curr, 0),
  };
};

const formatQuoteInclusions = (
  inclusions: QuoteInclusions
): QuoteInclusionsFormatted => {
  return {
    finance: toFormattedCurrency(inclusions.finance),
    servicing: toFormattedCurrency(inclusions.servicing),
    tyres: toFormattedCurrency(inclusions.tyres),
    fuel: toFormattedCurrency(inclusions.fuel),
    rego: toFormattedCurrency(inclusions.rego),
    insurance: toFormattedCurrency(inclusions.insurance),
    managementFees: toFormattedCurrency(inclusions.managementFees),
    luxuryCharge: toFormattedCurrency(inclusions.luxuryCharge),
    gst: toFormattedCurrency(inclusions.gst),
  };
};

export const formatCalculatedQuote = (
  quote: CalculatedQuote,
  payCyclePeriod?: QuotePayPeriod
): CalculatedQuoteFormatted => {
  const period = payCyclePeriod || quote.payPeriod;
  const periodLabel = getAutoCompleteLabel(payCyclePeriodOptions, period);
  const peroidValues = quote.valuesPerPeriod[period];

  return {
    id: quote.id,
    dossierId: quote.dossierId,
    name: quote.name || "",
    isLocked: quote.isLocked,
    vehicle: {
      make: quote.vehicle.make,
      makeId: quote.vehicle.makeId,
      model: quote.vehicle.model,
      modelId: quote.vehicle.modelId,
      year: quote.vehicle.year,
      description: quote.vehicle.description,
      variantId: quote.vehicle.variantId,
      variantCode: quote.vehicle.variantCode,
      series: quote.vehicle.series,
      body: quote.vehicle.body,
      seats: toFormattedValue(quote.vehicle.seats, `${quote.vehicle.seats}`),
      transmission: quote.vehicle.transmission,
      driveType: quote.vehicle.driveType,
      variantShortName: quote.vehicle.variantShortName,
      variantLongName: quote.vehicle.variantLongName,
      fuelType: quote.vehicle.fuelType,
      co2Emissions: toFormattedValue(
        quote.vehicle.co2Emissions,
        `${quote.vehicle.co2Emissions}`
      ),
      ancapRating: toFormattedValue(
        quote.vehicle.ancapRating,
        `${quote.vehicle.ancapRating} star`
      ),
      standardEquipment: quote.vehicle.standardEquipment,
      initialOdometer: quote.vehicle.initialOdometer
        ? toFormattedValue(
            quote.vehicle.initialOdometer,
            `${quote.vehicle.initialOdometer}`
          )
        : null,
    },
    state: `${quote.state}`,
    primaryImageCode: quote.primaryImageCode,
    packageValue: toFormattedCurrency(peroidValues.packageValue),
    netCost: toFormattedCurrency(peroidValues.netCost),
    inclusions: formatQuoteInclusions(peroidValues.inclusions),
    payPeriod: toFormattedValue(period, periodLabel),
    runningCostsPA: toFormattedCurrency(quote.runningCostsPA),
    annualSalary: toFormattedCurrency(quote.annualSalary),
    annualKm: toFormattedValue(
      quote.annualKm,
      `${toFormattedNumber(quote.annualKm, 0)} km`
    ),
    vehicleOnroadCosts: toFormattedCurrency(quote.vehicleOnroadCosts),
    vehicleResidualGross: toFormattedCurrency(quote.vehicleResidualGross),
    term: toFormattedValue(quote.term, `${quote.term}`),
    businessUsage: toFormattedValue(
      quote.businessUsage,
      `${quote.businessUsage.toFixed(0)}%`
    ),
    savings: toFormattedValue(quote.savings, `${quote.savings.toFixed(0)}%`),
    savingsPerYear: toFormattedCurrency(quote.savingsPerYear),
    lifeOfLeaseSavings: toFormattedCurrency(quote.lifeOfLeaseSavings),
    listPrice: toFormattedCurrency(quote.listPrice),
    catchEEquipmentIds: quote.catchEEquipmentIds,
    catchEQuoteId: quote.catchEQuoteId,
    catchEQuoteUrl: quote.catchEQuoteUrl,
    purchaseType: toFormattedValue(
      quote.purchaseType,
      getPurchaseTypeLabel(quote.purchaseType)
    ),
    discount: quote.discount ? toFormattedCurrency(quote.discount) : null,
  };
};
