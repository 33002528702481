import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../api/AuthProvider";
import { getDocuments } from "../../api/document/getDocuments";
import { getMyDossiers } from "../../api/dossier/getMyDossiers";
import { qualifyNewLead } from "../../api/user/qualifyNewLead";
import MenuCard from "../../components/MenuCard";
import PageContainer from "../../components/PageContainer";
import {
  filterAuthorisedRolesForPage,
  hasAccountRoles,
  isAuthorisedToViewEmployerPage,
} from "../../helpers/user";
import { User } from "../../types/User";
import { DEFAULT_PAGE_SIZE } from "../documentList";

const EmployerHome = ({ me }: { me: User }) => {
  const navigate = useNavigate();
  const { getScopedPageUrl } = useAuth();
  const [navigationCheckComplete, setNavigationCheck] = useState(false);
  const promotionAuthorisedRoles = me.contactRoles
    ? filterAuthorisedRolesForPage(me.contactRoles, "employerPromotions")
    : [];

  const { mutateAsync, isLoading: isUpdating } = qualifyNewLead();

  const handleNewLead = async () => {
    await mutateAsync();
    navigate(`${getScopedPageUrl("viewDossiers")}?openFirst=true`);
  };

  const promotionDocuments = getDocuments(
    {
      pageSize: DEFAULT_PAGE_SIZE,
      accountIds: promotionAuthorisedRoles.map((role) => role.accountId),
      documentTypes: ["Promotion"],
    },
    { disabled: !navigationCheckComplete }
  );
  const dossiers = getMyDossiers();

  if (dossiers.isError) {
    throw dossiers.error;
  }

  useEffect(() => {
    if (me.contactRoles && hasAccountRoles(me.contactRoles)) {
      setNavigationCheck(true);
    } else {
      navigate(getScopedPageUrl("viewDossiers"), { replace: true });
    }
  });

  const isLoading = dossiers.isLoading || promotionDocuments.isLoading;
  const isSuccess = dossiers.isSuccess && promotionDocuments.isFetched;

  return (
    <PageContainer
      loading={isLoading || isUpdating || !navigationCheckComplete}
    >
      {isSuccess && (
        <Stack spacing={2}>
          <MenuCard
            title="My Car"
            primaryButton={
              dossiers.data.length > 0 ? (
                <Button
                  size="large"
                  color="primary"
                  variant="contained"
                  disableElevation={true}
                  onClick={() => navigate(getScopedPageUrl("viewDossiers"))}
                >
                  My Car
                </Button>
              ) : undefined
            }
            secondaryButton={
              dossiers.data.length === 0 ? (
                <Button
                  size="large"
                  color="secondary"
                  variant="contained"
                  disableElevation={true}
                  onClick={handleNewLead}
                  startIcon={<PlayArrowOutlinedIcon />}
                >
                  New Car
                </Button>
              ) : undefined
            }
          />

          {isAuthorisedToViewEmployerPage(
            me.contactRoles || [],
            "employerApproveDrivers"
          ) && (
            <MenuCard
              title="Approve"
              primaryButton={
                <Button
                  size="large"
                  color="primary"
                  variant="contained"
                  disableElevation={true}
                  onClick={() =>
                    navigate(getScopedPageUrl("employerApproveDrivers"))
                  }
                >
                  Approve Employees
                </Button>
              }
              secondaryButton={
                isAuthorisedToViewEmployerPage(
                  me.contactRoles || [],
                  "employerPackagingAgreements"
                ) && (
                  <Button
                    size="large"
                    color="primary"
                    variant="contained"
                    disableElevation={true}
                    onClick={() =>
                      navigate(getScopedPageUrl("employerPackagingAgreements"))
                    }
                  >
                    Packaging Agreements
                  </Button>
                )
              }
            />
          )}

          {(isAuthorisedToViewEmployerPage(
            me.contactRoles || [],
            "employerEngagementReports"
          ) ||
            (promotionDocuments.isSuccess &&
              promotionDocuments.data.count > 0)) && (
            <MenuCard
              title="Engagement"
              primaryButton={
                promotionDocuments.isSuccess &&
                promotionDocuments.data.count > 0 && (
                  <Button
                    size="large"
                    color="primary"
                    variant="contained"
                    disableElevation={true}
                    onClick={() =>
                      navigate(getScopedPageUrl("employerPromotions"))
                    }
                  >
                    Promotions
                  </Button>
                )
              }
              secondaryButton={
                isAuthorisedToViewEmployerPage(
                  me.contactRoles || [],
                  "employerEngagementReports"
                ) && (
                  <Button
                    size="large"
                    color="primary"
                    variant="contained"
                    disableElevation={true}
                    onClick={() =>
                      navigate(getScopedPageUrl("employerEngagementReports"))
                    }
                  >
                    Reports
                  </Button>
                )
              }
            />
          )}

          {isAuthorisedToViewEmployerPage(
            me.contactRoles || [],
            "employerInvoices"
          ) && (
            <MenuCard
              title="Invoices"
              primaryButton={
                <Button
                  size="large"
                  color="primary"
                  variant="contained"
                  disableElevation={true}
                  onClick={() => navigate(getScopedPageUrl("employerInvoices"))}
                >
                  Invoices
                </Button>
              }
            />
          )}

          {isAuthorisedToViewEmployerPage(
            me.contactRoles || [],
            "employerPayrollAdvice"
          ) && (
            <MenuCard
              title="Payroll"
              primaryButton={
                <Button
                  size="large"
                  color="primary"
                  variant="contained"
                  disableElevation={true}
                  onClick={() =>
                    navigate(getScopedPageUrl("employerPayrollAdvice"))
                  }
                >
                  Advices
                </Button>
              }
            />
          )}

          {isAuthorisedToViewEmployerPage(
            me.contactRoles || [],
            "employerFBTReports"
          ) && (
            <MenuCard
              title="FBT"
              primaryButton={
                <Button
                  size="large"
                  color="primary"
                  variant="contained"
                  disableElevation={true}
                  onClick={() =>
                    navigate(getScopedPageUrl("employerFBTReports"))
                  }
                >
                  Reports
                </Button>
              }
            />
          )}

          {isAuthorisedToViewEmployerPage(
            me.contactRoles || [],
            "employerLuxuryLeaseReports"
          ) && (
            <MenuCard
              title="Luxury"
              primaryButton={
                <Button
                  size="large"
                  color="primary"
                  variant="contained"
                  disableElevation={true}
                  onClick={() =>
                    navigate(getScopedPageUrl("employerLuxuryLeaseReports"))
                  }
                >
                  Reports
                </Button>
              }
            />
          )}
        </Stack>
      )}
    </PageContainer>
  );
};

export default EmployerHome;
