import { PageDirectoryEntryName } from "../api/PageDirectory";
import { APDocumentType } from "../types/Document";
import { ContactRole, RoleName } from "../types/User";
import { arraysHaveIntersection } from "./array";

const EmployerPageNameRestrictedRoleMap: Record<EmployerPageName, RoleName[]> =
  {
    employerApproveDrivers: [
      "Primary Authorised Signatory",
      "Authorised Signatory",
    ],
    employerInvoices: ["Primary Invoice Contact", "Invoice Contact"],
    employerPackagingAgreements: [
      "Primary Authorised Signatory",
      "Authorised Signatory",
    ],
    employerPayrollAdvice: ["Primary Payroll Contact", "Payroll Contact"],
    employerFBTReports: ["Primary FBT Contact", "FBT Contact"],
    employerEngagementReports: ["Internal Promoter", "Engagement Reporting"],
    employerLuxuryLeaseReports: ["Luxury Lease Contact"],
    employerPromotions: [],
  };

const PageNameToDocumentTypesMap: Record<
  EmployerDocumentPageName,
  APDocumentType[]
> = {
  employerInvoices: ["Invoice", "Reconciliation Invoice", "Credit Note"],
  employerPackagingAgreements: ["Packaging Agreement"],
  employerPayrollAdvice: ["Payroll Advice", "Termination Advice"],
  employerFBTReports: ["FBT Report"],
  employerEngagementReports: ["Engagement Report"],
  employerLuxuryLeaseReports: ["Luxury Lease Report"],
  employerPromotions: ["Promotion"],
};

export type EmployerPageName = Extract<
  PageDirectoryEntryName,
  | "employerApproveDrivers"
  | "employerInvoices"
  | "employerPackagingAgreements"
  | "employerPayrollAdvice"
  | "employerFBTReports"
  | "employerEngagementReports"
  | "employerLuxuryLeaseReports"
  | "employerPromotions"
>;

export type EmployerDocumentPageName = Exclude<
  EmployerPageName,
  "employerApproveDrivers"
>;

export const hasAccountRoles = (roles: ContactRole[]) => {
  return roles.some((role) => role.accountIsAuthorised);
};

export const filterAuthorisedRolesForPage = (
  roles: ContactRole[],
  page: EmployerPageName
) => {
  const restrictedRoles = EmployerPageNameRestrictedRoleMap[page];

  return roles.filter(
    (role) =>
      role.accountIsAuthorised &&
      (restrictedRoles.length === 0 || restrictedRoles.includes(role.name))
  );
};

export const isAuthorisedToViewEmployerPage = (
  roles: ContactRole[],
  pageName: EmployerPageName
) => {
  const authorisedRoles = roles.filter((role) => role.accountIsAuthorised);
  const userRoleNames = authorisedRoles.map((role) => role.name);
  const restrictedRoleNames = EmployerPageNameRestrictedRoleMap[pageName];

  return (
    authorisedRoles.length > 0 &&
    (restrictedRoleNames.length === 0 ||
      arraysHaveIntersection(userRoleNames, restrictedRoleNames))
  );
};

export const getDocumentTypesForEmployerPage = (
  page: EmployerDocumentPageName
): APDocumentType[] => {
  return PageNameToDocumentTypesMap[page];
};
