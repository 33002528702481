import { faRobot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { TERTIARY_COLOR } from "../theme";

const PoweredByGemini = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <FontAwesomeIcon icon={faRobot} color={TERTIARY_COLOR} size="xl" />
      <Typography variant="labelLarge" mx={1}>
        AI Powered by
      </Typography>
      <Box
        component="img"
        sx={{ height: 20, display: "flex" }}
        src="/gemini.png"
      ></Box>
    </Box>
  );
};

export default PoweredByGemini;
