import { Quote, VehiclePurchaseType } from "../types/Quote";

export type QuoteSortOption = "taxSavings" | "netWeeklyCost" | "lastUpdated";

const puchaseTypeLabelMap: Record<VehiclePurchaseType, string> = {
  new: "New",
  used: "Used",
  "sale-and-leaseback": "Lease my own car",
  refinance: "Refinance",
  renovation: "Renovation",
  "external-financier-takeover": "External Financier Takeover",
};

export const getPurchaseTypeLabel = (
  purchaseType: VehiclePurchaseType
): string => {
  return puchaseTypeLabelMap[purchaseType];
};

export const filterQuotes = (quotes: Quote[], isInternal: boolean) => {
  return quotes.filter((quote) => isInternal || quote.isVisible);
};

export const sortQuotes = (
  quotes: Quote[],
  quoteSortOptionId: QuoteSortOption,
  primaryQuoteId: string | null
): Quote[] => {
  const quotesToSort = [...quotes];

  let compareFunction: (a: Quote, b: Quote) => number;

  switch (quoteSortOptionId) {
    case "netWeeklyCost":
      compareFunction = (a: Quote, b: Quote) => {
        if (a.weeklyNetCosts < b.weeklyNetCosts) {
          return -1;
        }
        if (a.weeklyNetCosts > b.weeklyNetCosts) {
          return 1;
        }
        return 0;
      };
      break;
    case "lastUpdated":
      compareFunction = (a: Quote, b: Quote) => {
        if (a.crmQuoteId === primaryQuoteId) {
          return -1;
        } else if (b.crmQuoteId === primaryQuoteId) {
          return 1;
        } else if (a.updatedAt > b.updatedAt) {
          return -1;
        }
        if (a.updatedAt < b.updatedAt) {
          return 1;
        }
        return 0;
      };
      break;
    case "taxSavings":
      compareFunction = (a: Quote, b: Quote) => {
        if (a.savings > b.savings) {
          return -1;
        }
        if (a.savings < b.savings) {
          return 1;
        }
        return 0;
      };
      break;
  }

  quotesToSort.sort(compareFunction);

  return quotesToSort;
};
