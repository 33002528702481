import { useQuery } from "react-query";

import {
  getLocalStorageItem,
  HIGHLIGHTED_FAQ_VIEW_COUNTS_KEY,
} from "../../helpers/localStorage";
import { APQueryOptions, useAPQuery } from "../APQueryProvider";

export type HighlightedFaqViewCounts = Record<string, number>;

export const getHighlightedFaqViews = (options: APQueryOptions = {}) => {
  const { isQueryEnabled } = useAPQuery();

  return useQuery({
    queryKey: ["highlighted-faq-views"],
    queryFn: (): HighlightedFaqViewCounts => {
      return (
        getLocalStorageItem<HighlightedFaqViewCounts>(
          HIGHLIGHTED_FAQ_VIEW_COUNTS_KEY
        ) || {}
      );
    },
    enabled: isQueryEnabled(options),
  });
};
