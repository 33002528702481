import { useQuery } from "react-query";

import { APPage } from "../../types/Faq";
import { ProductTour } from "../../types/ProductTour";
import { useApi } from "../ApiProvider";
import { APQueryOptions, useAPQuery } from "../APQueryProvider";

export const getProductTours = (
  pageId: APPage,
  options: APQueryOptions = {}
) => {
  const { fetchWithAuth } = useApi();
  const { isQueryEnabled } = useAPQuery();

  return useQuery({
    queryKey: ["productTours", pageId],
    queryFn: (): Promise<ProductTour[]> => {
      return fetchWithAuth<ProductTour[]>("getProductTours", pageId);
    },
    enabled: isQueryEnabled(options),
  });
};
