import { ReactNode } from "react";

import { HighlightedFaqViewCounts } from "../api/faq/getHighlightedFaqViews";
import { FaqContextIds } from "../components/AppLocation";
import { CRMAppLocationIdentifier, FaqAppLocation } from "../types/Faq";
import { getNestedProperty } from "./object";
import { addParamToHref } from "./routes";

const HIGHLIGHTED_FAQ_VIEW_MAX = 2;

export type AppLocationRenderFunction = (
  appLocationIdentifier: CRMAppLocationIdentifier,
  children: ReactNode,
  hideIfNoFaqs?: boolean,
  hideHighlight?: boolean,
  faqContextIds?: FaqContextIds
) => JSX.Element;

export const hasHighlightedFaqs = (
  appLocations: FaqAppLocation[],
  appLocationIdentifiers: CRMAppLocationIdentifier[],
  highlightedFaqViewCounts: HighlightedFaqViewCounts
): boolean => {
  return appLocations.some((location) => {
    return (
      appLocationIdentifiers.includes(location.appLocationIdentifier) &&
      !!location.highlightedFaq &&
      (!highlightedFaqViewCounts[location.highlightedFaq.id] ||
        highlightedFaqViewCounts[location.highlightedFaq.id] <
          HIGHLIGHTED_FAQ_VIEW_MAX)
    );
  });
};

export const addFaqModalParamsToHref = (
  href: string,
  appLocationId: string,
  contextIds?: FaqContextIds
) => {
  let linkTo = addParamToHref("modal.appLocationId", appLocationId, href);

  if (contextIds?.quoteId) {
    // Add quote id modal param
    linkTo = addParamToHref("modal.quoteId", contextIds.quoteId, linkTo);
  }

  return linkTo;
};

export const addFaqParamsToSearchParams = (
  params: URLSearchParams,
  appLocationId: string,
  faqAppLocationId: string | null,
  contextIds?: FaqContextIds
) => {
  params.append("modal.appLocationId", appLocationId);

  if (faqAppLocationId) {
    params.append("modal.faqAppLocationId", faqAppLocationId);
  }

  if (contextIds?.quoteId) {
    params.append("modal.quoteId", contextIds.quoteId);
  }

  return params;
};

export const compileTemplate = (
  template: string,
  values: Record<string, unknown>,
  valuePrefix?: string
): string => {
  const compliedTemplate = template.replace(/\{\{([^{}]+)\}\}/g, (_, key) => {
    const valueKey = valuePrefix ? key.replace(`${valuePrefix}.`, "") : key;
    const value = getNestedProperty(valueKey, values);

    return value || value === 0 ? `${value}` : "";
  });

  return compliedTemplate;
};
