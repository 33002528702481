import { useQuery } from "react-query";

import { VehicleSuggestions } from "../../types/Vehicles";
import { useApi } from "../ApiProvider";
import { useAPQuery } from "../APQueryProvider";

export const VEHICLE_SUGGESTIONS_DELAY = 10000;
export const VEHICLE_SUGGESTIONS_DELAY_ALERT =
  "Working on the car of your dreams, Almost there.";

export const getQuoteVehicleSuggestions = (
  quoteId: string,
  makeId?: string,
  modelId?: string,
  modelYear?: string,
  variantId?: string,
  bevOnly?: boolean
) => {
  const { fetchWithAuth } = useApi();
  const { alertOnLongRequest } = useAPQuery();
  const queryParams = {
    makeId,
    modelId,
    modelYear,
    variantId,
    bevOnly: bevOnly ? "true" : undefined,
  };

  return useQuery({
    queryKey: ["quote-vehicle-suggestions", quoteId],
    queryFn: (): Promise<VehicleSuggestions> => {
      return alertOnLongRequest(
        fetchWithAuth<VehicleSuggestions>(
          "getQuoteVehicleSuggestions",
          quoteId,
          { queryParams }
        ),
        VEHICLE_SUGGESTIONS_DELAY,
        VEHICLE_SUGGESTIONS_DELAY_ALERT
      );
    },
  });
};
