import { HighlightedFaqViewCounts } from "../api/faq/getHighlightedFaqViews";
import { CRMAppLocationIdentifier, FaqAppLocation } from "../types/Faq";
import APDetailRow from "./APDetailRow";
import AppLocation, { FaqContextIds } from "./AppLocation";

const FaqDetailRow = ({
  label,
  value,
  appLocationIdentifier,
  appLocations,
  highlightedFaqViews,
  contextIds,
}: {
  label: string;
  value: string;
  appLocationIdentifier: CRMAppLocationIdentifier;
  appLocations: FaqAppLocation[];
  highlightedFaqViews: HighlightedFaqViewCounts;
  contextIds: FaqContextIds;
}) => {
  return (
    <APDetailRow
      label={label}
      value={value}
      leftWrapper={{
        component: AppLocation,
        props: {
          appLocations: appLocations,
          appLocationIdentifier: appLocationIdentifier,
          highlightedFaqViews: highlightedFaqViews,
          contextIds: contextIds,
        },
      }}
      rightWrapper={{
        component: AppLocation,
        props: {
          appLocations: appLocations,
          appLocationIdentifier: appLocationIdentifier,
          highlightedFaqViews: highlightedFaqViews,
          contextIds: contextIds,
          hideHighlight: true,
        },
      }}
    />
  );
};

export default FaqDetailRow;
