import Box from "@mui/material/Box";
import Slide from "@mui/material/Slide";
import { ReactNode, useRef } from "react";

import AIButton from "./AIButton";

const AIVehicleSuggestionsButton = ({
  onClick,
  animateOnRender = false,
  endAdornment,
}: {
  onClick: () => void;
  animateOnRender?: boolean;
  endAdornment?: ReactNode;
}) => {
  const suggestionsContainerRef = useRef<HTMLElement>(null);

  return (
    <Box
      ref={suggestionsContainerRef}
      sx={{ overflow: "hidden", px: "8px", mx: "-8px" }}
    >
      <Slide
        direction="down"
        in={true}
        container={suggestionsContainerRef.current}
        timeout={1000}
        appear={animateOnRender}
      >
        <Box>
          <AIButton
            text="AI Vehicle Suggestions"
            onClick={onClick}
            endAdornment={endAdornment}
            pulseOnRender={animateOnRender}
          />
        </Box>
      </Slide>
    </Box>
  );
};

export default AIVehicleSuggestionsButton;
