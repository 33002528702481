import { useQuery } from "react-query";

import { DossierQuoteSyncStatus } from "../../types/Quote";
import { useApi } from "../ApiProvider";
import { APQueryOptions, useAPQuery } from "../APQueryProvider";

export const getSyncQuotesStatus = (
  contactId: string,
  quoteId?: string,
  options: APQueryOptions = {}
) => {
  const { fetchWithAuth } = useApi();
  const {
    isQueryEnabled,
    defaultOnMutateSuccess,
    defaultOnMutateError,
    defaultOnMutateSettled,
  } = useAPQuery();
  const queryKey = ["syncQuotesStatus", contactId];

  return useQuery({
    queryKey,
    queryFn: (): Promise<DossierQuoteSyncStatus> => {
      return fetchWithAuth<DossierQuoteSyncStatus>(
        "getSyncQuotesStatus",
        contactId
      );
    },
    enabled: isQueryEnabled(options),
    onSettled: (data) => {
      if (data === "SYNCING") {
        setTimeout(() => {
          defaultOnMutateSettled([queryKey]);
        }, 5000);
      } else if (
        data === "SUCCESS" &&
        options.mutationOptions?.successAlertMessage
      ) {
        const invalidateList = [["quotes"], ["dossier"]];

        if (quoteId) {
          invalidateList.push(["quote", quoteId]);
          invalidateList.push(["calculated-quote", quoteId]);
        }

        const success = defaultOnMutateSuccess(options);
        success && success();
        defaultOnMutateSettled(invalidateList);
      } else if (options.mutationOptions?.errorAlertMessage) {
        const errorMessage = `${options.mutationOptions.errorAlertMessage}, Reason: ${data}`;

        const onError = defaultOnMutateError({
          ...options,
          mutationOptions: {
            ...options.mutationOptions,
            errorAlertMessage: errorMessage,
          },
        });

        onError();
      }
    },
  });
};
