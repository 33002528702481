import { useEffect, useRef, useState } from "react";
import Joyride, { CallBackProps, STATUS, Step } from "react-joyride";
import { useLocation } from "react-router-dom";

import { createProductTourView } from "../api/productTour/createProductTourView";
import { setProductTourView } from "../api/productTour/setProductTourView";
import { escapeClassSelector } from "../helpers/css";
import { isModalRoute } from "../helpers/routes";
import { PRODUCT_TOUR_ZINDEX, SECONDARY_COLOR } from "../theme";
import { ProductTour } from "../types/ProductTour";
import { APJoyrideTooltip } from "./APJoyrideTooltip";

const APProductTour = ({
  tour,
  tourContainerRef,
}: {
  tour: ProductTour;
  tourContainerRef: React.RefObject<HTMLDivElement>;
}) => {
  const hasEntered = useRef(false); // Track if it has already been set
  const latestStep = useRef(0);
  const creatingView = useRef<Promise<boolean> | null>(null);
  const [runTour, setRunTour] = useState(false);
  const [tourViewed, setTourViewed] = useState(false);
  const location = useLocation();

  const tourSteps: Step[] = tour.steps.map((step) => {
    const target = escapeClassSelector(
      `.productTour-${step.appLocationIdentifier}`
    );
    return {
      target,
      title: step.title,
      content: <span dangerouslySetInnerHTML={{ __html: step.content }} />,
      disableBeacon: true,
    };
  });

  const expandCollapsedTourSteps = () => {
    tour.steps.map((step) => {
      const targetSelector = `productTour-${step.appLocationIdentifier}`;
      const target = tourContainerRef.current
        ? tourContainerRef.current.getElementsByClassName(targetSelector)[0]
        : null;

      if (target) {
        // Check if target is in a collapsed accordion
        const accordion = target.closest(".APAccordion");

        // If accordion is collapsed then trigger the expand
        if (accordion && !accordion.classList.contains("Mui-expanded")) {
          const expand = accordion.querySelector(
            ".APAccordion-expand"
          ) as HTMLElement | null;

          expand?.click();
        }
      }
    });
  };

  const triggerTour = () => {
    return setTimeout(() => {
      hasEntered.current = false;
      setRunTour(true);
    }, tour.triggerDelay || 0);
  };

  const canTriggerTour = () => {
    // DON'T trigger product tour if a modal is open
    const modalIsOpen = isModalRoute(location);

    return !runTour && !tourViewed && !modalIsOpen && tourSteps.length > 0;
  };

  const setupAppLocationTrigger = () => {
    if (tour.triggerAppLocationIdentifier) {
      const triggerSelector = `productTour-${tour.triggerAppLocationIdentifier}`;

      const trigger = tourContainerRef.current
        ? tourContainerRef.current.getElementsByClassName(triggerSelector)[0]
        : null;
      let timer: NodeJS.Timeout;

      const observer = new IntersectionObserver(
        ([entry]) => {
          // Only set the first time it enters the viewport
          if (entry.isIntersecting && !hasEntered.current) {
            hasEntered.current = true;
            timer = triggerTour();
          }
        },
        {
          root: null,
          rootMargin: "0px",
          threshold: 1,
        }
      );

      if (trigger) {
        observer.observe(trigger);
      }

      return () => {
        if (timer) {
          hasEntered.current = false;
          clearTimeout(timer);
        }

        if (trigger) {
          observer.unobserve(trigger);
        }
      };
    }
  };

  const setupOnLoadTrigger = () => {
    let timer: NodeJS.Timeout;

    if (canTriggerTour()) {
      timer = triggerTour();
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  };

  // Detect when bottom of the page is reached
  useEffect(() => {
    if (canTriggerTour()) {
      switch (tour.triggerType) {
        case "app-location-visible":
          return setupAppLocationTrigger();
        case "on-load":
          return setupOnLoadTrigger();
      }
    }
  }, [tour.triggerAppLocationIdentifier, location.search, tourViewed]);

  // Disable scrolling when tour is running
  useEffect(() => {
    if (runTour) {
      expandCollapsedTourSteps(); // Expand collapses that contain tour steps
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = ""; // Re-enable scrolling
    }

    return () => {
      document.body.style.overflow = ""; // Clean up when unmounting
    };
  }, [runTour]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { mutateAsync: createView } = createProductTourView(tour.id);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { mutateAsync: updateView } = setProductTourView(tour.id);

  const handleJoyrideCallback = async (data: CallBackProps) => {
    const { action, status, index, lifecycle } = data;

    if (
      status === STATUS.RUNNING &&
      lifecycle === "tooltip" &&
      index > latestStep.current
    ) {
      latestStep.current = index;
    }

    if (action === "start" && lifecycle === "ready") {
      setTourViewed(true);
      creatingView.current = createView();
    }
    if (
      (status === STATUS.FINISHED || status === STATUS.SKIPPED) &&
      lifecycle === "complete"
    ) {
      setRunTour(false);

      // Wait for view to be created before attempting to update
      if (creatingView.current) {
        await creatingView.current;
      }
      updateView({
        latestStepId: tour.steps[latestStep.current].id,
        completed: status === STATUS.FINISHED,
      });
    }
  };

  return (
    <Joyride
      callback={handleJoyrideCallback}
      steps={tourSteps}
      continuous
      disableCloseOnEsc
      styles={{
        options: {
          primaryColor: SECONDARY_COLOR,
          zIndex: PRODUCT_TOUR_ZINDEX,
        },
      }}
      tooltipComponent={APJoyrideTooltip}
      run={runTour}
    />
  );
};

export default APProductTour;
