import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { trackEvent } from "../helpers/trackEvent";
import { PageViewEvent } from "../types/Events";
import { User } from "../types/User";

const PageViewTracker = ({ me }: { me: User }) => {
  const location = useLocation();

  useEffect(() => {
    trackEvent(new PageViewEvent(location.pathname, me.id));
  }, [location.pathname]);

  return null;
};

export default PageViewTracker;
