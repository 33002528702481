import { useMutation } from "react-query";

import { useApi } from "../ApiProvider";
import { APQueryOptions, useAPQuery } from "../APQueryProvider";

export const createProductTourView = (
  productTourId: string,
  options: APQueryOptions = {}
) => {
  const { fetchWithAuth } = useApi();
  const { defaultOnMutateError, defaultOnMutateSuccess } = useAPQuery();

  return useMutation({
    mutationFn: (): Promise<boolean> => {
      return fetchWithAuth<boolean>("createProductTourView", productTourId, {
        requestOptions: { method: "post" },
      });
    },
    onError: defaultOnMutateError(),
    onSuccess: defaultOnMutateSuccess(options),
  });
};
