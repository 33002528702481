import { useMutation } from "react-query";

import {
  getLocalStorageItem,
  HIGHLIGHTED_FAQ_VIEW_COUNTS_KEY,
  setLocalStorageItem,
} from "../../helpers/localStorage";
import { APQueryOptions, useAPQuery } from "../APQueryProvider";
import { HighlightedFaqViewCounts } from "./getHighlightedFaqViews";

export const setHighlightedFaqViews = (options: APQueryOptions = {}) => {
  const {
    defaultOnMutateError,
    defaultOnMutateSuccess,
    defaultOnMutateSettled,
  } = useAPQuery();
  const invalidateList = [["highlighted-faq-views"]];

  return useMutation({
    mutationFn: (faqId: string) => {
      const views =
        getLocalStorageItem<HighlightedFaqViewCounts>(
          HIGHLIGHTED_FAQ_VIEW_COUNTS_KEY
        ) || {};

      if (views[faqId]) {
        views[faqId] = views[faqId] + 1;
      } else {
        views[faqId] = 1;
      }

      setLocalStorageItem(HIGHLIGHTED_FAQ_VIEW_COUNTS_KEY, views);
      return Promise.resolve(true);
    },
    onError: defaultOnMutateError(),
    onSettled: () => defaultOnMutateSettled(invalidateList),
    onSuccess: defaultOnMutateSuccess(options),
  });
};
