import { useQuery } from "react-query";

import { APPage, FaqAppLocation } from "../../types/Faq";
import { useApi } from "../ApiProvider";
import { APQueryOptions, useAPQuery } from "../APQueryProvider";

export type FaqAppLocationsFilter = {
  pageId: APPage;
  quoteId?: string;
};

export const getFaqAppLocations = (
  filter: FaqAppLocationsFilter,
  options: APQueryOptions = {}
) => {
  const { fetchWithAuth } = useApi();
  const { isQueryEnabled } = useAPQuery();

  return useQuery({
    queryKey: ["faq-app-locations", filter.pageId],
    queryFn: (): Promise<FaqAppLocation[]> => {
      return fetchWithAuth<FaqAppLocation[]>(
        "getFaqAppLocations",
        filter.pageId,
        {
          queryParams: {
            quoteId: filter.quoteId,
          },
        }
      );
    },
    refetchInterval: options.refetchInterval,
    enabled: isQueryEnabled(options),
  });
};
