import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ReactNode } from "react";

import ModalContainer from "./ModalContainer";
import PoweredByGemini from "./PoweredByGemini";

const AIDisclaimer = ({ titleAdornment }: { titleAdornment?: ReactNode }) => {
  return (
    <ModalContainer title="AI Enhanced Experience" loading={false}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <PoweredByGemini />
        {titleAdornment}
      </Box>
      <Stack mt={2} spacing={2}>
        <Box>
          <Typography variant="labelLarge" display="inline">
            Proceeding will submit information to AI to provide an enhanced user
            experience and is subject to{" "}
          </Typography>
          <a href="https://www.auto-ux.io/terms-conditions/" target="_blank">
            <Typography variant="labelLarge" color="primary" display="inline">
              Auto-UX terms
            </Typography>
          </a>
        </Box>
        <Typography variant="labelLarge" display="inline">
          No personal identifiable data is shared with AI.
        </Typography>
        <Typography variant="labelLarge" display="inline">
          Do you want to proceed?
        </Typography>
      </Stack>
    </ModalContainer>
  );
};

export default AIDisclaimer;
