import Box from "@mui/material/Box";

import APProgressBar, {
  ProgressBarColorOption,
} from "../../components/APProgressBar";

const VEHICLE_STAT_COLORS: ProgressBarColorOption[] = [
  "error",
  "error",
  "error",
  "primary",
  "primary",
  "primary",
  "primary",
  "tertiaryGreen",
  "tertiaryGreen",
  "tertiaryGreen",
];

const VehicleStats = ({
  value,
  comfort,
  performance,
}: {
  value: number;
  comfort: number;
  performance: number;
}) => {
  return (
    <Box>
      <APProgressBar
        title="Value"
        value={value}
        maxValue={10}
        colorSpectrum={VEHICLE_STAT_COLORS}
      />
      <APProgressBar
        title="Comfort"
        value={comfort}
        maxValue={10}
        colorSpectrum={VEHICLE_STAT_COLORS}
      />
      <APProgressBar
        title="Performance"
        value={performance}
        maxValue={10}
        colorSpectrum={VEHICLE_STAT_COLORS}
      />
    </Box>
  );
};

export default VehicleStats;
