import { useQuery } from "react-query";

import { Quote } from "../../types/Quote";
import { useApi } from "../ApiProvider";
import { APQueryOptions, useAPQuery } from "../APQueryProvider";

export const getQuote = (quoteId: string, options: APQueryOptions = {}) => {
  const { fetchWithAuth } = useApi();
  const { isQueryEnabled } = useAPQuery();

  return useQuery({
    queryKey: ["quote", quoteId],
    queryFn: (): Promise<Quote> => {
      return fetchWithAuth<Quote>("getQuote", quoteId);
    },
    enabled: isQueryEnabled(options),
  });
};
