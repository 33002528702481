import {
  AI_VEHICLE_SUGGESTION_ENABLED,
  ConfigurationMap,
} from "../types/Config";
import { isTrue } from "./string";

export const isAIVehicleSuggestionEnabled = (configMap: ConfigurationMap) => {
  const config = configMap[AI_VEHICLE_SUGGESTION_ENABLED];

  return config?.value ? isTrue(config.value) : false;
};
