import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { useApi } from "../../api/ApiProvider";
import { useAuth } from "../../api/AuthProvider";
import { getFaqs } from "../../api/faq/getFaqs";
import { getCalculatedQuote } from "../../api/quote/getCalculatedQuote";
import { FaqContextIds } from "../../components/AppLocation";
import ModalContainer from "../../components/ModalContainer";
import { removeDuplicatesByKey } from "../../helpers/array";
import { formatCalculatedQuote } from "../../helpers/format";
import { Faq } from "../../types/Faq";
import FaqCarousel from "../components/FaqCarousel";
import FaqDetails from "../components/FaqDetails";
import FaqListCard from "../components/FaqListCard";

const ViewAppLocationFaqs = () => {
  const { faqId, appLocationId } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { fetchWithAuth } = useApi();
  const { inAppScope } = useAuth();
  const quoteId = searchParams.get("modal.quoteId") ?? undefined;

  if (!appLocationId) {
    // TODO: improve client side error messaging
    throw new Error("app location id param is misisng");
  }

  const faqs = getFaqs({ appLocationId, quoteId });
  const quote = getCalculatedQuote(quoteId ?? "", {
    useCached: true,
    disabled: !quoteId,
  });

  if (faqs && faqs.isError) {
    throw faqs.error;
  }

  if (quote.isError) {
    throw quote.error;
  }

  let faq: Faq | null = null;

  if (faqs.isSuccess) {
    if (faqs.data.length === 1) {
      faq = faqs.data[0];
    } else if (faqId) {
      faq = faqs.data.find((faq) => faq.id === faqId) || null;
    }
  }

  const trackView = (faqId: string) => {
    if (inAppScope("external")) {
      fetchWithAuth<void>("createFaqView", faqId, {
        requestOptions: {
          method: "post",
          body: JSON.stringify({
            appLocationId: appLocationId,
            faqAppLocationId: faq?.faqApplocationId || null,
          }),
        },
      });
    }
  };

  // If no Faqs then close modal
  useEffect(() => {
    if (faqs.isSuccess && faqs.data.length === 0) {
      navigate(window.location.pathname, { replace: true });
    }
  }, [faqs.isSuccess]);

  // If displaying an FAQ then track a view
  useEffect(() => {
    if (faq) {
      trackView(faq.id);
    }
  }, [faq?.id]);

  const contextIds: FaqContextIds = {
    quoteId,
  };

  const renderFaq = (faq: Faq) => {
    return (
      <FaqListCard
        key={`list-${faq.id}`}
        faq={faq}
        appLocationId={appLocationId}
        contextIds={contextIds}
      />
    );
  };

  const isLoading = faqs.isLoading || quote.isLoading;
  const isSuccess = faqs.isSuccess && (!quoteId || quote.isSuccess);

  const filteredFaqs = faqs.data ? removeDuplicatesByKey(faqs.data, "id") : [];

  return (
    <ModalContainer title={faq ? undefined : "FAQ"} loading={isLoading}>
      {isSuccess && (
        <Box>
          {faq ? (
            <FaqDetails
              faq={faq}
              quote={quote.data ? formatCalculatedQuote(quote.data) : undefined}
              appLocationId={appLocationId}
            />
          ) : (
            <Box>
              <Typography variant="labelLarge">
                Click on one of the FAQ’s to learn more.
              </Typography>
              {filteredFaqs.length > 5 && (
                <Box>
                  <FaqCarousel
                    faqs={filteredFaqs}
                    appLocationId={appLocationId}
                    contextIds={contextIds}
                  />
                </Box>
              )}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  rowGap: 1,
                  mt: 2,
                }}
              >
                {filteredFaqs.map(renderFaq)}
              </Box>
            </Box>
          )}
        </Box>
      )}
    </ModalContainer>
  );
};

export default ViewAppLocationFaqs;
