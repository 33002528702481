export const GST_PERCENTAGE = 10;

export type VehicleMake = {
  id: string;
  name: string;
};

export type VehicleModel = {
  id: string;
  name: string;
  makeId: string;
};

export type VehicleModelYear = string;

export type VehicleVariant = {
  id: string;
  name: string;
  makeId: string;
  modelId: string;
  modelYear: string;
};

export type VehicleVariantPhoto = {
  id: string;
  variantId: string;
  type: "front" | "rear" | "interior" | "side" | "engine";
  url: string;
  code: string;
  description: string;
};

export type RedbookVehiclePhoto = {
  id: number;
  vehicleid: number;
  photoangle: string;
  caption: string;
  sequenceno: number;
  href: string;
};

export type VehicleOptionType = "auto-ux" | "dealer";

export type VehicleOptionCategory = {
  id: string;
  name: string;
  displayOrder: number;
  optionIds: string[];
};

export type VehicleOption = {
  id: string;
  catchEOptionId: string;
  price: number;
  name: string;
  description: string;
  type: VehicleOptionType;
  productInfoDownloadURL: string | null;
  productInfoDownloadName: string | null;
  imageUrl: string | null;
};

export type VehicleOptions = {
  categories: VehicleOptionCategory[];
  options: {
    dealer: Record<string, VehicleOption>;
    manufacturer: Record<string, VehicleOption>;
  };
};

export type VehicleMatch = {
  make?: VehicleMake;
  model?: VehicleModel;
  year?: string;
  photo: RedbookVehiclePhoto | null;
};

export type VehicleSuggestions = {
  queryId: string;
  suggestions: VehicleSuggestion[];
};

export type VehicleSuggestion = {
  match: VehicleMatch;
  make: string;
  model: string;
  pros: string;
  cons: string;
  comparisonDetails: string;
  value: number;
  comfort: number;
  performance: number;
};
export const calculateWeeklyVehicleOptionCost = (
  totalExGst: number,
  savingsPercentage: number,
  term: number
) => {
  const totalWithGST = (totalExGst * 100) / (100 - GST_PERCENTAGE);
  const weeksInTerm = (term / 12) * 52;
  const costPerWeek =
    (totalWithGST * (1 - savingsPercentage / 100)) / weeksInTerm;

  return costPerWeek;
};
