import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import Box from "@mui/material/Box";
import Slide from "@mui/material/Slide";
import { ReactNode, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { useAuth } from "../api/AuthProvider";
import { HighlightedFaqViewCounts } from "../api/faq/getHighlightedFaqViews";
import { setHighlightedFaqViews } from "../api/faq/setHighlightedFaqView";
import {
  addFaqModalParamsToHref,
  addFaqParamsToSearchParams,
  hasHighlightedFaqs,
} from "../helpers/faq";
import { addModalToHref, replaceModalParam } from "../helpers/routes";
import { CRMAppLocationIdentifier, FaqAppLocation } from "../types/Faq";
import APHighlightTooltip from "./APHighlightTooltip";

export type FaqContextIds = {
  quoteId?: string;
};

interface AppLocationProps {
  children: ReactNode;
  appLocations: FaqAppLocation[];
  appLocationIdentifier: CRMAppLocationIdentifier;
  highlightedFaqViews: HighlightedFaqViewCounts;
  contextIds?: FaqContextIds;
  hideIfNoFaqs?: boolean;
  hideHighlight?: boolean;
}

const AppLocation = ({
  children,
  appLocations,
  appLocationIdentifier,
  highlightedFaqViews,
  contextIds = {},
  hideIfNoFaqs,
  hideHighlight,
}: AppLocationProps) => {
  const { getScopedPageUrl } = useAuth();
  const navigate = useNavigate();
  const [hasEnteredViewport, setHasEnteredViewport] = useState(false);
  const hasEntered = useRef(false); // Track if it has already been set
  const [hasDisplayedMaximised, setHasDisplayedMaximised] = useState(false);

  const elementRef = useRef<HTMLDivElement | null>(null);

  const appLocation = appLocations.find(
    (location) => location.appLocationIdentifier === appLocationIdentifier
  );
  const isClickable = appLocation && appLocation.faqCount > 0;
  // Determine highlight state
  const highlightedFaq = appLocation?.highlightedFaq ?? null;

  let linkTo = "";
  let [tooltipSearchParams] = useSearchParams();

  if (appLocation) {
    // Add modal param
    linkTo = addModalToHref(
      getScopedPageUrl("modalAppLocation", appLocation.id),
      window.location.href
    );

    linkTo = addFaqModalParamsToHref(linkTo, appLocation.id, contextIds);
    tooltipSearchParams = addFaqParamsToSearchParams(
      tooltipSearchParams,
      appLocation.id,
      highlightedFaq?.faqApplocationId || null,
      contextIds
    );
  }

  const hasHighlight = !hideHighlight && !!highlightedFaq;
  const showMaximisedHighlight =
    hasHighlight &&
    hasHighlightedFaqs(
      appLocations,
      [appLocationIdentifier],
      highlightedFaqViews
    ) &&
    hasEnteredViewport &&
    !hasDisplayedMaximised;
  const showMinimisedHighlight = hasHighlight && !showMaximisedHighlight;

  // Detect when Faq Link is in view port
  useEffect(() => {
    if (hasHighlight) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          // Only set the first time it enters the viewport
          if (entry.isIntersecting && !hasEntered.current) {
            hasEntered.current = true;
            setHasEnteredViewport(entry.isIntersecting);
          }
        },
        {
          root: null,
          rootMargin: "-10% 0px -20% 0px",
          threshold: 1,
        }
      );

      if (elementRef.current) {
        observer.observe(elementRef.current);
      }

      return () => {
        if (elementRef.current) {
          observer.unobserve(elementRef.current);
        }
      };
    }
  }, [hasHighlight]);

  const { mutateAsync } = setHighlightedFaqViews();

  useEffect(() => {
    if (showMaximisedHighlight) {
      const timer = setTimeout(() => {
        if (highlightedFaq) {
          setHasDisplayedMaximised(true);
          mutateAsync(highlightedFaq.id);
        }
      }, 15000);

      return () => clearTimeout(timer);
    }
  }, [showMaximisedHighlight]);

  // Handle tooltip click
  const onTooltipClick = () => {
    if (highlightedFaq) {
      const newSearchParams = replaceModalParam(
        getScopedPageUrl("modalFaq", highlightedFaq.id),
        tooltipSearchParams
      );

      navigate(`?${newSearchParams.toString()}`, { replace: true });
    }
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          pointerEvents: "none",
        }}
        className={`productTour-${appLocationIdentifier}`}
      />
      {isClickable ? (
        <APHighlightTooltip
          text={
            appLocation.highlightedFaq
              ? appLocation.highlightedFaq.question
              : ""
          }
          isOpen={showMaximisedHighlight}
          onClick={onTooltipClick}
        >
          <Box sx={{ position: "relative" }} ref={elementRef}>
            {showMinimisedHighlight && (
              <Box
                sx={{ overflow: "hidden", position: "absolute", right: -14 }}
              >
                <Link to={linkTo} replace={true}>
                  <Slide in={true} timeout={500} direction="right">
                    <Box sx={{ display: "flex" }}>
                      <ChatBubbleIcon
                        fontSize="small"
                        sx={{
                          color: "tertiaryGreen.main",
                          fontSize: "0.7rem",
                        }}
                      />
                    </Box>
                  </Slide>
                </Link>
              </Box>
            )}

            <Link to={linkTo} replace={true}>
              {children}
            </Link>
          </Box>
        </APHighlightTooltip>
      ) : hideIfNoFaqs ? (
        <></>
      ) : (
        children
      )}
    </Box>
  );
};

export default AppLocation;
