import { SxProps, Theme } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { ReactNode } from "react";

const APIconButton = ({
  icon,
  onClick,
  sx,
  isFaded,
}: {
  icon: ReactNode;
  onClick: () => void;
  sx?: SxProps<Theme>;
  isFaded?: boolean;
}) => {
  return (
    <IconButton
      size="large"
      aria-label="refresh"
      color="onSurface"
      onClick={() => onClick()}
      sx={{ ...sx, opacity: isFaded ? "40%" : "100%" }}
    >
      {icon}
    </IconButton>
  );
};

export default APIconButton;
